import React, { FC } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import PageLayout from 'components/PageLayout'
import Dialog from 'components/shared/Dialog'
import SubtitleBox from 'components/shared/SubtitleBox'
import PlusIcon16 from 'components/shared/icons/16x16/PlusIcon16'
import { ShellsHash } from 'types/shells.types'
import { PATH_SHELLS } from 'constants/routes.constants'
import ShellsTable from './ShellsTable'
import AddShellForm from './AddShellForm'

const ShellsPage: FC = () => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()

  const closeDialog = () => {
    navigate({
      pathname: PATH_SHELLS,
      search: searchParams.toString()
    })
  }

  return (
    <PageLayout title="SHELLS_PAGE.TITLE">
      <SubtitleBox>
        <Button
          size="small"
          variant="contained"
          onClick={() =>
            navigate({
              pathname: PATH_SHELLS,
              search: searchParams.toString(),
              hash: ShellsHash.ADD
            })
          }
          startIcon={<PlusIcon16 />}
        >
          {intl.formatMessage({ id: 'SHELLS_PAGE.BUTTON.ADD_SHELL' })}
        </Button>
      </SubtitleBox>
      <ShellsTable />

      <Dialog
        open={hash === ShellsHash.ADD}
        onClose={closeDialog}
        dialogTitle={intl.formatMessage({ id: 'SHELLS_PAGE.DIALOG.TITLE.ADD_SHELL' })}
      >
        <AddShellForm />
      </Dialog>
    </PageLayout>
  )
}

export default ShellsPage
