import * as TOKENS from 'constants/tokens'

const styles = {
  userMenu: {
    display: 'flex',
    alignItems: 'center',
    color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY
  },
  avatar: {
    width: TOKENS.BASE_SIZING_SIZING_3,
    height: TOKENS.BASE_SIZING_SIZING_3,
    borderRadius: '50%',
    border: `1px solid ${TOKENS.CORE_COLOR_ZINC_600}`,
    backgroundColor: TOKENS.CORE_COLOR_ZINC_800,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    pt: '2.25px',
    mr: 1
  }
}

export default styles
