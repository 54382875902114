import { camelCaseArray } from 'utils/shared.utils'
import {
  Credentials,
  CredentialsPayload,
  CredentialKey,
  User,
  UserPayload,
  CredentialType,
  CredentialPayload
} from 'types/user.types'

type NormalizeUser = (user: UserPayload) => User

export const normalizeUser: NormalizeUser = user => {
  const { id, nickname } = user

  return {
    id,
    nickname
  }
}

type NormalizeCredentials = (credentials: CredentialsPayload) => Credentials

export const normalizeCredentials: NormalizeCredentials = credentials => {
  return {
    [CredentialKey.PARSERS]: camelCaseArray(credentials[CredentialType.PARSER] as CredentialPayload[])
  }
}
