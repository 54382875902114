import { connect } from 'react-redux'
import { deleteDomains, fetchDomainsStorageList } from 'modules/domains'
import DomainsStorage from './DomainsStorage'

const mapDispatchToProps = {
  deleteDomains,
  fetchDomainsStorageList
}

export default connect(null, mapDispatchToProps)(DomainsStorage)
