import { object, boolean, string } from 'yup'
import { emptyStringSchema, requiredStringSchema } from 'utils/schemas.utils'

export default object().shape(
  {
    domains: requiredStringSchema,
    validation: boolean().required(),
    credentialParserId: string().when(['validation'], (validation: boolean) => {
      return validation ? requiredStringSchema : string().nullable()
    })
  },
  [['validation', 'credentialParserId']]
)
