import { KeysTableHead, KeysTableHeadIds, SnippetsMode, SnippetsThreads } from 'types/keys.types'

export const KEYS_TABLE_HEAD: KeysTableHead = [
  {
    id: KeysTableHeadIds.ID,
    label: 'KEYS_PAGE.KEYS_GROUPS_TABLE.HEAD.TITLE.ID'
  },
  {
    id: KeysTableHeadIds.CREATED_AT,
    label: 'KEYS_PAGE.KEYS_GROUPS_TABLE.HEAD.TITLE.CREATED_AT',
    sortable: true
  },
  {
    id: KeysTableHeadIds.TITLE,
    label: 'KEYS_PAGE.KEYS_GROUPS_TABLE.HEAD.TITLE.TITLE',
    sortable: true
  }
]

export const SNIPETS_MODES_LIST = [
  {
    id: SnippetsMode.ALL,
    title: 'CREATE_SNIPPETS_FORM.SNIPETS_MODES_LIST.OPTION.ALL'
  },
  {
    id: SnippetsMode.NEW,
    title: 'CREATE_SNIPPETS_FORM.SNIPETS_MODES_LIST.OPTION.NEW'
  },
  {
    id: SnippetsMode.OLD,
    title: 'CREATE_SNIPPETS_FORM.SNIPETS_MODES_LIST.OPTION.OLD'
  }
]

export const SNIPETS_THREAD_LIST = [
  {
    id: SnippetsThreads.THREADS100,
    title: 'CREATE_SNIPPETS_FORM.SNIPETS_THREAD_LIST.OPTION.100'
  },
  {
    id: SnippetsThreads.THREADS200,
    title: 'CREATE_SNIPPETS_FORM.SNIPETS_THREAD_LIST.OPTION.200'
  }
]
