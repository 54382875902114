import { SIDE_BAR_WIDTH } from 'constants/theme.constants'

const styles = {
  sideBar: {
    width: SIDE_BAR_WIDTH,

    '& .MuiPaper-root': {
      width: SIDE_BAR_WIDTH,
      p: 3
    }
  },
  logo: {
    mb: 8,
    display: 'flex',
    justifyContent: 'center',

    '@media screen and (max-height: 440px)': {
      mb: 3
    },

    '@media screen and (max-height: 400px)': {
      display: 'none'
    }
  }
}

export default styles
