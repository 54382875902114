import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CloseIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.655 2.34563C13.2645 1.9551 12.6314 1.9551 12.2408 2.34563L8.00026 6.5862L3.75989 2.34582C3.36936 1.9553 2.7362 1.9553 2.34567 2.34582C1.95515 2.73635 1.95515 3.36951 2.34567 3.76004L6.58605 8.00041L2.34367 12.2428C1.95315 12.6333 1.95315 13.2665 2.34367 13.657C2.7342 14.0475 3.36736 14.0475 3.75789 13.657L8.00026 9.41463L12.2425 13.6568C12.633 14.0474 13.2662 14.0474 13.6567 13.6568C14.0472 13.2663 14.0472 12.6331 13.6567 12.2426L9.41448 8.00041L13.655 3.75984C14.0456 3.36932 14.0456 2.73615 13.655 2.34563Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default CloseIcon16
