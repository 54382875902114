import theme from 'configs/theme.config'

const styles = {
  buttons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    flexWrap: 'wrap',

    '& > *': {
      flex: 'none',

      [theme.breakpoints.between('sm', 830)]: {
        flex: 1
      },

      '@media screen and (max-width: 600px)': {
        flex: 1
      },

      '& > button': {
        width: '100%'
      },

      '@media screen and (max-width: 420px)': {
        width: '100%',
        flex: 'none'
      },

      '&:only-child': {
        flex: 'none'
      }
    },

    '& > *:not(:last-child)': {
      mr: 3,

      [theme.breakpoints.down('xs')]: {
        mr: 1.5
      },
      '@media screen and (max-width: 420px)': {
        mr: 0,
        mb: 1.5
      }
    },

    '& > button:not(:last-child)': {
      mr: 3,

      [theme.breakpoints.down('xs')]: {
        mr: 1.5
      },
      '@media screen and (max-width: 420px)': {
        mr: 0,
        mb: 1.5
      }
    }
  }
}

export default styles
