import React, { FC } from 'react'
import { useController } from 'react-hook-form'
import { IntlShape, useIntl } from 'react-intl'
import moment, { Moment } from 'moment'
import timezone from 'moment-timezone'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import FormControl from '@mui/material/FormControl'
import FieldErrorMessage from 'components/shared/form/FieldErrorMessage'
import { getServerFormattedDate } from 'utils/shared.utils'
import { Controller, AfterFieldChange } from 'types/form.types'
import { SXProps } from 'types/theme.types'

interface FieldDateTimePickerProps extends Controller {
  label?: string
  afterFieldChange?: AfterFieldChange
  sx?: SXProps
}

const FieldDateTimePicker: FC<FieldDateTimePickerProps & DateTimePickerProps<Moment>> = ({
  control,
  name,
  label,
  fullWidth = true,
  sx,
  afterFieldChange,
  ...props
}) => {
  const intl: IntlShape = useIntl()

  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const handleChangeDate = (date: Moment | null) => {
    field.onChange(date && getServerFormattedDate(date, 'YYYY-MM-DD HH'))
    afterFieldChange?.()
  }

  return (
    <FormControl error={Boolean(error?.message)} sx={sx} fullWidth={fullWidth}>
      <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={timezone}>
        <DateTimePicker
          {...field}
          focused
          ampm={false}
          value={field.value ? moment.utc(field.value) : null}
          format="DD/MM/YYYY HH:mm"
          timezone="Europe/Kyiv"
          closeOnSelect={false}
          label={label ? intl.formatMessage({ id: String(label), defaultMessage: String(label) }) : ''}
          onChange={handleChangeDate}
          views={['year', 'month', 'day', 'hours']}
          minDateTime={moment.utc().endOf('hour')}
          slotProps={{
            textField: { error: Boolean(error?.message), fullWidth, size: 'large' },
            mobilePaper: {
              classes: { root: 'picker-mobile-paper' }
            }
          }}
          {...props}
        />
      </LocalizationProvider>
      <FieldErrorMessage errorMessage={error?.message} />
    </FormControl>
  )
}

export default FieldDateTimePicker
