import { SortMethod } from './shared.types'

export enum KeysActionType {
  FETCH_KEYS_GROUPS_LIST = 'FETCH_KEYS_GROUPS_LIST',
  SET_KEYS_GROUPS_LIST = 'SET_KEYS_GROUPS_LIST',
  SET_SORTED_KEYS_GROUPS_LIST = 'SET_SORTED_KEYS_GROUPS_LIST',
  IMPORT_KEYS = 'IMPORT_KEYS',
  CREATE_SNIPPETS = 'CREATE_SNIPPETS'
}

export interface KeysState {
  keysGroupsList: KeysGroupsList
  sortedKeysGroupsList: KeysGroupsList
}

export enum KeysHash {
  SNIPPETS = '#snippets',
  IMPORT = '#import',
  ADD_GROUP = '#addGroup'
}

export enum KeysTableHeadIds {
  ID = 'id',
  CREATED_AT = 'created_at',
  TITLE = 'title'
}

export interface KeysTableHeadCell {
  id: KeysTableHeadIds
  label: string
  sortable?: boolean
}

export type KeysTableHead = KeysTableHeadCell[]

export type FetchKeysGroupsList = (sortMethod?: SortMethod, sortBy?: KeysTableHeadIds) => void

export interface KeysGroupsItemPayload {
  id: number
  user_id: number
  title: string
  description: string
  created_at: Date
}

export type KeysGroupsListPayload = KeysGroupsItemPayload[]

export interface KeysGroupsItem {
  id: number
  createdAt: Date
  title: string
  description: string
}

export type KeysGroupsList = KeysGroupsItem[]

export interface SetKeysGroupsListAction {
  type: KeysActionType.SET_KEYS_GROUPS_LIST
  payload: KeysGroupsListPayload
}

export interface SetSortedKeysGroupsListAction {
  type: KeysActionType.SET_SORTED_KEYS_GROUPS_LIST
  payload: KeysGroupsListPayload
}

export interface ImportKeysValues {
  groupId: string
  title: string
  file: null | File
}

export type ImportKeys = (data: ImportKeysValues) => void

export interface ImportedKeysStatusPayload {
  uploaded: number
  imported: number
  failed_text: number
  failed_duplicated: number
}

export enum SnippetsMode {
  ALL = 'all',
  NEW = 'new',
  OLD = 'old'
}

export interface SnippetsModeItem {
  id: SnippetsMode
  title: string
}

export enum SnippetsThreads {
  THREADS100 = '100 Threads',
  THREADS200 = '200 Threads'
}

export interface SnippetsThreadsItem {
  id: SnippetsThreads
  title: string
}

export interface CreateSnippetsValues {
  groupId: string
  mode: SnippetsMode
  threads: SnippetsThreads
}

export type CreateSnippets = (data: CreateSnippetsValues, successInfo: string) => void
