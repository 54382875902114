import { object, number } from 'yup'
import { convertStringToNumber, requiredStringSchema } from 'utils/schemas.utils'

export default object().shape({
  domain: requiredStringSchema,
  cost: number()
    .transform(convertStringToNumber)
    .min(1, 'FORM.FIELD.VALIDATION.ERROR.COST_MIN')
    .required('FORM.FIELD.VALIDATION.ERROR.REQUIRED')
})
