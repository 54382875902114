import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import * as TOKENS from 'constants/tokens'

const ActiveIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM10.748 5L7.542 8.36878L6 7L4.74797 8.31561L7.542 11L12 6.31561L10.748 5Z"
        fill={TOKENS.COMPONENT_COLOR_ICON_SUCCESS}
      />
    </SvgIcon>
  )
}

export default ActiveIcon16
