import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const KeysIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 3H6V2H15V11L21 17V20H20V17.5L14 11.613L14 3ZM4 5H12L12 12.7261L18 18.5V20.9478H19V18L13 12.113V4H4V5ZM2 6H11V13.113L17 19L17 22L13.5 22L12.5 21V19H10.5L10 18.5L9.9879 16.5H8L6.5 15H2V6ZM6 8H7V10H9V11H7V13H6V11H4V10H6V8Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default KeysIcon24
