import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'
import AuthPageLayout from 'components/auth/AuthPageLayout'
import { PATH_SIGN_IN } from 'constants/routes.constants'

interface PrivateRouteProps {
  isLoaded: boolean
  isAuthed: boolean
  Component: FC
}

const PrivateRoute: FC<PrivateRouteProps> = ({ isLoaded, isAuthed, Component }) => {
  if (!isLoaded) {
    return <AuthPageLayout />
  }

  if (!isAuthed) {
    return <Navigate to={PATH_SIGN_IN} replace />
  }

  return <Component />
}

export default PrivateRoute
