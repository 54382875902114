import React, { FC, SyntheticEvent } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import MuiTabs, { TabsProps } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { TabsList } from 'types/shared.types'

interface TabsNavProps extends TabsProps {
  tabs: TabsList
  value?: string
  size?: 'small' | 'medium' | 'large'
  onTabChange?: (value: string) => void
}

const Tabs: FC<TabsNavProps> = ({ tabs, value: currentValue, size = 'large', onTabChange, ...props }) => {
  const intl: IntlShape = useIntl()
  const { pathname } = useLocation()

  const handleChange = (event: SyntheticEvent, value: string) => {
    if (onTabChange) {
      onTabChange(value)
    }
  }

  return (
    <MuiTabs value={currentValue || pathname} classes={{ root: `size-${size}` }} onChange={handleChange} {...props}>
      {tabs.map(({ path, label, icon, value }, index) => (
        <Tab
          classes={{ root: `size-${size}` }}
          key={`${path || value}-${index}`}
          value={path || value}
          label={intl.formatMessage({ id: label })}
          {...(path
            ? { to: `${path}?sortMethod=desc&sortBy=created_at&page=1&perPage=25`, component: RouterLink }
            : {})}
          {...(icon ? { iconPosition: 'start', icon: icon } : {})}
        />
      ))}
    </MuiTabs>
  )
}

export default Tabs
