import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import * as TOKENS from 'constants/tokens'

const WarningIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.299 2.25C8.72165 1.25 7.27827 1.25 6.70092 2.25L0.638746 12.75C0.0613962 13.75 0.783085 15 1.93779 15H14.0621C15.2168 15 15.9385 13.75 15.3612 12.75L9.299 2.25ZM6.99996 5H8.99996V10H6.99996V5ZM6.99996 11H8.99996V13H6.99996V11Z"
        fill={TOKENS.COMPONENT_COLOR_ICON_WARNING}
      />
    </SvgIcon>
  )
}

export default WarningIcon16
