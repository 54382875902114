import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SnippetsIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 18" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1H5V3H4V6.23607L3.11803 8L4 9.76393V13H5V15H2V10.2361L0.881966 8L2 5.76393V1ZM13 15L13 13L14 13L14 9.76393L14.882 8L14 6.23607V3L13 3V1H16L16 5.76393L17.118 8L16 10.2361L16 15L13 15ZM12 7H6V9H12V7ZM5 10H13V12H5V10ZM13 4H5V6H13V4Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default SnippetsIcon16
