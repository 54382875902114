import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchShellsList, getShellsList, getShellsListPagination, updateShell } from 'modules/shells'
import ShellsTable from './ShellsTable'

const mapStateToProps = (state: RootState) => {
  return {
    shellsList: getShellsList(state),
    shellsListPagination: getShellsListPagination(state)
  }
}

const mapDispatchToProps = {
  fetchShellsList,
  updateShell
}

export default connect(mapStateToProps, mapDispatchToProps)(ShellsTable)
