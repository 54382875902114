import React, { FC, useEffect } from 'react'
import { useLocation, Routes, Route } from 'react-router-dom'
import { FetchUser } from 'types/user.types'
import { FetchKeysGroupsList } from 'types/keys.types'
import { routes } from 'configs/routes.config'
import { isWindow } from 'constants/system.constants'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Notifier from './Notifier'
import './App.styles.css'

interface AppProps {
  isAuthed: boolean
  fetchUser: FetchUser
  fetchKeysGroupsList: FetchKeysGroupsList
}

const App: FC<AppProps> = ({ isAuthed, fetchUser, fetchKeysGroupsList }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    if (isAuthed) {
      fetchKeysGroupsList()
    }
  }, [isAuthed])

  useEffect(() => {
    if (isWindow) {
      window.scrollTo(0, 0)

      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'manual'
      }
    }
  }, [pathname])

  return (
    <>
      <Routes>
        {routes.map(({ type, path, Component }) => (
          <Route
            key={path}
            path={path}
            element={
              type === 'private' ? (
                <PrivateRoute Component={Component} />
              ) : type === 'static' ? (
                <Component />
              ) : (
                <PublicRoute Component={Component} />
              )
            }
          />
        ))}
      </Routes>
      <Notifier />
    </>
  )
}

export default App
