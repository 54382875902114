import React, { FC, ChangeEvent } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useController } from 'react-hook-form'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from 'components/shared/form/Checkbox'
import FieldErrorMessage from 'components/shared/form/FieldErrorMessage'
import { AfterFieldChange, Controller } from 'types/form.types'

interface FieldCheckboxProps extends Controller {
  label: string
  afterFieldChange?: AfterFieldChange
}

const FieldCheckbox: FC<FieldCheckboxProps & FormControlProps> = ({
  control,
  name,
  label,
  fullWidth = true,
  afterFieldChange,
  ...props
}) => {
  const intl: IntlShape = useIntl()

  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, control })

  const handleChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)

    afterFieldChange?.()
  }

  return (
    <FormControl error={Boolean(error?.message)} fullWidth={fullWidth} {...props}>
      <FormControlLabel
        control={<Checkbox checked={value} onChange={handleChangeField} />}
        label={intl.formatMessage({ id: label, defaultMessage: label })}
      />
      <FieldErrorMessage errorMessage={error?.message} />
    </FormControl>
  )
}

export default FieldCheckbox
