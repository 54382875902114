import { AnyAction } from 'redux'
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { getUserAuthToken } from 'utils/user.utils'

export const clientsOptions = {
  interceptors: {
    response: [
      {
        success: (action: AnyAction, response: AxiosResponse) => {
          return response.data
        },
        error: (action: AnyAction, error: AxiosError) => {
          return Promise.reject(error?.response?.data.error)
        }
      }
    ]
  }
}

export const projectApiOptions = {
  interceptors: {
    request: [
      (action: AnyAction, request: AxiosRequestConfig) => {
        const authToken = getUserAuthToken()

        if (authToken) {
          request.headers.Authorization = authToken
        }

        return request
      }
    ],
    response: [
      {
        success: (action: AnyAction, response?: AxiosResponse) => {
          if (!response) {
            return {}
          }

          return response.data
        }
      }
    ]
  }
}

const apiClients = {
  default: {
    client: axios.create({
      baseURL: `${process.env.RAZZLE_API_URL}/api/v1`,
      responseType: 'json'
    }),
    options: projectApiOptions
  }
}

export default apiClients
