import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ArrowLeftIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.335 20.792L4.08537 12.5424L12.335 4.29282L13.7492 5.70703L7.9138 11.5424L21.0845 11.5424L21.0845 13.5424H7.9138L13.7492 19.3778L12.335 20.792Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ArrowLeftIcon24
