import React, { FC, MouseEvent } from 'react'
import IconButton from '@mui/material/IconButton'
import ArrowRightIcon16 from 'components/shared/icons/16x16/ArrowRightIcon16'
import ArrowLeftIcon16 from 'components/shared/icons/16x16/ArrowLeftIcon16'

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => void
}

const TablePaginationActions: FC<TablePaginationActionsProps> = ({ count, page, rowsPerPage, onPageChange }) => {
  const handleBackButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  return (
    <>
      <IconButton
        sx={{ ml: 3, mr: 1 }}
        color="outlinedSecondary"
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
      >
        <ArrowLeftIcon16 />
      </IconButton>
      <IconButton
        color="outlinedSecondary"
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <ArrowRightIcon16 />
      </IconButton>
    </>
  )
}

export default TablePaginationActions
