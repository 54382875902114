import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const UploadIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C2.89543 1 2 1.89543 2 3V13C2 14.1046 2.89543 15 4 15H12C13.1046 15 14 14.1046 14 13V8V6.6406C14 5.90586 13.7304 5.19666 13.2422 4.64751L10.895 2.00691C10.3257 1.36644 9.50972 1 8.6528 1H8H4ZM7 7L5.65227 7C5.39596 7 5.25769 6.69937 5.42449 6.50476L7.6963 4C7.85594 3.81376 8.14406 3.81376 8.3037 4L10.5755 6.50476C10.7423 6.69936 10.604 7 10.3477 7H9V9.6C9 9.82091 8.82091 10 8.6 10H7.4C7.17909 10 7 9.82091 7 9.6V7ZM10.5 12C10.7761 12 11 11.7761 11 11.5C11 11.2239 10.7761 11 10.5 11H5.5C5.22386 11 5 11.2239 5 11.5C5 11.7761 5.22386 12 5.5 12L10.5 12Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default UploadIcon16
