import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ElixirIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99994 3C6.99994 1.89543 7.89537 1 8.99994 1H14.9999C16.1045 1 16.9999 1.89543 16.9999 3V4.44536L15.9999 5.34536V10.65L21.6703 16.6039C22.6035 17.5837 22.7652 19.067 22.0652 20.225L20.3875 23H3.61244L3.00787 22H3.00065L2.97573 21.9468L1.93472 20.225C1.23465 19.067 1.3964 17.5837 2.32959 16.6039L7.99995 10.65V5.34536L6.99994 4.44536V3ZM13.9999 11.45L18.9633 16.6615C18.371 16.5205 17.7371 16.5267 17.1337 16.7191C15.3033 17.303 13.2936 17.6175 11.75 17C9.56067 16.1243 6.94535 16.2711 5.14761 16.545L9.99995 11.45V4.45464L8.99994 3.55464V3H14.9999V3.55464L13.9999 4.45464V11.45ZM13.875 13H12L14.5 16H16.5L13.875 13Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ElixirIcon24
