import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const PlusIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 20 20" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82743 18.3333C9.36719 18.3333 9.16669 17.9602 9.16669 17.5V10.8333H2.50002C2.03978 10.8333 1.66669 10.4602 1.66669 9.99996C1.66669 9.53972 2.03978 9.16663 2.50002 9.16663H9.16669V2.49996C9.16669 2.03972 9.53978 1.66663 10 1.66663C10.4603 1.66663 10.8334 2.03973 10.8334 2.49998V9.16663H17.5C17.9602 9.16663 18.3334 9.53972 18.3334 9.99996C18.3334 10.4602 17.9602 10.8333 17.5 10.8333H10.8334V17.5C10.8334 17.9602 10.2877 18.3333 9.82743 18.3333Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default PlusIcon24
