import {
  DomainsActiveItemPayload,
  DomainsActiveListPayload,
  DomainsActiveItem,
  DomainsActiveList
} from 'types/domains.types'
import { camelCaseKeys } from 'utils/shared.utils'

type NormalizeDomainsActiveItem = (item: DomainsActiveItemPayload) => DomainsActiveItem

export const normalizeDomainsActiveItem: NormalizeDomainsActiveItem = item => {
  const { domain, farmer, campaign } = item

  return {
    ...(camelCaseKeys(domain) as DomainsActiveItem),
    farmer: farmer.title,
    campaign: campaign.title
  }
}

type NormalizeDomainsActiveList = (domains: DomainsActiveListPayload) => DomainsActiveList

export const normalizeDomainsActiveList: NormalizeDomainsActiveList = domains => domains.map(normalizeDomainsActiveItem)
