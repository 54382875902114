import {
  NotificationsActionType,
  AddNotificationsItem,
  AddSuccessNotificationsItem,
  AddErrorNotificationsItem,
  CloseNotificationsItem,
  RemoveNotificationsItem
} from 'types/notifications.types'

export const addNotificationsItem: AddNotificationsItem = notification => {
  return {
    type: NotificationsActionType.ADD_NOTIFICATIONS_ITEM,
    payload: {
      ...notification,
      key: notification.options?.key || new Date().getTime() + Math.random()
    }
  }
}

export const addSuccessNotificationsItem: AddSuccessNotificationsItem =
  ({ title, severity = 'success', ...notification } = {}) =>
  dispatch => {
    dispatch(
      addNotificationsItem({
        title: title || 'NOTIFIER.TITLE.SUCCESS.DEFAULT',
        severity,
        ...notification
      })
    )
  }

export const addErrorNotificationsItem: AddErrorNotificationsItem = code => dispatch => {
  dispatch(
    addNotificationsItem({
      title: code ? `NOTIFIER.TITLE.ERROR.${code}` : 'NOTIFIER.TITLE.ERROR.DEFAULT',
      severity: 'error'
    })
  )
}

export const closeNotificationsItem: CloseNotificationsItem = key => ({
  type: NotificationsActionType.CLOSE_NOTIFICATIONS_ITEM,
  payload: {
    dismissAll: !key,
    key
  }
})

export const removeNotificationsItem: RemoveNotificationsItem = key => ({
  type: NotificationsActionType.REMOVE_NOTIFICATIONS_ITEM,
  payload: {
    key
  }
})
