import cookies from 'js-cookie'
import { ContactUs, FetchUser, ResetUser, SetUser, SignIn, UserActionType, UserDataPayload } from 'types/user.types'
import { AxiosDispatch } from 'types/system.types'
import { STORAGE_KEY_AUTH_TOKEN } from 'constants/store.constants'
import { addErrorNotificationsItem, addSuccessNotificationsItem } from 'modules/notifications'
import { logError } from 'utils/system.utils'

export const fetchUser: FetchUser = () => {
  return {
    type: UserActionType.FETCH_USER,
    payload: {
      request: {
        url: '/users/user'
      },
      options: {
        onSuccess({ dispatch, response }: { response: UserDataPayload; dispatch: AxiosDispatch }) {
          dispatch(setUser(response))
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          dispatch(resetUser())
          logError(error)
        }
      }
    }
  }
}

export const signIn: SignIn = values => {
  return {
    type: UserActionType.SIGN_IN,
    payload: {
      request: {
        url: '/users/signin',
        method: 'post',
        data: values
      },
      options: {
        onSuccess({ dispatch, response: { user, ...rest } }: { response: UserDataPayload; dispatch: AxiosDispatch }) {
          setAuthToken(user.token_type, user.access_token)

          dispatch(setUser({ user, ...rest }))
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          dispatch(resetUser())
          dispatch(addErrorNotificationsItem(error))
        }
      }
    }
  }
}

export const setUser: SetUser = user => {
  return {
    type: UserActionType.SET_USER,
    payload: user
  }
}

export const resetUser: ResetUser = () => {
  cookies.remove(STORAGE_KEY_AUTH_TOKEN)

  return {
    type: UserActionType.RESET_USER,
    payload: null
  }
}

export const setAuthToken = (type: string, token: string) => {
  cookies.set(STORAGE_KEY_AUTH_TOKEN, `${type} ${token}`)
}

export const contactUs: ContactUs = values => {
  return {
    type: UserActionType.CONTACT_US,
    payload: {
      request: {
        url: '/feedback',
        method: 'post',
        data: values
      },
      options: {
        onSuccess({ dispatch }: { dispatch: AxiosDispatch }) {
          dispatch(addSuccessNotificationsItem({ title: 'NOTIFIER.TITLE.SUCCESS.CONUCT_US' }))
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          dispatch(addErrorNotificationsItem(error))
        }
      }
    }
  }
}
