import {
  DomainsActiveTableHead,
  DomainsActiveTableHeadIds,
  DomainsStorageTableHead,
  DomainsStorageTableHeadIds,
  DomainsTemplate,
  ProviderZoneStatus
} from 'types/domains.types'
import ActiveIcon16 from 'components/shared/icons/16x16/ActiveIcon16'
import StatusPendingIcon16 from 'components/shared/icons/16x16/StatusPendingIcon16'

export const DOMAINS_ACTIVE_TABLE_HEAD: DomainsActiveTableHead = [
  {
    id: DomainsActiveTableHeadIds.CREATED_AT,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.CREATED_AT',
    sortable: true
  },
  {
    id: DomainsActiveTableHeadIds.ID,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.ID'
  },
  {
    id: DomainsActiveTableHeadIds.HTTP_STATUS,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.HTTP_STATUS'
  },
  {
    id: DomainsActiveTableHeadIds.DOMAIN,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.DOMAIN',
    sortable: true
  },
  {
    id: DomainsActiveTableHeadIds.DNS,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.DNS'
  },
  {
    id: DomainsActiveTableHeadIds.DATETIME_START,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.DATETIME_START',
    sortable: true
  },
  {
    id: DomainsActiveTableHeadIds.REQUESTS_BOTS,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.REQUESTS_BOTS'
  },
  {
    id: DomainsActiveTableHeadIds.REQUESTS_KEYS,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.REQUESTS_KEYS'
  },
  {
    id: DomainsActiveTableHeadIds.MARK,
    label: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.HEAD.TITLE.MARK'
  }
]

export const PROVIDER_ZONE_STATUS = {
  [ProviderZoneStatus.PENDING]: {
    text: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.PROVIDER_ZONE_STATUS.PENDING',
    icon: StatusPendingIcon16,
    color: 'inherit'
  },
  [ProviderZoneStatus.ACTIVE]: {
    text: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.PROVIDER_ZONE_STATUS.ACTIVE',
    icon: ActiveIcon16,
    color: 'primary'
  }
}

export const DOMAINS_STORAGE_TABLE_HEAD: DomainsStorageTableHead = [
  {
    id: DomainsStorageTableHeadIds.CREATED_AT,
    label: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.CREATED_AT',
    sortable: true
  },
  {
    id: DomainsStorageTableHeadIds.DOMAIN,
    label: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.NAIM',
    sortable: true
  },
  {
    id: DomainsStorageTableHeadIds.DATA,
    label: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.HEAD.TITLE.INDEX'
  }
]

export const DOMAINS_CREATE_VALUES_DEFAULTS = {
  linksGenerationType: 'subdomains-root',
  anchorGenerationType: 'keywords',
  linksLimit: '100000',
  template: DomainsTemplate.REDIRECT_01,
  serverId: '2S-EPYC',
  farmerId: 'Magbo V2',
  campaignId: 'Mainstream'
}

export const DOMAINS_TYPES_OPTIONS = [
  {
    title: 'FORM.FIELD.LEGEND.DOMAINS_TYPE.DOMAIN_LINK',
    id: 'domain-page',
    text: 'https://domain.com/search-keyword.html'
  },
  {
    title: 'FORM.FIELD.LEGEND.DOMAINS_TYPE.SUBDOMAIN_LINK',
    id: 'subdomains-page',
    text: 'https://sub.domain.com/search-keyword.html'
  },
  {
    title: 'FORM.FIELD.LEGEND.DOMAINS_TYPE.SUBDOMAIN_NO_LINK',
    id: 'subdomains-root',
    text: 'https://sub.domain.com'
  }
]

export const ANCHORS_TYPES_OPTIONS = [
  {
    title: 'keywords',
    id: 'keywords'
  },
  {
    title: 'random',
    id: 'random'
  }
]

export const DOMAINS_TEMPLATES_LIST = [
  {
    id: DomainsTemplate.IFRAME,
    title: 'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.IFRAME'
  },
  {
    id: DomainsTemplate.REDIRECT_01,
    title: 'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.REDIRECT_01'
  },
  {
    id: DomainsTemplate.REDIRECT_02,
    title: 'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.REDIRECT_02'
  },
  {
    id: DomainsTemplate.REDIRECT_03,
    title: 'DOMAINS_CREATE_FORM.DOMAINS_TEMPLATES_LIST.OPTION.REDIRECT_03'
  }
]
