import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const KeysIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2H2.5V0.5H11.5V8.5L15.5 12.5V14.5H14V13L10 9V2ZM9 3H0V12L5.99999 12L6.33299 12L7.99999 12V13.6214L8.38928 14H9.99999V15.5666L10.4512 16.0055L12.9987 16.0055L12.9987 13.5558L9 9.55715V3ZM4 5H5V7H7V8H5V10H4V8H2V7H4V5Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default KeysIcon16
