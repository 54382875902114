import React, { FC, useEffect, useRef, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import camelCase from 'lodash/camelCase'
import moment from 'moment'
import Highcharts, {
  Options,
  SeriesAreaOptions,
  XAxisOptions,
  TooltipFormatterCallbackFunction,
  TooltipPositionerCallbackFunction
} from 'highcharts'
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Card from 'components/shared/Card'
import Tabs from 'components/shared/Tabs'
import { PartnersStatisticsList, StatisticsPeriod } from 'types/statistics.types'
import { MONTHS_LIST, DAY_LIST } from 'constants/shared.contants'
import theme from 'configs/theme.config'
import * as TOKENS from 'constants/tokens'
import styles from './PartnersStatisticsChart.styles'
import './PartnersStatisticsChart.styles.css'

interface PartnersStatisticsChartParams extends BoxProps {
  partnersStatisticsList: PartnersStatisticsList
  period: StatisticsPeriod
  dates: [string, string]
  setPeriod: (period: StatisticsPeriod) => void
}

const TABS_LIST = [
  {
    label: 'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.TAB.DAY',
    value: StatisticsPeriod.DAY
  },
  {
    label: 'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.TAB.WEEK',
    value: StatisticsPeriod.WEEK
  },
  {
    label: 'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.TAB.MONTH',
    value: StatisticsPeriod.MONTH
  }
]

const defaultOptions: Options = {
  title: {
    text: undefined
  },
  chart: {
    type: 'area',
    backgroundColor: 'transparent',
    spacing: [1, 1, 5, 1],
    className: 'highchartsChart'
  },
  legend: {
    enabled: false
  },
  credits: {
    enabled: false
  },
  yAxis: {
    title: {
      text: undefined
    },
    labels: {
      enabled: false
    },
    gridLineWidth: 1,
    gridLineColor: 'rgba(255, 255, 255, 0.07)',
    min: 0,
    minRange: 5
  },
  xAxis: {
    className: 'highchartsXAxis',
    gridLineWidth: 1,
    gridLineColor: 'rgba(255, 255, 255, 0.07)',
    lineWidth: 0,
    tickLength: 0,
    startOnTick: true,
    endOnTick: true,
    tickInterval: 1,
    labels: {
      align: 'left',
      autoRotation: undefined,
      allowOverlap: true,
      style: {
        color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,
        fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE
      },
      y: 20
    },
    crosshair: {
      width: 1,
      dashStyle: 'Dash',
      zIndex: 2,
      color: TOKENS.COMPONENT_COLOR_CHART_03
    }
  },
  tooltip: {
    shared: true,
    shadow: false,
    shape: 'square',
    padding: 0,
    backgroundColor: TOKENS.COMPONENT_COLOR_CARD_LEVEL2,
    borderRadius: parseInt(TOKENS.BASE_BORDER_RADIUS_M),
    className: 'highcharts-tooltip',
    useHTML: true
  },
  plotOptions: {
    area: {
      lineWidth: 1,
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 5,
        lineWidth: 0,
        enabledThreshold: undefined,
        states: {
          hover: {
            enabled: true,
            radiusPlus: 0,
            lineWidthPlus: 0
          }
        }
      },
      states: {
        hover: {
          lineWidth: 1,
          halo: {
            opacity: parseInt(TOKENS.CORE_OPACITY_OPCT_40) / 100,
            size: 8
          }
        }
      }
    }
  },
  series: []
}

const PartnersStatisticsChart: FC<PartnersStatisticsChartParams> = ({
  period,
  dates,
  partnersStatisticsList,
  setPeriod,
  sx
}) => {
  const intl: IntlShape = useIntl()
  const chartComponentRef = useRef<HighchartsReactRefObject>(null)
  const isSMSize = useMediaQuery(theme.breakpoints.down('sm'))
  const isXSSize = useMediaQuery(theme.breakpoints.down('xs'))

  const [chartContainerWidth, setChartContainerWidth] = useState(0)

  const isDay = period === StatisticsPeriod.DAY
  const currentDate = moment(dates[0])

  const chartContainerRef = useRef<HTMLElement>(null)

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      setChartContainerWidth(entries[0].contentRect.width)
    })

    resizeObserver.observe(chartContainerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const tooltipFormatter: TooltipFormatterCallbackFunction = function () {
    const date = isDay ? currentDate : moment().utc().date(Number(this.x))

    return this.points?.reduce(
      function (memo, point) {
        return `
            ${memo}
            <div class="tooltip-item ${camelCase(point.series.name)}-item">
                <span class="tooltip-name">${
                  point.series.name === 'common'
                    ? intl.formatMessage({ id: 'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.PARTNER_NAME.COMMON' })
                    : point.series.name
                }:</span>
                <span class="tooltip-value">${point.y}</span>
            </div>
        `
      },
      `
            <div class="tooltip-header tooltip-item">
                ${intl.formatMessage({ id: DAY_LIST[date.isoWeekday() - 1] })}
                ${date.format('DD/MM')}${isDay ? ` ${this.x}:00` : ''}
            </div>
        `
    )
  }

  const tooltipPositioner: TooltipPositionerCallbackFunction = function (labelWidth, labelHeight, point) {
    const halfLabelWidth = labelWidth / 2
    const scrolledSize = this.chart?.container?.closest('#chartContainer')?.scrollLeft || 0
    const pointX = point.plotX - scrolledSize
    const pointToEnd = chartContainerWidth - pointX

    let x

    if (halfLabelWidth > pointToEnd) {
      x = chartContainerWidth - labelWidth
    } else if (halfLabelWidth > pointX) {
      x = 0
    } else {
      x = pointX - halfLabelWidth
    }

    return {
      x: x + scrolledSize,
      y: this.chart.plotTop + this.chart.chartHeight
    }
  }

  const [options, setOptions] = useState<Options>(defaultOptions)

  useEffect(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      series: partnersStatisticsList.map(seria => ({
        ...seria,
        pointStart: isDay ? 0 : currentDate.date()
      })) as SeriesAreaOptions[],
      xAxis: {
        ...prevOptions.xAxis,
        labels: {
          ...(prevOptions.xAxis as XAxisOptions)?.labels,
          formatter() {
            const month = currentDate.month()
            const first = isDay ? currentDate.format('DD/MM') : intl.formatMessage({ id: MONTHS_LIST[month] })
            const value = isDay ? `${this.value}:00` : `${this.value}`

            return this.isFirst ? first : this.isLast ? '' : value
          }
        }
      },
      tooltip: {
        outside: isSMSize,
        ...defaultOptions.tooltip,
        formatter: tooltipFormatter,
        positioner: isSMSize ? tooltipPositioner : undefined
      }
    }))
  }, [partnersStatisticsList, chartContainerWidth, isSMSize])

  useEffect(() => {
    chartContainerRef.current?.scrollTo({ left: 0 })
  }, [period, chartContainerRef])

  return (
    <Card sx={{ ...styles.statistics, ...sx }}>
      <Box sx={styles.tabs}>
        {!isXSSize && (
          <Typography variant="h6">
            {intl.formatMessage({ id: 'STATISTICS_PAGE.PARTNERS_STATISTICS_CHART.SUBTITLE.PARTNERS' })}
          </Typography>
        )}
        <Tabs
          tabs={TABS_LIST}
          size="small"
          value={period}
          onTabChange={setPeriod}
          variant={isXSSize ? 'fullWidth' : 'standard'}
        />
      </Box>
      <Box id="chartContainer" sx={styles.chart} ref={chartContainerRef}>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
      </Box>
    </Card>
  )
}

export default PartnersStatisticsChart
