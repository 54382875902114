import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchUser, getIsAuthed } from 'modules/user'
import { fetchKeysGroupsList } from 'modules/keys'
import App from './App'

const mapStateToProps = (state: RootState) => {
  return {
    isAuthed: getIsAuthed(state)
  }
}

const mapDispatchToProps = {
  fetchUser,
  fetchKeysGroupsList
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
