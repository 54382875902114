import * as TOKENS from 'constants/tokens'

const styles = {
  card: {
    minHeight: 96,
    height: '100%',

    '& span': {
      color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY
    }
  }
}

export default styles
