import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getIsLoaded, getIsAuthed } from 'modules/user'
import PrivateRoute from './PrivateRoute'

const mapStateToProps = (state: RootState) => {
  return {
    isLoaded: getIsLoaded(state),
    isAuthed: getIsAuthed(state)
  }
}

export default connect(mapStateToProps)(PrivateRoute)
