import { DomainsStorageHash } from 'types/domains.types'
import { KeysHash } from 'types/keys.types'
import { SHELLS_DEFAULT_PAGINATION_PER_PAGE } from './shells.constants'
import { DEFAULT_PAGINATION_PER_PAGE } from './shared.contants'

export const PATH_LANDING = '/'
export const PATH_LANDING_EN = '/en'
export const PATH_SIGN_IN = '/sign-in'

export const PATH_STATISTICS = '/statistics'
export const PATH_STATISTICS_DEFAULT = `${PATH_STATISTICS}?period=week`

export const PATH_DOMAINS = '/domains'
export const SUB_PATH_DOMAINS_STORAGE = 'storage'
export const PATH_DOMAINS_STORAGE = `${PATH_DOMAINS}/${SUB_PATH_DOMAINS_STORAGE}`
export const PATH_DOMAINS_STORAGE_DEFAULT = `${PATH_DOMAINS}/${SUB_PATH_DOMAINS_STORAGE}?sortMethod=desc&sortBy=created_at&page=1&perPage=${DEFAULT_PAGINATION_PER_PAGE}`
export const PATH_DOMAINS_CREATE = `${PATH_DOMAINS_STORAGE}${DomainsStorageHash.CREATE}`
export const PATH_DOMAINS_VALIDATE = `${PATH_DOMAINS_STORAGE}${DomainsStorageHash.VALIDATE}`
export const SUB_PATH_DOMAINS_ACTIVE = 'active'
export const PATH_DOMAINS_ACTIVE = `${PATH_DOMAINS}/${SUB_PATH_DOMAINS_ACTIVE}`
export const PATH_DOMAINS_ACTIVE_DEFAULT = `${PATH_DOMAINS}/${SUB_PATH_DOMAINS_ACTIVE}?sortMethod=desc&sortBy=created_at&page=1&perPage=${DEFAULT_PAGINATION_PER_PAGE}`

export const PATH_KEYS = '/keys'
export const PATH_KEYS_DEFAULT = `${PATH_KEYS}?sortMethod=desc&sortBy=created_at`
export const PATH_KEYS_SNIPPETS = `${PATH_KEYS}${KeysHash.SNIPPETS}`
export const PATH_KEYS_IMPORT = `${PATH_KEYS}${KeysHash.IMPORT}`
export const PATH_KEYS_IMPORTS_DEFAULT = `${PATH_KEYS_DEFAULT}${KeysHash.IMPORT}`
export const PATH_KEYS_ADD_GROUP = `${PATH_KEYS}${KeysHash.ADD_GROUP}`

export const PATH_SHELLS = '/shells'
export const PATH_SHELLS_DEFAULT = `${PATH_SHELLS}?sortMethod=desc&sortBy=created_at&page=1&perPage=${SHELLS_DEFAULT_PAGINATION_PER_PAGE}`

export const PATH_CAMPAIGNS = '/campaigns'
