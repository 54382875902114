import { string, array, number } from 'yup'

export const requiredStringSchema = string().required('FORM.FIELD.VALIDATION.ERROR.REQUIRED')

export const requiredStringMaxSchema = requiredStringSchema.max(255, 'FORM.FIELD.VALIDATION.ERROR.MAX_255')

export const emptyStringSchema = string().min(0)

export const requiredCheckboxGroupSchema = array().of(number()).min(1, 'FORM.FIELD.VALIDATION.ERROR.CHECKBOX.REQUIRED')

export const convertStringToNumber = (value: string) => (isNaN(Number(value)) ? undefined : Number(value))
