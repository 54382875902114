import { SetCampaignsListAction, CampaignsActionType, CampaignsState, SetCampaignAction } from 'types/campaigns.types'

type CampaignsAction = SetCampaignsListAction | SetCampaignAction

const initialState: CampaignsState = {
  campaignsList: []
}

const campaigns = (state = initialState, { type, payload }: CampaignsAction) => {
  switch (type) {
    case CampaignsActionType.SET_CAMPAIGNS_LIST:
      return {
        ...state,
        campaignsList: payload
      }
    case CampaignsActionType.SET_CAMPAIGN:
      return {
        ...state,
        campaignsList: {
          ...state.campaignsList,
          payload
        }
      }
    default:
      return state
  }
}

export default campaigns
