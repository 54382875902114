import { FarmersListAction, MaterialsActionType, MaterialsState, ServersListAction } from 'types/materials.types'

type MaterialsAction = ServersListAction | FarmersListAction

const initialState: MaterialsState = {
  serversList: [],
  farmersList: []
}

const materials = (state = initialState, { type, payload }: MaterialsAction) => {
  switch (type) {
    case MaterialsActionType.SET_SERVERS_LIST:
      return {
        ...state,
        serversList: payload
      }
    case MaterialsActionType.SET_FARMERS_LIST:
      return {
        ...state,
        farmersList: payload
      }
    default:
      return state
  }
}

export default materials
