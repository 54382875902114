import React, { FC } from 'react'
import FieldTextControlled, { FieldTextControlledProps } from 'components/shared/form/FieldTextControlled'

interface FieldTextareaProps {
  limit?: number
}

const FieldTextarea: FC<FieldTextareaProps & FieldTextControlledProps> = ({ limit, ...props }) => {
  return <FieldTextControlled multiline limit={limit} rows={3} {...props} />
}

export default FieldTextarea
