import { FarmsStatisticsTableHead, FarmsStatisticsTableHeadIds } from 'types/statistics.types'

export const FARMS_STATISTICS_TABLE_HEAD: FarmsStatisticsTableHead = [
  {
    id: FarmsStatisticsTableHeadIds.TITLE,
    label: 'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.TITLE'
  },
  {
    id: FarmsStatisticsTableHeadIds.REQUESTS,
    label: 'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.REQUESTS'
  },
  {
    id: FarmsStatisticsTableHeadIds.REQUESTS_HOURLY,
    label: 'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.REQUESTS_HOURLY'
  },
  {
    id: FarmsStatisticsTableHeadIds.DOMAIN_ACTIVE,
    label: 'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.DOMAIN_ACTIVE'
  },
  {
    id: FarmsStatisticsTableHeadIds.DOMAIN_AMOUNT,
    label: 'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.HEAD.TITLE.DOMAIN_AMOUNT'
  }
]
