import {
  NotificationsState,
  NotificationsActionType,
  AddNotificationsItemAction,
  CloseNotificationsItemAction,
  RemoveNotificationsItemAction
} from 'types/notifications.types'

type NotificationsAction = AddNotificationsItemAction | CloseNotificationsItemAction | RemoveNotificationsItemAction

const initialState: NotificationsState = {
  notificationsList: []
}

const notifications = (state: NotificationsState = initialState, { type, payload }: NotificationsAction) => {
  switch (type) {
    case NotificationsActionType.ADD_NOTIFICATIONS_ITEM:
      return {
        ...state,
        notificationsList: [...state.notificationsList, payload]
      }

    case NotificationsActionType.CLOSE_NOTIFICATIONS_ITEM:
      return {
        ...state,
        notificationsList: state.notificationsList.map(notification =>
          payload.dismissAll || notification.key === payload.key
            ? { ...notification, dismissed: true }
            : { ...notification }
        )
      }

    case NotificationsActionType.REMOVE_NOTIFICATIONS_ITEM:
      return {
        ...state,
        notificationsList: state.notificationsList.filter(notification => notification.key !== payload.key)
      }

    default:
      return state
  }
}

export default notifications
