export default {
  'NOTIFIER.TITLE.ERROR.USERS_ANONYMOUS_ACCESS_DENIED': 'Anonymous access denied',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_ACCESS_TOKEN.STRING': 'The field "access_token" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_CLIENT_ID.STRING': 'The field "client_id" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_CLIENT_SECRET.STRING': 'The field "client_secret" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_EMAIL.REQUIRED': 'The field "email" is missing or empty',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_EMAIL.STRING': 'The field "email" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_EMAIL.EMAIL': 'Invalid email address',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_NAME.REQUIRED': 'The field "name" is missing or empty',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_NAME.STRING': 'The field "name" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_PASSWORD.REQUIRED': 'The field "password" is missing or empty',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_PASSWORD.STRING': 'The field "password" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_STATUS.BOOL': 'The field "status" must be a valid boolean',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_TYPE.REQUIRED': 'The field "type" is missing or empty',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_TYPE.STRING': 'The field "type" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_TYPE.IN': 'Unknown value of user credential type',
  'NOTIFIER.TITLE.ERROR.USERS_BADREQUEST_USERNAME.STRING': 'The field "username" must be a valid string',
  'NOTIFIER.TITLE.ERROR.USERS_CREDENTIAL_FAILED_DELETE': 'Failed to delete user credentials',
  'NOTIFIER.TITLE.ERROR.USERS_CREDENTIAL_FAILED_SAVE': 'Failed to save user credentials',
  'NOTIFIER.TITLE.ERROR.USERS_INVALID_ARGUMENT_VALUE': 'Invalid value for argument to get data',
  'NOTIFIER.TITLE.ERROR.USERS_NOTFOUND_CREDENTIAL': 'Failed to find the user credential',
  'NOTIFIER.TITLE.ERROR.USERS_NOTFOUND_USER': 'Failed to find user profile',
  'NOTIFIER.TITLE.ERROR.USERS_USER_ACCESS_DENIED': 'User access denied',
  'NOTIFIER.TITLE.ERROR.USERS_USER_BANNED': 'User is banned',
  'NOTIFIER.TITLE.ERROR.USERS_USER_FAILED_CREATE_ACCESS_TOKEN': 'Failed to create Personal Access Token',
  'NOTIFIER.TITLE.ERROR.USERS_WRONG_EMAIL_OR_PASSWORD': 'Wrong email or password',

  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_PARSER_ID.NUMBER':
    'The field "credential_parser_id" must be numeric',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_PARTNER_ID.NUMBER':
    'The field "credential_partner_id" must be numeric',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_CREDENTIAL_TRACKER_ID.NUMBER':
    'The field "credential_tracker_id" must be numeric',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DOMAINS.REQUIRED': 'The field "domains" is missing or empty',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DOMAINS.STRING': 'The field "domains" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_GROUP_ID.REQUIRED':
    'The field "group_id" is missing or empty, but is required without the field "title"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_GROUP_ID.NUMBER': 'The field "group_id" must be numeric',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TITLE.REQUIRED':
    'The field "title" is missing or empty, but is required without the field "group_id"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TITLE.STRING': 'The field "title" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_TITLE.SIZE_MAXIMUM': 'Content of the field "title" is too long',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DESCRIPTION.STRING': 'The field "description" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_DESCRIPTION.SIZE_MAXIMUM':
    'Content of the field "description" is too long',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FILE.REQUIRED': 'The field "file" is missing or empty',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FILE.FILE': 'The field "file" must contain a valid file',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_FILE.MIME': 'Wrong type of file in the field "file"',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINK.REQUIRED': 'The field "link" is missing or empty',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINK.STRING': 'The field "link" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_LINK.SIZE_MAXIMUM': 'Content of the field "link" is too long',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SOURCE_ID.REQUIRED': 'The field "source_id" is missing or empty',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SOURCE_ID.STRING': 'The field "source_id" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SOURCE_ID.SIZE_MAXIMUM': 'Content of the field "source_id" is too long',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_BY.STRING': 'The field "sort_by" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_BY.IN': 'Unknown value of sort field',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_METHOD.STRING': 'The field "sort_method" must be a valid string',
  'NOTIFIER.TITLE.ERROR.MATERIALS_BADREQUEST_SORT_METHOD.IN': 'Unknown value of sort method',
  'NOTIFIER.TITLE.ERROR.MATERIALS_INVALID_ARGUMENT_VALUE': 'Invalid value for argument to get data',
  'NOTIFIER.TITLE.ERROR.MATERIALS_CAMPAIGN_FAILED_SAVE': 'Failed to save campaign',
  'NOTIFIER.TITLE.ERROR.MATERIALS_GROUP_FAILED_SAVE': 'Failed to save key group',
  'NOTIFIER.TITLE.ERROR.MATERIALS_PARSER_FAILED_SEND_REQUEST': 'Failed to send a request to the parser',
  'NOTIFIER.TITLE.ERROR.MATERIALS_EXTERNAL_PROCESS_FAILED_SAVE': 'Failed to save external process',
  'NOTIFIER.TITLE.ERROR.MATERIALS_KEY_FILE_FAILED_READ': 'Failed to read data from file with a list of keys',
  'NOTIFIER.TITLE.ERROR.MATERIALS_KEY_FILE_FAILED_UPLOAD': 'Failed to upload file with a list of keys',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_CAMPAIGN': 'Failed to find a campaign',
  'NOTIFIER.TITLE.ERROR.MATERIALS_NOTFOUND_GROUP': 'Failed to find a key group',

  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_EMAIL.REQUIRED': 'The field "email" is missing or empty',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_EMAIL.STRING': 'The field "email" must be a valid string',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_EMAIL.EMAIL': 'Invalid email address',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_SUBJECT.STRING': 'The field "subject" must be a valid string',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_MESSAGE.REQUIRED': 'The field "message" is missing or empty',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_MESSAGE.STRING': 'The field "message" must be a valid string',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_BADREQUEST_MESSAGE.SIZE_MAXIMUM': 'Content of the field "message" is too long',
  'NOTIFIER.TITLE.ERROR.FEEDBACK_FEEDBACK_FAILED_SAVE': 'Failed to save feedback message',

  'NOTIFIER.TITLE.ERROR.DEFAULT': 'Sorry, something went wrong.<br />Try again 🥺',

  'NOTIFIER.TITLE.SUCCESS.CONUCT_US': 'Thank you! We have received your message and will contact you shortly',

  'LANDING_PAGE.TITLE.MAKE_MAGIC': 'Make magic.<br />Generate leads.',
  'LANDING_PAGE.MENU.ABOUT_US': 'About',
  'LANDING_PAGE.MENU.CONTACT': 'Contact',
  'LANDING_PAGE.MENU.LOG_IN': 'Log in',
  'LANDING_PAGE.TITLE.LETS_START': 'Let’s start by<br />talking about<br />your goals.',
  'LANDING_PAGE.BUTTON.SUBMIT.CONTACT_US': 'Send',
  'LANDING_PAGE.ADVANTAGES_LIST.TITLE.ORGANIC_TRAFFIC': 'Exclusively<br />Organic Traffic',
  'LANDING_PAGE.ADVANTAGES_LIST.TITLE.INDUSTRY_FOCUS': 'Industry Focus<br />and Innovation',
  'LANDING_PAGE.ADVANTAGES_LIST.TITLE.TECH_SOLUTIONS': 'Tech-Savvy<br />Solutions',
  'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.ORGANIC_TRAFFIC':
    'We specialize in organic traffic in large volumes, ensuring high conversion rates by connecting you with genuinely interested visitors. Say goodbye to irrelevant clicks and experience the power of pure organic traffic.',
  'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.INDUSTRY_FOCUS':
    'We have a strong inclination towards the adult and iGaming industries. We hone our skills by constantly adapting and seeking for fresh approaches in these domains.',
  'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.TECH_SOLUTIONS':
    'Our high-tech team brings extensive experience from large companies. We offer flexible, customized solutions using the latest technology to achieve your unique goals.',

  'FORM.FIELD.TITLE.EMAIL': 'Email',
  'FORM.FIELD.TITLE.CONTUCT_US_SUBJECT': 'Subject',
  'FORM.FIELD.TITLE.CONTUCT_US_MESSAGE': 'Message',

  'FORM.FIELD.HELPER_TEXT.MAX_255': '255 characters max.',

  'FORM.FIELD.VALIDATION.ERROR.REQUIRED': 'This field is required',
  'FORM.FIELD.VALIDATION.ERROR.EMAIL': 'Invalid email address',
  'FORM.FIELD.VALIDATION.ERROR.MAX_255': '255 characters max.'
}
