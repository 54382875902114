import React, { FC } from 'react'
import { useIntl, IntlShape } from 'react-intl'
import MuiTooltip, { TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import styles from './Tooltip.styles'

export interface TooltipProps extends MuiTooltipProps {
  // property for tooltip debugging - leave tooltip opened after mouse leaving
  debug?: string
  type?: 'primary' | 'info'
}

const Tooltip: FC<TooltipProps> = ({ title, debug, children, type = 'primary', ...props }) => {
  const intl: IntlShape = useIntl()

  return (
    <MuiTooltip
      PopperProps={{ sx: styles[type] }}
      leaveDelay={debug ? 10000000 : 0}
      title={
        typeof title === 'string' ? (
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: title, defaultMessage: title })
            }}
          />
        ) : (
          title
        )
      }
      {...props}
    >
      {children}
    </MuiTooltip>
  )
}

export default Tooltip
