import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const FileIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <g id="16/file">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1C2.89543 1 2 1.89543 2 3V13C2 14.1046 2.89543 15 4 15H12C14 15 14 13 14 13V6H9.9C9.1268 6 8.5 5.3732 8.5 4.6V1H4ZM9.5 1L14 5H10.5C9.94772 5 9.5 4.55228 9.5 4V1ZM5 9.5C5 9.22386 5.22386 9 5.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H5.5C5.22386 10 5 9.77614 5 9.5ZM5 11.5C5 11.2239 5.22386 11 5.5 11H10.5C10.7761 11 11 11.2239 11 11.5C11 11.7761 10.7761 12 10.5 12H5.5C5.22386 12 5 11.7761 5 11.5Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}

export default FileIcon16
