import React, { FC, forwardRef, ElementType } from 'react'
import NumberFormat, { NumberFormatProps, InputAttributes } from 'react-number-format'
import FieldTextControlled, { FieldTextControlledProps } from 'components/shared/form/FieldTextControlled'
import { ChangeTextField } from 'types/form.types'

interface FieldNumberProps {
  numberProps?: NumberFormatProps
}

interface NumberFormatComponentProps {
  onChange: ChangeTextField
  name: string
}

const NumberFormatComponent = forwardRef<NumberFormat<InputAttributes>, NumberFormatComponentProps>(
  ({ onChange, ...props }, ref) => (
    <NumberFormat
      onValueChange={values => {
        onChange?.({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      getInputRef={ref}
      {...props}
    />
  )
)

const FieldNumber: FC<FieldNumberProps & FieldTextControlledProps & NumberFormatProps> = ({
  numberProps,
  sx,
  afterFieldChange,
  ...props
}) => (
  <FieldTextControlled
    sx={sx}
    afterFieldChange={afterFieldChange}
    InputProps={{ inputComponent: NumberFormatComponent as ElementType<unknown> }}
    inputProps={{ ...props, ...numberProps }}
    {...props}
  />
)

export default FieldNumber
