import { Pagination, PaginationPayload, SortMethod } from './shared.types'

export enum ShellsActionType {
  FETCH_SHELLS_LIST = 'FETCH_SHELLS_LIST',
  SET_SHELLS_LIST = 'SET_SHELLS_LIST',
  ADD_SHELL = 'ADD_SHELL',
  UPDATE_SHELL = 'UPDATE_SHELL',
  SET_SHELL = 'SET_SHELL'
}

export interface ShellsState {
  shellsList: ShellsList
  shellsListPagination: Pagination
}

export enum ShellsTableHeadIds {
  CREATED_AT = 'created_at',
  ID = 'id',
  COST = 'cost',
  DOMAIN = 'domain',
  METHOD = 'method',
  FILE_STATUS = 'file_status',
  CACHED_STATUS = 'cached_status',
  REQUESTS_TODAY = 'requests_today',
  REQUESTS_YESTERDAY = 'requests_yesterday'
}

export interface ShellsTableHeadCell {
  id: ShellsTableHeadIds
  label: string
  sortable?: boolean
}

export type ShellsTableHead = ShellsTableHeadCell[]

export type FetchShellsList = (
  sortMethod: SortMethod,
  sortBy: ShellsTableHeadIds,
  page: number,
  perPage: number
) => void

export interface FetchShellsListPayload extends PaginationPayload {
  items: ShellsListPayload
}

export interface ShellsItemPayload {
  id: number
  user_id: number
  domain: string
  cost: number
  ip: string
  file_status: boolean
  fget_status: boolean
  cached_status: boolean
  status: boolean
  requests_today: number
  requests_yesterday: number
  created_at: Date
  last_requested_at: Date
}

export type ShellsListPayload = ShellsItemPayload[]

export interface ShellsItem {
  createdAt: Date
  id: number
  cost: number
  domain: string
  fgetStatus: boolean
  cachedStatus: boolean
  requestsToday: number
  requestsYesterday: number
  fileStatus: boolean
  lastRequestedAt: Date
}

export type ShellsList = ShellsItem[]

export interface SetShellsListAction {
  type: ShellsActionType.SET_SHELLS_LIST
  payload: FetchShellsListPayload
}

export enum ShellsHash {
  ADD = '#add'
}

export interface AddShellValues {
  domain: string
  cost: number
  fgetStatus: boolean
  cachedStatus: boolean
}

export type AddShell = (data: AddShellValues) => void

export interface UpdateShellValues {
  status?: boolean
  fileStatus?: boolean
}

export type UpdateShell = (data: UpdateShellValues, shellId: number) => void

export interface SetShellAction {
  type: ShellsActionType.SET_SHELL
  payload: { data: ShellsItemPayload; shellId: number }
}

export interface ShellsFGetStatusItem {
  value: boolean
  title: string
}
