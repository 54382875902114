import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const KeyIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2H1V5.92113L3 3.99769V2ZM1 7.30854L4 4.42337V2L5 2L5 5L2 8H3.5L6 5.5L6 2L10 2V5.99769L9 7L9 4L5 8L5 11V14H3V10H2V14H1V7.30854ZM6 14H8V12L9 11H10L9 12V14H9.02487L10.9855 16H13V18.055L13.9264 19H16V21.1153L18 23.1555H22.2318V18.9891L13 9.75736V8L12 9H10.5L13 6.5V2H11V6.42337L6 11.7128V14Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default KeyIcon24
