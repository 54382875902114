import { object, mixed, string } from 'yup'
import { requiredStringMaxSchema, emptyStringSchema } from 'utils/schemas.utils'

export default object().shape(
  {
    groupId: string().when(['title'], title => {
      return title ? emptyStringSchema : requiredStringMaxSchema
    }),
    title: string().when('groupId', groupId => {
      return groupId ? emptyStringSchema : requiredStringMaxSchema
    }),
    file: mixed().required('FORM.FIELD.VALIDATION.ERROR.REQUIRED')
  },
  [['title', 'groupId']]
)
