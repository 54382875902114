import React, { FC } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import camelCase from 'lodash/camelCase'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import Table from 'components/shared/Table'
import Card from 'components/shared/Card'
import ThousandNumber from 'components/shared/ThousandNumber'
import { FARMS_STATISTICS_TABLE_HEAD } from 'constants/statistics.constants'
import { FarmsStatisticsList, FarmsStatisticsTableHeadIds } from 'types/statistics.types'
import theme from 'configs/theme.config'

interface FarmsStatisticsParams {
  farmsStatisticsList: FarmsStatisticsList
}

const FarmsStatistics: FC<FarmsStatisticsParams> = ({ farmsStatisticsList }) => {
  const intl: IntlShape = useIntl()
  const isSMSize = useMediaQuery(theme.breakpoints.down('sm'))

  const isFarms = farmsStatisticsList.length > 0

  if (!isFarms) {
    return null
  }

  return (
    <>
      <Typography mb={2} mt={6} variant="h6">
        {intl.formatMessage({ id: 'STATISTICS_PAGE.FARMS_STATISTICS_TABLE.SUBTITLE.FARMS' })}
      </Typography>
      {isSMSize ? (
        farmsStatisticsList.map(row => {
          return (
            <Card mb={2} key={row.id}>
              {FARMS_STATISTICS_TABLE_HEAD.map(item => (
                <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center" height={40}>
                  {item.id === FarmsStatisticsTableHeadIds.TITLE ? (
                    <Typography variant="h5">{row.title}</Typography>
                  ) : (
                    <>
                      <Typography variant="XSRegular" mr={2} color="textSecondary">
                        {intl.formatMessage({ id: item.label })}
                      </Typography>
                      <Typography variant="XSRegular">{row[camelCase(item.id)]}</Typography>
                    </>
                  )}
                </Box>
              ))}
            </Card>
          )
        })
      ) : (
        <Table tableHead={FARMS_STATISTICS_TABLE_HEAD} isTableData>
          {farmsStatisticsList.map(row => {
            return (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography variant="XSSemiBold">{row.title}</Typography>
                </TableCell>
                <TableCell>
                  <ThousandNumber value={row.requests} />
                </TableCell>
                <TableCell>
                  <ThousandNumber value={row.requestsHourly} />
                </TableCell>
                <TableCell>
                  <ThousandNumber value={row.domainActive} />
                </TableCell>
                <TableCell>
                  <ThousandNumber value={row.domainAmount} />
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
      )}
    </>
  )
}

export default FarmsStatistics
