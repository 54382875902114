import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const AutomaticIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H11H12.5C11.7872 14.9903 11 14.3708 11 13.5343V13.5H8.4C7.6268 13.5 7 12.8732 7 12.1V10.9C7 10.1268 7.6268 9.5 8.4 9.5H11V9.46568C11 8.38835 12.1251 7.77555 13 8.19692V6H8.9C8.1268 6 7.5 5.3732 7.5 4.6V1H3ZM8.5 1L13 5H9.5C8.94772 5 8.5 4.55228 8.5 4V1ZM12.6828 9.11796C12.4309 8.86597 12 9.04444 12 9.4008V10.4351H8.4C8.17909 10.4351 8 10.6142 8 10.8351V12.0351C8 12.256 8.17909 12.4351 8.4 12.4351H12V13.4694C12 13.8258 12.4309 14.0043 12.6828 13.7523L15 11.718C15.1562 11.5617 15.1562 11.3085 15 11.1523L12.6828 9.11796Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default AutomaticIcon16
