import { connect } from 'react-redux'
import { getNickname } from 'modules/user'
import { RootState } from 'types/system.types'
import UserName from './UserName'

const mapStateToProps = (state: RootState) => {
  return {
    nickname: getNickname(state)
  }
}

export default connect(mapStateToProps)(UserName)
