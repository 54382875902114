import { UserActionType, UserState, SetUserAction, ResetUserAction } from 'types/user.types'
import { normalizeCredentials, normalizeUser } from './user.normalizers'

type UserAction = SetUserAction | ResetUserAction

const initialState: UserState = {
  isLoaded: false,
  isAuthed: false,
  id: null,
  nickname: '',
  credentials: {}
}

const user = (state = initialState, { type, payload }: UserAction) => {
  switch (type) {
    case UserActionType.SET_USER:
      const { user, credentials } = payload

      return {
        ...state,
        ...normalizeUser(user),
        credentials: normalizeCredentials(credentials),
        isLoaded: true,
        isAuthed: true
      }
    case UserActionType.RESET_USER:
      return {
        ...state,
        ...initialState,
        isLoaded: true
      }
    default:
      return state
  }
}

export default user
