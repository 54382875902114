import { ChangeEvent, JSX } from 'react'

export enum SortMethod {
  ASC = 'asc',
  DESC = 'desc'
}

export interface PaginationPayload {
  count: number
  current_page: number
  last_page: number
  per_page: number
  total: number
}

export interface Pagination {
  count: number
  page: number
  lastPage: number
  perPage: number
  total: number
}

export type HandlePaginationChange = (event: ChangeEvent<unknown>, value: number) => void

export interface CheckboxItem {
  id: number
  title: string
}

export type CheckboxOptions = CheckboxItem[] | null

export interface TabItem {
  path?: string
  value?: string
  label: string
  icon?: JSX.Element
}

export type TabsList = TabItem[]
