/**
 * File generated by the Figma Token Engine
 * Do not edit directly
 */

export const BUTTON_SECONDARY_DEFAULT = { color: '#52525b', width: 1, style: 'solid' }
export const BUTTON_SECONDARY_HOVER = { color: '#71717a', width: 1, style: 'solid' }
export const BADGE_DEFAULT = { color: '#ffffff33', width: 1, style: 'solid' }
export const CARD_DEFAULT = { color: '#3f3f46', width: 1, style: 'solid' }
export const INPUT_NOTACTIVE = { color: '#3f3f46', width: 1, style: 'solid' }
export const INPUT_DEFAULT = { color: '#115e59', width: 1, style: 'solid' }
export const INPUT_DEFAULT_ERROR = { color: '#ef4444', width: 1, style: 'solid' }
export const INPUT_HOVER = { color: '#0d9488', width: 1, style: 'solid' }
export const COMPONENT_COLOR_BUTTON_BG_PRIMARY_DEFAULT = '#14b8a6'
export const COMPONENT_COLOR_BUTTON_BG_PRIMARY_HOVER = '#0d9488'
export const COMPONENT_COLOR_BUTTON_BG_PRIMARY_FOCUSED = '#0f766e'
export const COMPONENT_COLOR_BUTTON_BG_DANGER_DEFAULT = '#ef4444'
export const COMPONENT_COLOR_BUTTON_BG_DANGER_HOVER = '#dc2626'
export const COMPONENT_COLOR_BUTTON_BG_DANGER_FOCUSED = '#b91c1c'
export const COMPONENT_COLOR_BUTTON_BG_WARNING_DEFAULT = '#f59e0b'
export const COMPONENT_COLOR_BUTTON_BG_WARNING_HOVER = '#d97706'
export const COMPONENT_COLOR_BUTTON_BG_WARNING_FOCUSED = '#b45309'
export const COMPONENT_COLOR_BUTTON_BG_SUCCESS_DEFAULT = '#22c55e'
export const COMPONENT_COLOR_BUTTON_BG_SUCCESS_HOVER = '#16a34a'
export const COMPONENT_COLOR_BUTTON_BG_SUCCESS_FOCUSED = '#15803d'
export const COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER = '#ffffff0a'
export const COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED = '#ffffff12'
export const COMPONENT_COLOR_BUTTON_FG_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_INPUT_BG_FOCUSED = '#27272a'
export const COMPONENT_COLOR_INPUT_BG_ACTIVE = '#5eead412'
export const COMPONENT_COLOR_INPUT_BG_DEFAULT = '#27272a99'
export const COMPONENT_COLOR_INPUT_BG_ERROR = '#7f1d1d'
export const COMPONENT_COLOR_INPUT_FG_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_INPUT_FG_SECONDARY = '#a1a1aa'
export const COMPONENT_COLOR_INPUT_FG_DANGER = '#ef4444'
export const COMPONENT_COLOR_BADGE_BG_ERROR = '#7f1d1d'
export const COMPONENT_COLOR_BADGE_BG_DEFAULT = '#ffffff12'
export const COMPONENT_COLOR_BADGE_BG_DEFAULT_EXPLICIT = '#ffffff33'
export const COMPONENT_COLOR_BADGE_BG_OUTSTANDING = '#ffffff'
export const COMPONENT_COLOR_BADGE_BG_PRIMARY = '#14b8a6'
export const COMPONENT_COLOR_BADGE_BG_SUCCESS = '#22c55e'
export const COMPONENT_COLOR_BADGE_BG_NEW = '#ef4444'
export const COMPONENT_COLOR_BADGE_BG_NEW_GHOST = '#ef444466'
export const COMPONENT_COLOR_BADGE_FG_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_BADGE_FG_SUCCESS = '#22c55e'
export const COMPONENT_COLOR_BADGE_FG_WARNING = '#f59e0b'
export const COMPONENT_COLOR_BADGE_FG_DANGER = '#ef4444'
export const COMPONENT_COLOR_BADGE_FG_SECONDARY = '#a1a1aa'
export const COMPONENT_COLOR_BADGE_FG_OUTSTANDING = '#18181b'
export const COMPONENT_COLOR_SCROLL_SECONDARY = '#ffffff33'
export const COMPONENT_COLOR_ICON_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_ICON_SECONDARY = '#a1a1aa'
export const COMPONENT_COLOR_ICON_TERTIARY = '#71717a'
export const COMPONENT_COLOR_ICON_ACTION = '#14b8a6'
export const COMPONENT_COLOR_ICON_WARNING = '#f59e0b'
export const COMPONENT_COLOR_ICON_SUCCESS = '#22c55e'
export const COMPONENT_COLOR_ICON_DANGER = '#ef4444'
export const COMPONENT_COLOR_LIST_BG_HOVER = '#ffffff12'
export const COMPONENT_COLOR_LIST_BG_SELECTED = '#134e4a'
export const COMPONENT_COLOR_LIST_FG_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_TEXT_BODY_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_TEXT_BODY_ACTIVE = '#14b8a6'
export const COMPONENT_COLOR_TEXT_BODY_SUCCESS = '#22c55e'
export const COMPONENT_COLOR_TEXT_BODY_WARNING = '#f59e0b'
export const COMPONENT_COLOR_TEXT_BODY_DANGER = '#ef4444'
export const COMPONENT_COLOR_TEXT_BODY_SECONDARY = '#a1a1aa'
export const COMPONENT_COLOR_TEXT_BODY_TERTIARY = '#71717a'
export const COMPONENT_COLOR_TEXT_BODY_OUTSTANDING = '#18181b'
export const COMPONENT_COLOR_TABLE_BG_SELECTED = '#5eead412'
export const COMPONENT_COLOR_TABLE_BG_DANGER = '#ef444412'
export const COMPONENT_COLOR_TABLE_BG_HOVER = '#ffffff12'
export const COMPONENT_COLOR_TABLE_BG_FLOATING = '#27272ae6'
export const COMPONENT_COLOR_TAB_BG_DEFAULT = 'linear-gradient(180deg, #ffffff12 0%, #ffffff00 100%)'
export const COMPONENT_COLOR_TAB_BG_ACTIVE = 'linear-gradient(180deg, #ffffff20 0%, #ffffff00 100%)'
export const COMPONENT_COLOR_CARD_ACTIVE = '#ffffff12'
export const COMPONENT_COLOR_CARD_LEVEL1 = '#27272a4d'
export const COMPONENT_COLOR_CARD_LEVEL1_CONTRASTED = '#27272a99'
export const COMPONENT_COLOR_CARD_POPUP = '#18181b'
export const COMPONENT_COLOR_CARD_SIDEBAR = '#18181b'
export const COMPONENT_COLOR_CARD_LEVEL2 = '#27272a'
export const COMPONENT_COLOR_ALERT_BG_DEFAULT = '#27272a'
export const COMPONENT_COLOR_ALERT_BG_OUTSTANDING = '#ffffff'
export const COMPONENT_COLOR_ALERT_BG_PRIMARY = '#14b8a6'
export const COMPONENT_COLOR_ALERT_BG_SUCCESS = '#22c55e'
export const COMPONENT_COLOR_ALERT_BG_WARNING = '#f59e0b'
export const COMPONENT_COLOR_ALERT_BG_NEW = '#ef4444'
export const COMPONENT_COLOR_ALERT_FG_PRIMARY = '#ffffff'
export const COMPONENT_COLOR_ALERT_FG_SUCCESS = '#22c55e'
export const COMPONENT_COLOR_ALERT_FG_WARNING = '#f59e0b'
export const COMPONENT_COLOR_ALERT_FG_DANGER = '#ef4444'
export const COMPONENT_COLOR_ALERT_FG_SECONDARY = '#a1a1aa'
export const COMPONENT_COLOR_ALERT_FG_OUTSTANDING = '#18181b'
export const COMPONENT_COLOR_CHART_00 = '#98F4E2'
export const COMPONENT_COLOR_CHART_00_01 = '#98f4e21a'
export const COMPONENT_COLOR_CHART_01 = '#C4B5FD'
export const COMPONENT_COLOR_CHART_01_01 = '#c4b5fd1a'
export const COMPONENT_COLOR_CHART_02 = '#22D3EE'
export const COMPONENT_COLOR_CHART_02_01 = '#22d3ee1a'
export const COMPONENT_COLOR_CHART_03 = '#FFFFFF'
export const COMPONENT_COLOR_CHART_03_01 = '#ffffff1a'
export const COMPONENT_COLOR_CHART_04 = '#A1A1AA'
export const COMPONENT_COLOR_CHART_04_01 = '#a1a1aa1a'
export const COMPONENT_COLOR_CHART_05_01 = '#6cb0ff1a'
export const COMPONENT_COLOR_CHART_05 = '#6CB0FF'
export const SEMANTIC_COLOR_ACTION_DEFAULT = '#14b8a6'
export const SEMANTIC_COLOR_ACTION_HOVER = '#0d9488'
export const SEMANTIC_COLOR_ACTION_FOCUSED = '#0f766e'
export const SEMANTIC_COLOR_ACTION_FOCUSED_SUBDUED = '#134e4a'
export const SEMANTIC_COLOR_ACTION_GHOST_07 = '#5eead412'
export const SEMANTIC_COLOR_ACTION_GHOST_20 = '#5eead433'
export const SEMANTIC_COLOR_BG_LEVEL1 = '#18181b'
export const SEMANTIC_COLOR_BG_LEVEL2 = '#27272a'
export const SEMANTIC_COLOR_BG_LEVEL2_30 = '#27272a4d'
export const SEMANTIC_COLOR_BG_LEVEL2_60 = '#27272a99'
export const SEMANTIC_COLOR_BG_LEVEL2_90 = '#27272ae6'
export const SEMANTIC_COLOR_BG_OUTSTANDING = '#ffffff'
export const SEMANTIC_COLOR_BG_OUTSTANDING_04 = '#ffffff0a'
export const SEMANTIC_COLOR_BG_OUTSTANDING_07 = '#ffffff12'
export const SEMANTIC_COLOR_BG_OUTSTANDING_20 = '#ffffff33'
export const SEMANTIC_COLOR_SUCCESS_DEFAULT = '#22c55e'
export const SEMANTIC_COLOR_SUCCESS_HOVER = '#16a34a'
export const SEMANTIC_COLOR_SUCCESS_FOCUSED = '#15803d'
export const SEMANTIC_COLOR_WARNING_DEFAULT = '#f59e0b'
export const SEMANTIC_COLOR_WARNING_HOVER = '#d97706'
export const SEMANTIC_COLOR_WARNING_FOCUSED = '#b45309'
export const SEMANTIC_COLOR_DANGER_DEFAULT = '#ef4444'
export const SEMANTIC_COLOR_DANGER_GHOST_07 = '#ef444412'
export const SEMANTIC_COLOR_DANGER_HOVER = '#dc2626'
export const SEMANTIC_COLOR_DANGER_FOCUSED = '#b91c1c'
export const SEMANTIC_COLOR_DANGER_FOCUSED_STRONGER = '#7f1d1d'
export const SEMANTIC_COLOR_FG_PRIMARY = '#ffffff'
export const SEMANTIC_COLOR_FG_SECONDARY = '#a1a1aa'
export const SEMANTIC_COLOR_FG_TERTIARY = '#71717a'
export const SEMANTIC_COLOR_FG_OUTSTANDING = '#18181b'
export const TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT = 400
export const TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT = '16px'
export const TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE = '12px'
export const TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_XS_REGULAR_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_XS_MEDIUM_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_XS_MEDIUM_FONT_WEIGHT = 500
export const TYPOGRAPHY_BODY_XS_MEDIUM_LINE_HEIGHT = '16px'
export const TYPOGRAPHY_BODY_XS_MEDIUM_FONT_SIZE = '12px'
export const TYPOGRAPHY_BODY_XS_MEDIUM_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_XS_MEDIUM_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_XS_MEDIUM_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_WEIGHT = 600
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_LINE_HEIGHT = '16px'
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_SIZE = '12px'
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_XS_SEMI_BOLD_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT = 400
export const TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT = '20px'
export const TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE = '14px'
export const TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_S_REGULAR_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_S_MEDIUM_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_S_MEDIUM_FONT_WEIGHT = 500
export const TYPOGRAPHY_BODY_S_MEDIUM_LINE_HEIGHT = '20px'
export const TYPOGRAPHY_BODY_S_MEDIUM_FONT_SIZE = '14px'
export const TYPOGRAPHY_BODY_S_MEDIUM_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_S_MEDIUM_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_S_MEDIUM_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_WEIGHT = 600
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_LINE_HEIGHT = '20px'
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_SIZE = '14px'
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_S_SEMI_BOLD_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_M_REGULAR_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_M_REGULAR_FONT_WEIGHT = 400
export const TYPOGRAPHY_BODY_M_REGULAR_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_BODY_M_REGULAR_FONT_SIZE = '16px'
export const TYPOGRAPHY_BODY_M_REGULAR_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_M_REGULAR_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_M_REGULAR_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_M_MEDIUM_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_M_MEDIUM_FONT_WEIGHT = 500
export const TYPOGRAPHY_BODY_M_MEDIUM_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_BODY_M_MEDIUM_FONT_SIZE = '16px'
export const TYPOGRAPHY_BODY_M_MEDIUM_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_M_MEDIUM_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_M_MEDIUM_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_WEIGHT = 600
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_SIZE = '16px'
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_M_SEMI_BOLD_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_L_REGULAR_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_L_REGULAR_FONT_WEIGHT = 400
export const TYPOGRAPHY_BODY_L_REGULAR_LINE_HEIGHT = '28px'
export const TYPOGRAPHY_BODY_L_REGULAR_FONT_SIZE = '18px'
export const TYPOGRAPHY_BODY_L_REGULAR_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_L_REGULAR_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_L_REGULAR_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_L_MEDIUM_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_L_MEDIUM_FONT_WEIGHT = 500
export const TYPOGRAPHY_BODY_L_MEDIUM_LINE_HEIGHT = '28px'
export const TYPOGRAPHY_BODY_L_MEDIUM_FONT_SIZE = '18px'
export const TYPOGRAPHY_BODY_L_MEDIUM_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_L_MEDIUM_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_L_MEDIUM_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_FONT_WEIGHT = 600
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_LINE_HEIGHT = '28px'
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_FONT_SIZE = '18px'
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_L_SEMI_BOLD_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_XXS_REGULAR_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_XXS_REGULAR_FONT_WEIGHT = 400
export const TYPOGRAPHY_BODY_XXS_REGULAR_LINE_HEIGHT = '12px'
export const TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE = '10px'
export const TYPOGRAPHY_BODY_XXS_REGULAR_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_XXS_MEDIUM_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_XXS_MEDIUM_FONT_WEIGHT = 500
export const TYPOGRAPHY_BODY_XXS_MEDIUM_LINE_HEIGHT = '12px'
export const TYPOGRAPHY_BODY_XXS_MEDIUM_FONT_SIZE = '10px'
export const TYPOGRAPHY_BODY_XXS_MEDIUM_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_XXS_MEDIUM_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_XXS_MEDIUM_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_FONT_WEIGHT = 600
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_LINE_HEIGHT = '12px'
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_FONT_SIZE = '10px'
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_TEXT_CASE = 'none'
export const TYPOGRAPHY_BODY_XXS_SEMI_BOLD_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_INPUT_S_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_INPUT_S_FONT_WEIGHT = 400
export const TYPOGRAPHY_INPUT_S_LINE_HEIGHT = '20px'
export const TYPOGRAPHY_INPUT_S_FONT_SIZE = '14px'
export const TYPOGRAPHY_INPUT_S_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_INPUT_S_TEXT_CASE = 'none'
export const TYPOGRAPHY_INPUT_S_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_INPUT_M_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_INPUT_M_FONT_WEIGHT = 400
export const TYPOGRAPHY_INPUT_M_LINE_HEIGHT = '20px'
export const TYPOGRAPHY_INPUT_M_FONT_SIZE = '14px'
export const TYPOGRAPHY_INPUT_M_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_INPUT_M_TEXT_CASE = 'none'
export const TYPOGRAPHY_INPUT_M_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_INPUT_L_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_INPUT_L_FONT_WEIGHT = 400
export const TYPOGRAPHY_INPUT_L_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_INPUT_L_FONT_SIZE = '16px'
export const TYPOGRAPHY_INPUT_L_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_INPUT_L_TEXT_CASE = 'none'
export const TYPOGRAPHY_INPUT_L_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BUTTON_S_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BUTTON_S_FONT_WEIGHT = 600
export const TYPOGRAPHY_BUTTON_S_LINE_HEIGHT = '20px'
export const TYPOGRAPHY_BUTTON_S_FONT_SIZE = '14px'
export const TYPOGRAPHY_BUTTON_S_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BUTTON_S_TEXT_CASE = 'none'
export const TYPOGRAPHY_BUTTON_S_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BUTTON_M_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BUTTON_M_FONT_WEIGHT = 600
export const TYPOGRAPHY_BUTTON_M_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_BUTTON_M_FONT_SIZE = '16px'
export const TYPOGRAPHY_BUTTON_M_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BUTTON_M_TEXT_CASE = 'none'
export const TYPOGRAPHY_BUTTON_M_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_BUTTON_L_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_BUTTON_L_FONT_WEIGHT = 600
export const TYPOGRAPHY_BUTTON_L_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_BUTTON_L_FONT_SIZE = '16px'
export const TYPOGRAPHY_BUTTON_L_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_BUTTON_L_TEXT_CASE = 'none'
export const TYPOGRAPHY_BUTTON_L_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H6_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_DESKTOP_H6_FONT_WEIGHT = 600
export const TYPOGRAPHY_HEADING_DESKTOP_H6_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_HEADING_DESKTOP_H6_FONT_SIZE = '18px'
export const TYPOGRAPHY_HEADING_DESKTOP_H6_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_DESKTOP_H6_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H6_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_WEIGHT = 600
export const TYPOGRAPHY_HEADING_DESKTOP_H5_LINE_HEIGHT = '32px'
export const TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_SIZE = '24px'
export const TYPOGRAPHY_HEADING_DESKTOP_H5_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_DESKTOP_H5_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H5_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_WEIGHT = 600
export const TYPOGRAPHY_HEADING_DESKTOP_H3_LINE_HEIGHT = '36px'
export const TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_SIZE = '28px'
export const TYPOGRAPHY_HEADING_DESKTOP_H3_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_DESKTOP_H3_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H3_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H2_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_DESKTOP_H2_FONT_WEIGHT = 700
export const TYPOGRAPHY_HEADING_DESKTOP_H2_LINE_HEIGHT = '44px'
export const TYPOGRAPHY_HEADING_DESKTOP_H2_FONT_SIZE = '36px'
export const TYPOGRAPHY_HEADING_DESKTOP_H2_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H2_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H2_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_DESKTOP_H1_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_DESKTOP_H1_FONT_WEIGHT = 700
export const TYPOGRAPHY_HEADING_DESKTOP_H1_LINE_HEIGHT = '56px'
export const TYPOGRAPHY_HEADING_DESKTOP_H1_FONT_SIZE = '48px'
export const TYPOGRAPHY_HEADING_DESKTOP_H1_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_DESKTOP_H1_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_DESKTOP_H1_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H6_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_MOBILE_H6_FONT_WEIGHT = 600
export const TYPOGRAPHY_HEADING_MOBILE_H6_LINE_HEIGHT = '24px'
export const TYPOGRAPHY_HEADING_MOBILE_H6_FONT_SIZE = '18px'
export const TYPOGRAPHY_HEADING_MOBILE_H6_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_MOBILE_H6_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H6_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H5_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_MOBILE_H5_FONT_WEIGHT = 600
export const TYPOGRAPHY_HEADING_MOBILE_H5_LINE_HEIGHT = '28px'
export const TYPOGRAPHY_HEADING_MOBILE_H5_FONT_SIZE = '20px'
export const TYPOGRAPHY_HEADING_MOBILE_H5_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_MOBILE_H5_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H5_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H3_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_MOBILE_H3_FONT_WEIGHT = 600
export const TYPOGRAPHY_HEADING_MOBILE_H3_LINE_HEIGHT = '32px'
export const TYPOGRAPHY_HEADING_MOBILE_H3_FONT_SIZE = '24px'
export const TYPOGRAPHY_HEADING_MOBILE_H3_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_MOBILE_H3_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H3_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H2_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_MOBILE_H2_FONT_WEIGHT = 700
export const TYPOGRAPHY_HEADING_MOBILE_H2_LINE_HEIGHT = '40px'
export const TYPOGRAPHY_HEADING_MOBILE_H2_FONT_SIZE = '32px'
export const TYPOGRAPHY_HEADING_MOBILE_H2_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H2_TEXT_DECORATION = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H2_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_MOBILE_H1_FONT_FAMILY = 'Inter'
export const TYPOGRAPHY_HEADING_MOBILE_H1_FONT_WEIGHT = 700
export const TYPOGRAPHY_HEADING_MOBILE_H1_LINE_HEIGHT = '44px'
export const TYPOGRAPHY_HEADING_MOBILE_H1_FONT_SIZE = '36px'
export const TYPOGRAPHY_HEADING_MOBILE_H1_LETTER_SPACING = 'normal'
export const TYPOGRAPHY_HEADING_MOBILE_H1_TEXT_CASE = 'none'
export const TYPOGRAPHY_HEADING_MOBILE_H1_TEXT_DECORATION = 'none'
export const BASE_BORDER_RADIUS_XXXS = '1px'
export const BASE_BORDER_RADIUS_XXS = '2px'
export const BASE_BORDER_RADIUS_XS = '4px'
export const BASE_BORDER_RADIUS_S = '6px'
export const BASE_BORDER_RADIUS_M = '8px'
export const BASE_BORDER_RADIUS_L = '16px'
export const BASE_BORDER_RADIUS_XL = '24px'
export const BASE_BORDER_RADIUS_SCALE = '1px'
export const BASE_BORDER_WIDTH_S = '1px'
export const BASE_BORDER_WIDTH_M = '2px'
export const BASE_BORDER_WIDTH_L = '4px'
export const BASE_SPACING_SPCNG_0 = 0
export const BASE_SPACING_SPCNG_0_25 = '2px'
export const BASE_SPACING_SPCNG_1 = '8px'
export const BASE_SPACING_SPCNG_2 = '16px'
export const BASE_SPACING_SPCNG_3 = '24px'
export const BASE_SPACING_SPCNG_4 = '32px'
export const BASE_SPACING_SPCNG_5 = '40px'
export const BASE_SPACING_SPCNG_6 = '48px'
export const BASE_SPACING_SPCNG_7 = '56px'
export const BASE_SPACING_SPCNG_8 = '64px'
export const BASE_SPACING_SPCNG_10 = '80px'
export const BASE_SPACING_SPCNG_12 = '96px'
export const BASE_SPACING_SPCNG_16 = '128px'
export const BASE_SPACING_SPCNG_20 = '160px'
export const BASE_SPACING_SPCNG_25 = '200px'
export const BASE_SPACING_SPCNG_32 = '256px'
export const BASE_SPACING_SPCNG_0_5 = '4px'
export const BASE_SPACING_SPCNG_0_75 = '6px'
export const BASE_SPACING_SPCNG_1_5 = '12px'
export const BASE_SPACING_SPCNG_2_5 = '20px'
export const BASE_SPACING_SCALE = '8px'
export const BASE_SIZING_SIZING_0_25 = '2px'
export const BASE_SIZING_SIZING_0_5 = '4px'
export const BASE_SIZING_SIZING_0_75 = '6px'
export const BASE_SIZING_SIZING_1 = '8px'
export const BASE_SIZING_SIZING_2 = '16px'
export const BASE_SIZING_SIZING_1_5 = '12px'
export const BASE_SIZING_SIZING_2_5 = '20px'
export const BASE_SIZING_SIZING_3 = '24px'
export const BASE_SIZING_SIZING_4 = '32px'
export const BASE_SIZING_SIZING_5 = '40px'
export const BASE_SIZING_SIZING_6 = '48px'
export const BASE_SIZING_SIZING_7 = '56px'
export const BASE_SIZING_SIZING_8 = '64px'
export const BASE_SIZING_SIZING_10 = '80px'
export const BASE_SIZING_SIZING_12 = '96px'
export const BASE_SIZING_SIZING_16 = '128px'
export const BASE_SIZING_SIZING_20 = '160px'
export const BASE_SIZING_SIZING_25 = '200px'
export const BASE_SIZING_SIZING_32 = '256px'
export const BASE_SIZING_SCALE = '8px'
export const BASE_SIZING_WEB_BREAKPOINT_S = '375px'
export const BASE_SIZING_WEB_BREAKPOINT_M = '768px'
export const BASE_SIZING_WEB_BREAKPOINT_L = '1024px'
export const BASE_SIZING_WEB_BREAKPOINT_XL = '1200px'
export const BASE_SIZING_WEB_BREAKPOINT_XXL = '1400px'
export const CORE_OPACITY_OPCT_4 = '4%'
export const CORE_OPACITY_OPCT_7 = '7%'
export const CORE_OPACITY_OPCT_10 = '10%'
export const CORE_OPACITY_OPCT_20 = '20%'
export const CORE_OPACITY_OPCT_30 = '30%'
export const CORE_OPACITY_OPCT_40 = '40%'
export const CORE_OPACITY_OPCT_50 = '50%'
export const CORE_OPACITY_OPCT_60 = '60%'
export const CORE_OPACITY_OPCT_70 = '70%'
export const CORE_OPACITY_OPCT_0 = '0%'
export const CORE_COLOR_ZINC_50 = '#fafafa'
export const CORE_COLOR_ZINC_100 = '#f4f4f5'
export const CORE_COLOR_ZINC_200 = '#e4e4e7'
export const CORE_COLOR_ZINC_300 = '#d4d4d8'
export const CORE_COLOR_ZINC_400 = '#a1a1aa'
export const CORE_COLOR_ZINC_500 = '#71717a'
export const CORE_COLOR_ZINC_600 = '#52525b'
export const CORE_COLOR_ZINC_700 = '#3f3f46'
export const CORE_COLOR_ZINC_800 = '#27272a'
export const CORE_COLOR_ZINC_900 = '#18181b'
export const CORE_COLOR_ABSOLUT_BLACK = '#09090a'
export const CORE_COLOR_ABSOLUT_WHITE = '#ffffff'
export const CORE_COLOR_TEAL_50 = '#f0fdfa'
export const CORE_COLOR_TEAL_100 = '#ccfbf1'
export const CORE_COLOR_TEAL_200 = '#5eead4'
export const CORE_COLOR_TEAL_300 = '#5eead4'
export const CORE_COLOR_TEAL_400 = '#2dd4bf'
export const CORE_COLOR_TEAL_500 = '#14b8a6'
export const CORE_COLOR_TEAL_600 = '#0d9488'
export const CORE_COLOR_TEAL_700 = '#0f766e'
export const CORE_COLOR_TEAL_800 = '#115e59'
export const CORE_COLOR_TEAL_900 = '#134e4a'
export const CORE_COLOR_GREEN_50 = '#f0fdf4'
export const CORE_COLOR_GREEN_100 = '#dcfce7'
export const CORE_COLOR_GREEN_200 = '#bbf7d0'
export const CORE_COLOR_GREEN_300 = '#86efac'
export const CORE_COLOR_GREEN_400 = '#4ade80'
export const CORE_COLOR_GREEN_500 = '#22c55e'
export const CORE_COLOR_GREEN_600 = '#16a34a'
export const CORE_COLOR_GREEN_700 = '#15803d'
export const CORE_COLOR_GREEN_800 = '#166534'
export const CORE_COLOR_GREEN_900 = '#14532d'
export const CORE_COLOR_ORANGE_50 = '#fffbeb'
export const CORE_COLOR_ORANGE_100 = '#fef3c7'
export const CORE_COLOR_ORANGE_200 = '#fde68a'
export const CORE_COLOR_ORANGE_300 = '#fcd34d'
export const CORE_COLOR_ORANGE_400 = '#fbbf24'
export const CORE_COLOR_ORANGE_500 = '#f59e0b'
export const CORE_COLOR_ORANGE_600 = '#d97706'
export const CORE_COLOR_ORANGE_700 = '#b45309'
export const CORE_COLOR_ORANGE_800 = '#92400e'
export const CORE_COLOR_ORANGE_900 = '#78350f'
export const CORE_COLOR_RED_50 = '#fef2f2'
export const CORE_COLOR_RED_100 = '#fee2e2'
export const CORE_COLOR_RED_200 = '#fecaca'
export const CORE_COLOR_RED_300 = '#fca5a5'
export const CORE_COLOR_RED_400 = '#f87171'
export const CORE_COLOR_RED_500 = '#ef4444'
export const CORE_COLOR_RED_600 = '#dc2626'
export const CORE_COLOR_RED_700 = '#b91c1c'
export const CORE_COLOR_RED_800 = '#991b1b'
export const CORE_COLOR_RED_900 = '#7f1d1d'
export const CORE_COLOR_ADITIONAL_00 = '#98F4E2'
export const CORE_COLOR_ADITIONAL_01 = '#C4B5FD'
export const CORE_COLOR_ADITIONAL_02 = '#22D3EE'
export const CORE_COLOR_ADITIONAL_03 = '#FFFFFF'
export const CORE_COLOR_ADITIONAL_04 = '#A1A1AA'
export const CORE_COLOR_ADITIONAL_05 = '#6CB0FF'
export const CORE_LETTER_SPACING_NONE = 'normal'
export const CORE_LETTER_SPACING_S = '0.25px'
export const CORE_LETTER_SPACING_M = '0.5px'
export const CORE_LETTER_SPACING_L = '0.75px'
export const CORE_LETTER_SPACING_XL = '1px'
export const FONT_FAMILIES_INTER = 'Inter'
export const FONT_WEIGHTS_REGULAR = 400
export const FONT_WEIGHTS_MEDIUM = 500
export const FONT_WEIGHTS_SEMI_BOLD = 600
export const FONT_WEIGHTS_BOLD = 700
export const TEXT_CASE_NONE = 'none'
export const TEXT_DECORATION_NONE = 'none'
export const AUTO = 'auto'
export const XXS = '12px'
export const XS = '16px'
export const S = '20px'
export const M = '24px'
export const L = '28px'
export const XL = '32px'
export const XXL = '36px'
export const XXXL = '40px'
export const XXXXL = '44px'
export const XXXXXL = '56px'
export const FONT_SIZE_XXS = '10px'
export const FONT_SIZE_XS = '12px'
export const FONT_SIZE_S = '14px'
export const FONT_SIZE_M = '16px'
export const FONT_SIZE_L = '18px'
export const FONT_SIZE_XL = '20px'
export const FONT_SIZE_XXL = '24px'
export const FONT_SIZE_XXXL = '28px'
export const FONT_SIZE_XXXXL = '32px'
export const FONT_SIZE_XXXXXL = '36px'
export const FONT_SIZE_XXXXXXL = '48px'
