const styles = {
  image: {
    lineHeight: 0,
    overflow: 'hidden'
  },
  cover: {
    '& img': {
      objectFit: 'cover',
      overflow: 'hidden',
      width: '100%',
      height: '100%'
    }
  }
}

export default styles
