import { getBorderProp } from 'utils/theme.utils'
import * as TOKENS from 'constants/tokens'

const styles = {
  box: {
    p: 2,
    display: 'flex',
    borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
    border: getBorderProp(TOKENS.CARD_DEFAULT),
    backgroundColor: TOKENS.COMPONENT_COLOR_CARD_LEVEL1_CONTRASTED
  }
}

export default styles
