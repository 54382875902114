import React, { FC } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import FormHelperText from '@mui/material/FormHelperText'

interface FieldErrorMessageProps {
  errorMessage?: string
}

const FieldErrorMessage: FC<FieldErrorMessageProps> = ({ errorMessage }) => {
  const intl: IntlShape = useIntl()

  return (
    <FormHelperText>
      {errorMessage ? intl.formatMessage({ id: errorMessage, defaultMessage: errorMessage }) : null}
      &nbsp;
    </FormHelperText>
  )
}

export default FieldErrorMessage
