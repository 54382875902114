import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const WarningIcon20: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 20 20" fontSize="icon20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4999 3.00037L17.8333 12.6004C18.5083 13.817 18.5833 15.0004 18.0416 15.9254C17.4999 16.8504 16.4333 17.3587 15.0333 17.3587H4.3666C2.9666 17.3587 1.89993 16.8504 1.35827 15.9254C0.816599 15.0004 0.891599 13.8254 1.5666 12.6004L6.89993 3.00037C7.6166 1.7087 8.60827 1.00037 9.69993 1.00037C10.7916 1.00037 11.7833 1.7087 12.4999 3.00037ZM9.69992 11.6254C9.35825 11.6254 9.07492 11.342 9.07492 11.0004V6.83371C9.07492 6.49204 9.35825 6.20871 9.69992 6.20871C10.0416 6.20871 10.3249 6.49204 10.3249 6.83371V11.0004C10.3249 11.342 10.0416 11.6254 9.69992 11.6254ZM9.69991 14.3337C9.64991 14.3337 9.59158 14.3254 9.53324 14.317C9.48324 14.3087 9.43324 14.292 9.38324 14.267C9.33324 14.2504 9.28324 14.2254 9.23324 14.192L9.10824 14.092C8.95824 13.9337 8.86658 13.717 8.86658 13.5004C8.86658 13.2837 8.95824 13.067 9.10824 12.9087L9.23324 12.8087C9.28324 12.7754 9.33324 12.7504 9.38324 12.7337C9.43324 12.7087 9.48324 12.692 9.53324 12.6837C9.64158 12.6587 9.75824 12.6587 9.85824 12.6837C9.91658 12.692 9.96658 12.7087 10.0166 12.7337C10.0666 12.7504 10.1166 12.7754 10.1666 12.8087L10.2916 12.9087C10.4416 13.067 10.5332 13.2837 10.5332 13.5004C10.5332 13.717 10.4416 13.9337 10.2916 14.092L10.1666 14.192C10.1166 14.2254 10.0666 14.2504 10.0166 14.267C9.96658 14.292 9.91658 14.3087 9.85824 14.317C9.80824 14.3254 9.74991 14.3337 9.69991 14.3337Z"
        fill="#F59E0B"
      />
    </SvgIcon>
  )
}

export default WarningIcon20
