export enum UserActionType {
  FETCH_USER = 'FETCH_USER',
  SIGN_IN = 'SIGN_IN',
  SET_USER = 'SET_USER',
  RESET_USER = 'RESET_USER',
  CONTACT_US = 'CONTACT_US'
}

export interface UserState extends User {
  isAuthed: boolean
  isLoaded: boolean
  credentials: Credentials
}

export type FetchUser = () => void

export interface SignInFormValues {
  email: string
  password: string
}

export type SignIn = (values: SignInFormValues) => void

export interface UserPayload {
  approved: boolean
  banned: boolean
  id: number
  nickname: string
  token_type: string
  access_token: string
}

export interface CredentialPayload {
  id: number
  name: string
  type: CredentialType
  status: boolean
  username: string
  password: string
  client_id: string
  client_secret: string
  access_token: string
  created_at: Date
}

export enum CredentialType {
  HOSTING = 'hosting',
  MESSENGER = 'messenger',
  PARSER = 'parser',
  PARTNER = 'partner',
  PROVIDER = 'provider',
  TRACKER = 'tracker'
}

export type CredentialsPayload = {
  [key in CredentialType]?: CredentialPayload[]
}

export interface UserDataPayload {
  user: UserPayload
  credentials: CredentialsPayload
}

export interface SetUserAction {
  type: UserActionType.SET_USER
  payload: UserDataPayload
}

export type SetUser = (user: UserDataPayload) => SetUserAction

export enum CredentialKey {
  HOSTING = 'hosting',
  MESSENGERS = 'messengers',
  PARSERS = 'parsers',
  PARTNERS = 'partners',
  PROVIDERS = 'providers',
  TRACKERS = 'trackers'
}

export interface Credential {
  id: number
  name: string
  type: CredentialType
  status: boolean
  username: string
  password: string
  clientId: string
  clientSecret: string
  accessToken: string
  createdAt: Date
}

export type Credentials = {
  [key in CredentialKey]?: Credential[]
}

export interface User {
  id: number | null
  nickname: string
}

export interface ResetUserAction {
  type: UserActionType.RESET_USER
  payload: null
}

export type ResetUser = () => ResetUserAction

export interface ContactUsValues {
  email: string
  subject: string
  message: string
}

export type ContactUs = (values: ContactUsValues) => void
