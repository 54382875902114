import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const AddIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8 14C7.35817 14 7 13.6418 7 13.2V9H2.8C2.35817 9 2 8.64183 2 8.2V7.8C2 7.35817 2.35817 7 2.8 7H7V2.8C7 2.35817 7.35817 2 7.8 2H8.2C8.64183 2 9 2.35817 9 2.8V7H13.2C13.6418 7 14 7.35817 14 7.8V8.2C14 8.64183 13.6418 9 13.2 9H9V13.2C9 13.6418 8.64183 14 8.2 14H7.8Z"
        fill="currentcolor"
      />
    </SvgIcon>
  )
}

export default AddIcon16
