import { connect } from 'react-redux'
import { addShell, fetchShellsList } from 'modules/shells'
import AddShellForm from './AddShellForm'

const mapDispatchToProps = {
  addShell,
  fetchShellsList
}

export default connect(null, mapDispatchToProps)(AddShellForm)
