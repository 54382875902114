import React, { FC } from 'react'
import MuiBackdrop, { BackdropProps as MuiBackdropProps } from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const Backdrop: FC<MuiBackdropProps> = props => {
  return (
    <MuiBackdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} {...props}>
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  )
}

export default Backdrop
