import { RootState } from 'types/system.types'

export const getDomains = (state: RootState) => state.domains

export const getParsersInfo = (state: RootState) => getDomains(state).parsersInfo

export const getDomainsActiveList = (state: RootState) => getDomains(state).domainsActiveList
export const getDomainsActiveListPagination = (state: RootState) => getDomains(state).domainsActiveListPagination

export const getDomainsStorageList = (state: RootState) => getDomains(state).domainsStorageList
export const getDomainsStorageListPagination = (state: RootState) => getDomains(state).domainsStorageListPagination
