import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ArrowBackIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63628 11.2925C2.24575 11.683 2.24575 12.3162 2.63628 12.7067L8.29313 18.3636C8.68366 18.7541 9.31682 18.7541 9.70734 18.3636C10.0979 17.9731 10.0979 17.3399 9.70734 16.9494L5.7576 12.9996L20.0005 12.9996C20.5527 12.9996 21.0005 12.5519 21.0005 11.9996C21.0005 11.4473 20.5527 10.9996 20.0005 10.9996L5.7576 10.9996L9.70734 7.04988C10.0979 6.65936 10.0979 6.02619 9.70734 5.63567C9.31682 5.24514 8.68366 5.24514 8.29313 5.63567L2.63628 11.2925Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ArrowBackIcon24
