import { SeriesAreaOptions } from 'highcharts'
import camelCase from 'lodash/camelCase'
import {
  CommonStatisticsPayload,
  PartnersStatisticsList,
  StatisticsItemPayload,
  StatisticsListPayload
} from 'types/statistics.types'
import * as TOKENS from 'constants/tokens'

type NormalizeStatisticsItem = (item: StatisticsItemPayload) => SeriesAreaOptions

const SERIES_OPTIONS = {
  common: {
    zIndex: 1,
    lineColor: 'transparent',
    fillColor: TOKENS.COMPONENT_COLOR_CHART_03_01,
    marker: {
      fillColor: TOKENS.COMPONENT_COLOR_CHART_04
    },
    states: {
      hover: {
        halo: {
          attributes: {
            fill: TOKENS.COMPONENT_COLOR_CHART_04
          }
        }
      }
    }
  },
  adsterra: {
    zIndex: 5,
    lineColor: TOKENS.COMPONENT_COLOR_CHART_02,
    fillColor: TOKENS.COMPONENT_COLOR_CHART_02_01,
    marker: {
      fillColor: TOKENS.COMPONENT_COLOR_CHART_02
    },
    states: {
      hover: {
        halo: {
          attributes: {
            fill: TOKENS.COMPONENT_COLOR_CHART_02
          }
        }
      }
    }
  },
  lospollos: {
    zIndex: 3,
    lineColor: TOKENS.COMPONENT_COLOR_CHART_00,
    fillColor: TOKENS.COMPONENT_COLOR_CHART_00_01,
    marker: {
      fillColor: TOKENS.COMPONENT_COLOR_CHART_00
    },
    states: {
      hover: {
        halo: {
          attributes: {
            fill: TOKENS.COMPONENT_COLOR_CHART_00
          }
        }
      }
    }
  },
  adsterraCpa: {
    zIndex: 4,
    lineColor: TOKENS.COMPONENT_COLOR_CHART_05,
    fillColor: TOKENS.COMPONENT_COLOR_CHART_05_01,
    marker: {
      fillColor: TOKENS.COMPONENT_COLOR_CHART_05
    },
    states: {
      hover: {
        halo: {
          attributes: {
            fill: TOKENS.COMPONENT_COLOR_CHART_05
          }
        }
      }
    }
  },
  adsterraBots: {
    zIndex: 2,
    lineColor: TOKENS.COMPONENT_COLOR_CHART_01,
    fillColor: TOKENS.COMPONENT_COLOR_CHART_01_01,
    marker: {
      fillColor: TOKENS.COMPONENT_COLOR_CHART_01
    },
    states: {
      hover: {
        halo: {
          attributes: {
            fill: TOKENS.COMPONENT_COLOR_CHART_01
          }
        }
      }
    }
  }
}

export const normalizeStatisticsItem: NormalizeStatisticsItem = item => {
  const { title, statistics } = item

  return {
    name: title,
    data: Object.values(statistics),
    ...SERIES_OPTIONS[camelCase(title)]
  }
}

type NormalizePartnersStatisticsList = (
  common: CommonStatisticsPayload,
  partners: StatisticsListPayload
) => PartnersStatisticsList

export const normalizePartnersStatisticsList: NormalizePartnersStatisticsList = (common, partners) => {
  return [
    { name: 'common', data: Object.values(common.statistics), ...SERIES_OPTIONS.common },
    ...partners.map(normalizeStatisticsItem)
  ]
}
