import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getFarmsStatisticsList } from 'modules/statistics'
import FarmsStatistics from './FarmsStatistics'

const mapStateToProps = (state: RootState) => {
  return {
    farmsStatisticsList: getFarmsStatisticsList(state)
  }
}

export default connect(mapStateToProps)(FarmsStatistics)
