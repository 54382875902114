import React, { FC } from 'react'
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom'
import { useIntl, IntlShape } from 'react-intl'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import Drawer from '@mui/material/Drawer'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoTextEnIcon from 'components/shared/icons/LogoTextEnIcon'
import { PATH_LANDING } from 'constants/routes.constants'
import { nav } from 'configs/sideBar.config'
import styles from './SideBar.styles'

const SideBar: FC = () => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Drawer sx={styles.sideBar} variant="permanent" anchor="left">
      <Link sx={styles.logo} component={RouterLink} to={PATH_LANDING}>
        <LogoTextEnIcon sx={{ width: 115, height: 48 }} />
      </Link>
      <nav>
        {nav.map(({ title, icon, path, selectedPath }) => {
          return (
            <List key={path}>
              <ListItem>
                <ListItemButton selected={pathname.includes(selectedPath)} onClick={() => navigate(path)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={intl.formatMessage({ id: title })} />
                </ListItemButton>
              </ListItem>
            </List>
          )
        })}
      </nav>
    </Drawer>
  )
}

export default SideBar
