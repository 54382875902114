import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchKeysGroupsList, getSortedKeysGroupsList } from 'modules/keys'
import KeysGroupsTable from './KeysGroupsTable'

const mapStateToProps = (state: RootState) => {
  return {
    sortedKeysGroupsList: getSortedKeysGroupsList(state)
  }
}

const mapDispatchToProps = {
  fetchKeysGroupsList
}

export default connect(mapStateToProps, mapDispatchToProps)(KeysGroupsTable)
