import React, { FC } from 'react'
import { useIntl, IntlShape } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import AuthPageLayout from 'components/auth/AuthPageLayout'
import FieldTextControlled from 'components/shared/form/FieldTextControlled'
import Image from 'components/shared/Image'
import Logo from 'components/shared/images/webp/logo2.webp'
import { SignIn, SignInFormValues } from 'types/user.types'
import theme from 'configs/theme.config'
import schema from './SignInPage.schemas'
import styles from './SignInPage.styles'

interface SignInPageProps {
  signIn: SignIn
}

const SignInPage: FC<SignInPageProps> = ({ signIn }) => {
  const intl: IntlShape = useIntl()
  const isSMSize = useMediaQuery(theme.breakpoints.down('sm'))
  const isXSSize = useMediaQuery(theme.breakpoints.down('xs'))

  const signInFormValues: SignInFormValues = {
    email: '',
    password: ''
  }

  const { control, handleSubmit } = useForm({
    defaultValues: signInFormValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const handleSignIn = (values: SignInFormValues) => {
    try {
      signIn(values)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <AuthPageLayout>
      <Box sx={styles.layout}>
        <Box sx={styles.image}>
          <Image sx={styles.logo} width={isXSSize ? 160 : 222} src={Logo} alt="Shaman Solutions" />
          <Typography
            sx={styles.slogan}
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({
                id: isSMSize ? 'SIGN_IN_PAGE.TITLE.HEADER.NARROW' : 'SIGN_IN_PAGE.TITLE.HEADER'
              })
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <form onSubmit={handleSubmit(handleSignIn)}>
            <Typography sx={styles.title} variant="h2" component="h1" align="center">
              {intl.formatMessage({ id: 'SIGN_IN_PAGE.TITLE.LOGIN' })}
            </Typography>
            <FieldTextControlled sx={{ mb: 2 }} name="email" control={control} label="FORM.FIELD.TITLE.EMAIL" />
            <FieldTextControlled name="password" control={control} label="FORM.FIELD.TITLE.PASSWORD" type="password" />
            <Button sx={styles.submit} type="submit" variant="contained" size="large" fullWidth>
              {intl.formatMessage({ id: 'SIGN_IN_PAGE.BUTTON.LOGIN' })}
            </Button>
          </form>
        </Box>
      </Box>
    </AuthPageLayout>
  )
}

export default SignInPage
