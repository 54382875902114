const styles = {
  copy: {
    opacity: 0,
    transition: 'opacity 200ms ease-out',
    ml: 1
  },
  table: {
    '& tr': {
      '&:hover .copyBtn': {
        opacity: 1
      },
      '@media (hover: none)': {
        '& .copyBtn': {
          opacity: 1
        }
      }
    }
  }
}

export default styles
