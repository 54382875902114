import DomainsPage from 'components/domains/DomainsPage'
import SignInPage from 'components/auth/SignInPage'
import StatisticsPage from 'components/statistics/StatisticsPage'
import KeysPage from 'components/keys/KeysPage'
import ShellsPage from 'components/shells/ShellsPage'
import CampaignsPage from 'components/campaigns/CampaignsPage'
import LandingPage from 'components/LandingPage'
import {
  PATH_DOMAINS,
  PATH_STATISTICS,
  PATH_SIGN_IN,
  PATH_KEYS,
  PATH_CAMPAIGNS,
  PATH_SHELLS,
  PATH_LANDING,
  PATH_LANDING_EN
} from 'constants/routes.constants'

export const routes = [
  {
    path: PATH_LANDING,
    Component: LandingPage,
    type: 'static'
  },
  {
    path: PATH_LANDING_EN,
    Component: LandingPage,
    type: 'static'
  },
  {
    path: PATH_SIGN_IN,
    Component: SignInPage,
    type: 'public'
  },
  {
    path: PATH_STATISTICS,
    Component: StatisticsPage,
    type: 'private'
  },
  {
    path: PATH_KEYS,
    Component: KeysPage,
    type: 'private'
  },
  {
    path: `${PATH_DOMAINS}/*`,
    Component: DomainsPage,
    type: 'private'
  },
  {
    path: PATH_SHELLS,
    Component: ShellsPage,
    type: 'private'
  },
  {
    path: PATH_CAMPAIGNS,
    Component: CampaignsPage,
    type: 'private'
  }
]
