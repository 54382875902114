import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ManuallyIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0227 1.08333C11.7673 1.02873 11.5024 1 11.2307 1C9.14904 1 7.46149 2.68754 7.46149 4.76923C7.46149 4.99604 7.48152 5.21818 7.51992 5.43396L4.41049 8.54337C4.35101 8.54011 4.29109 8.53845 4.23079 8.53845C2.44647 8.53845 1 9.98492 1 11.7692C1 13.5535 2.44647 15 4.23078 15C6.0151 15 7.46157 13.5535 7.46157 11.7692C7.46157 11.7088 7.45991 11.6488 7.45664 11.5892L10.5659 8.48001C10.7817 8.51842 11.0039 8.53846 11.2307 8.53846C13.3124 8.53846 15 6.85092 15 4.76923C15 4.49769 14.9713 4.23286 14.9167 3.97761L12.8101 6.08416L10.4569 5.54321L9.91594 3.19L12.0227 1.08333ZM4.23091 9.61534L6.0962 10.6923V12.8461L4.23091 13.923L2.36561 12.8461L2.36561 10.6923L4.23091 9.61534Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ManuallyIcon16
