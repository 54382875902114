import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchDomainsActiveList, getDomainsActiveList, getDomainsActiveListPagination } from 'modules/domains'
import DomainsActiveTable from './DomainsActiveTable'

const mapStateToProps = (state: RootState) => {
  return {
    domainsActiveList: getDomainsActiveList(state),
    domainsActiveListPagination: getDomainsActiveListPagination(state)
  }
}

const mapDispatchToProps = {
  fetchDomainsActiveList
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsActiveTable)
