import React, { FC } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import UserIcon24 from 'components/shared/icons/24x24/UserIcon24'
import { Dispatch } from 'types/system.types'
import styles from './UserName.styles'

interface PageLayoutProps extends BoxProps, Dispatch {
  nickname: string
}

const UserName: FC<PageLayoutProps> = ({ nickname, sx, dispatch, ...props }) => (
  <Box sx={{ ...styles.userMenu, ...sx }} {...props}>
    <Box sx={styles.avatar}>
      <UserIcon24 />
    </Box>
    <Typography variant="SMedium">{nickname}</Typography>
  </Box>
)

export default UserName
