import React, { FC, useState, useEffect } from 'react'
import { useIntl, IntlShape } from 'react-intl'
import { withSnackbar, WithSnackbarProps, SnackbarKey } from 'notistack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from 'components/shared/Alert'
import { NotificationsList, RemoveNotificationsItem } from 'types/notifications.types'

interface NotifierProps extends WithSnackbarProps {
  notificationsList: NotificationsList
  removeNotificationsItem: RemoveNotificationsItem
}

const Notifier: FC<NotifierProps> = ({
  notificationsList,
  removeNotificationsItem,
  enqueueSnackbar,
  closeSnackbar
}) => {
  const intl: IntlShape = useIntl()

  const [displayed, setDisplayed] = useState<SnackbarKey[]>([])

  const storeDisplayed = (id: SnackbarKey) => {
    setDisplayed([...displayed, id])
  }

  const removeDisplayed = (id: SnackbarKey) => {
    setDisplayed(displayed.filter(key => id !== key))
  }

  useEffect(() => {
    notificationsList.forEach(
      ({
        key: notificationKey,
        title = '',
        info,
        isAction,
        isClose = true,
        severity,
        options = {},
        dismissed = false
      }) => {
        if (dismissed) {
          closeSnackbar(notificationKey)
          return
        }

        if (displayed.includes(Number(notificationKey))) {
          return
        }

        enqueueSnackbar('', {
          key: notificationKey,
          autoHideDuration: isAction ? null : 5000,
          anchorOrigin: options.anchorOrigin || {
            vertical: 'top',
            horizontal: 'right'
          },
          content: options.content || (
            <Alert
              {...(isClose ? { onClose: () => closeSnackbar(notificationKey) } : {})}
              elevation={6}
              severity={severity}
            >
              <AlertTitle
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: title, defaultMessage: title })
                }}
              />
              {Array.isArray(info) ? (
                info.map(({ text, value }) => (
                  <Box key={`${text}-${value}`} my={1} display="flex" justifyContent="space-between">
                    <Box component="span">{intl.formatMessage({ id: text })}</Box>
                    <Box component="span">{value}</Box>
                  </Box>
                ))
              ) : info ? (
                <Box>{info}</Box>
              ) : null}
              {isAction && (
                <Button
                  sx={{ mt: 1, mb: 0.5 }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => closeSnackbar(notificationKey)}
                >
                  {intl.formatMessage({ id: 'NOTIFIER.BUTTON.ACTION.OK' })}
                </Button>
              )}
            </Alert>
          ),
          ...options,
          onClose: (event, reason, key) => {
            options.onClose?.(event, reason, key)
          },
          onExited: (event, key) => {
            removeNotificationsItem(key)
            removeDisplayed(key)
          }
        })
        storeDisplayed(Number(notificationKey))
      }
    )
  }, [notificationsList])

  return null
}

export default withSnackbar(Notifier)
