import React, { FC, SyntheticEvent } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useController, UseFormSetError } from 'react-hook-form'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import FieldText from 'components/shared/form/FieldText'
import UploadIcon16 from 'components/shared/icons/16x16/UploadIcon16'
import { Controller, AfterFieldChange } from 'types/form.types'
import styles from './FieldFile.styles'

interface FieldFileProps extends Controller {
  label: string
  afterFieldChange?: AfterFieldChange
  setError: UseFormSetError<any>
}

const FieldFile: FC<FieldFileProps & BoxProps> = ({ control, name, label, afterFieldChange, setError, ...props }) => {
  const intl: IntlShape = useIntl()
  const isWidth400 = useMediaQuery('(max-width:400px)')
  const isWidth300 = useMediaQuery('(max-width:300px)')

  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, control })

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement
    const file = (target.files as FileList)?.[0]

    if (file && file?.type !== 'text/plain') {
      setError(name, { type: 'custom', message: 'FORM.FIELD.VALIDATION.ERROR.FILE_TYPE' })
      return
    }

    onChange(file)

    afterFieldChange?.()
  }

  return (
    <Box position="relative" {...props}>
      <FieldText
        sx={styles.file}
        type="file"
        inputProps={{ accept: 'text/plain', hidden: true }}
        onChange={handleChange}
      />
      {isWidth300 ? null : (
        <Box sx={styles.button}>
          {isWidth400 ? (
            <IconButton size="small" color="outlinedSecondary">
              <UploadIcon16 />
            </IconButton>
          ) : (
            <Button size="small" variant="outlined" color="secondary" startIcon={<UploadIcon16 />}>
              {intl.formatMessage({ id: 'FORM.FIELD.BUTTON.DOWNLOAD' })}
            </Button>
          )}
        </Box>
      )}
      <FieldText value={value?.name || ''} label={label} errorMessage={error?.message} />
    </Box>
  )
}

export default FieldFile
