export const BODY_00 = '#fff'
export const BODY_10 = '#17181C'
export const BODY_20 = '#2E3038'
export const BODY_25 = 'rgba(46, 48, 56, 0.04)'
export const BODY_45 = '#9DA1AF'
export const BODY_50 = '#B6BAC4'
export const BODY_60 = '#DDDEE3'
export const BODY_70 = '#F1F1F3'
export const BODY_80 = '#F7F7F7'
export const BODY_90 = '#FBFBFC'
export const NAV_10 = '#3870FE'
export const NAV_20 = 'rgba(56, 112, 254, 0.1)'
export const SUCCESS_20 = '#20c997'
export const SUCCESS_30 = '#63e6be'
export const SUCCESS_35 = 'rgba(121, 231, 152, 0.2)'
export const DANGER_10 = '#FF5E48'
export const WARNING_10 = '#EAAB4D'
export const WARNING_20 = 'rgba(255, 183, 43, 0.2)'
