import { connect } from 'react-redux'
import { getNotificationsList, removeNotificationsItem } from 'modules/notifications'
import { RootState } from 'types/system.types'
import Notifier from './Notifier'

const mapStateToProps = (state: RootState) => {
  return {
    notificationsList: getNotificationsList(state)
  }
}

const mapDispatchToProps = {
  removeNotificationsItem
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifier)
