import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getPartnerStatisticsList } from 'modules/statistics'
import PartnersStatisticsChart from './PartnersStatisticsChart'

const mapStateToProps = (state: RootState) => {
  return {
    partnersStatisticsList: getPartnerStatisticsList(state)
  }
}

export default connect(mapStateToProps)(PartnersStatisticsChart)
