import React, { FC, useState, useEffect, MouseEvent } from 'react'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import Table from 'components/shared/Table'
import Dialog from 'components/shared/Dialog'
import Checkbox from 'components/shared/form/Checkbox'
import CopyButton from 'components/shared/CopyButton'
import AddIcon24 from 'components/shared/icons/24x24/AddIcon24'
import CopyIcon16 from 'components/shared/icons/16x16/CopyIcon16'
import DeleteIcon16 from 'components/shared/icons/16x16/DeleteIcon16'
import { getFormattedDate, filterSelectedTableIds } from 'utils/shared.utils'
import { DOMAINS_STORAGE_TABLE_HEAD } from 'constants/domains.constants'
import { DEFAULT_PAGINATION_PER_PAGE } from 'constants/shared.contants'
import { PATH_DOMAINS_STORAGE } from 'constants/routes.constants'
import {
  FetchDomainsStorageList,
  DomainsStorageList,
  DomainsStorageTableHeadIds,
  DomainsStorageHash,
  DeleteDomains
} from 'types/domains.types'
import { Pagination, SortMethod } from 'types/shared.types'
import styles from './DomainsStorageTable.styles'

interface DomainsStorageTableParams {
  domains: number[]
  setDomains: (domains: number[]) => void
  domainsStorageList: DomainsStorageList
  domainsStorageListPagination: Pagination
  fetchDomainsStorageList: FetchDomainsStorageList
  deleteDomains: DeleteDomains
}

const DomainsStorageTable: FC<DomainsStorageTableParams> = ({
  domains,
  setDomains,
  domainsStorageList,
  domainsStorageListPagination,
  fetchDomainsStorageList,
  deleteDomains
}) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as DomainsStorageTableHeadIds) || DomainsStorageTableHeadIds.CREATED_AT
  const page = 1
  const perPage = Number(searchParams.get('perPage')) || DEFAULT_PAGINATION_PER_PAGE

  const [isAllSelected, setIsAllSelected] = useState(false)
  const [deletedId, setDeletedId] = useState<null | number>(null)
  const [storedDomains, setStoredDomains] = useState<Record<string, Date>>(
    JSON.parse(localStorage.getItem('domains') || '{}')
  )

  useEffect(() => {
    // Remove stored domains older than 3 days
    const filteredStoredDomains = Object.entries(storedDomains).filter(([key, value]) => {
      return new Date(value) > new Date(Date.now() - 1000 * 60 * 60 * 24 * 3)
    })

    updateStoredDomains(Object.fromEntries(filteredStoredDomains))
  }, [])

  const isSelected = (id: number) => domains.indexOf(id) !== -1

  useEffect(() => {
    setIsAllSelected(domains.length === domainsStorageList.length)
  }, [domains, domainsStorageList])

  const handleDomainSelection = (event: MouseEvent<unknown>, id: number) => {
    setDomains(filterSelectedTableIds(id, domains))
  }

  const handleAllDomainsSelection = () => {
    setDomains(isAllSelected ? [] : domainsStorageList.map(row => row.id))
  }

  const closeRowDialog = () => setDeletedId(null)

  const handleDeleteDomain = async (id: number) => {
    try {
      await deleteDomains([id])

      handleFetchDomainsStorageList()
    } catch (error) {
      console.log(error)
    }

    closeRowDialog()
  }

  const handleFetchDomainsStorageList = () => {
    fetchDomainsStorageList(sortMethod, sortBy, page, perPage)

    navigate({
      pathname: PATH_DOMAINS_STORAGE,
      search: createSearchParams({ sortMethod, sortBy, page: String(page), perPage: String(perPage) }).toString()
    })
  }

  const onCopy = (domain: string) => {
    updateStoredDomains({ ...storedDomains, [domain]: new Date() })
  }

  const updateStoredDomains = (newDomains: Record<string, Date>) => {
    setStoredDomains(newDomains)
    localStorage.setItem('domains', JSON.stringify(newDomains))
  }

  return (
    <>
      <Table
        sx={styles.table}
        sortByDefault={DomainsStorageTableHeadIds.CREATED_AT}
        tableHead={DOMAINS_STORAGE_TABLE_HEAD}
        fetchTableData={fetchDomainsStorageList}
        isTableData={domainsStorageList.length > 0}
        isAllChecked={isAllSelected}
        handleAllChecked={handleAllDomainsSelection}
        emptyTitle="DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.TEXT.EMPTY"
        emptyContent={
          <>
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                navigate({
                  pathname: PATH_DOMAINS_STORAGE,
                  search: searchParams.toString(),
                  hash: DomainsStorageHash.VALIDATE
                })
              }
              startIcon={<AddIcon24 />}
            >
              {intl.formatMessage({ id: 'DOMAINS_STORAGE.BUTTON.ADD_DOMAINS' })}
            </Button>
          </>
        }
        pagination={domainsStorageListPagination}
        onTableChange={() => setDomains([])}
      >
        {domainsStorageList.map(row => {
          const isItemSelected = isSelected(row.id)

          return (
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.id}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox" onClick={event => handleDomainSelection(event, row.id)}>
                <Checkbox color="primary" checked={isItemSelected} />
              </TableCell>
              <TableCell>{getFormattedDate(row.createdAt, intl, true)} </TableCell>
              <TableCell>
                <Box display="flex" alignItems="center">
                  <Typography
                    ml={1}
                    noWrap
                    variant="XSRegular"
                    color={Object.keys(storedDomains).includes(row.domain) ? 'textSecondary' : 'textPrimary'}
                  >
                    {row.domain}
                  </Typography>
                  <CopyButton value={row.domain} handleOnCopy={() => onCopy(row.domain)}>
                    <IconButton className="copyBtn" sx={styles.copy} size="small" color="outlinedSecondary">
                      <CopyIcon16 />
                    </IconButton>
                  </CopyButton>
                </Box>
              </TableCell>
              <TableCell>{row.data}</TableCell>
              <TableCell sx={styles.actions}>
                <IconButton size="small" color="outlinedSecondary" onClick={() => setDeletedId(row.id)}>
                  <DeleteIcon16 />
                </IconButton>

                <Dialog
                  open={deletedId === row.id}
                  onClose={closeRowDialog}
                  dialogTitle={intl.formatMessage({
                    id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TITLE.DELETE_DOMAIN'
                  })}
                  dialogActions={
                    <>
                      <Button onClick={closeRowDialog} variant="outlined" color="secondary" size="large">
                        {intl.formatMessage({ id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.BUTTON.CANCEL' })}
                      </Button>
                      <Button onClick={() => handleDeleteDomain(row.id)} variant="contained" color="error" size="large">
                        {intl.formatMessage({ id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.BUTTON.DELETE' })}
                      </Button>
                    </>
                  }
                >
                  <Typography variant="LRegular" align="center">
                    {intl.formatMessage(
                      { id: 'DOMAINS_STORAGE.DOMAINS_STORAGE_TABLE.DIALOG.TEXT.DELETE_DOMAIN' },
                      { domain: row.domain }
                    )}
                  </Typography>
                </Dialog>
              </TableCell>
            </TableRow>
          )
        })}
      </Table>
    </>
  )
}

export default DomainsStorageTable
