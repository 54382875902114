import { createTheme } from '@mui/material/styles'
import { getBorderProp } from 'utils/theme.utils'
import * as PALETTE from 'constants/palette.constants'
import * as TOKENS from 'constants/tokens'

let theme = createTheme({
  breakpoints: {
    values: {
      zero: 0,
      xs: 480,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  spacing: parseFloat(TOKENS.BASE_SPACING_SCALE)
})

theme = createTheme(theme as any, {
  palette: {
    background: {
      default: TOKENS.CORE_COLOR_ZINC_900
    },
    text: {
      primary: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
      secondary: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY
    },
    common: {
      white: PALETTE.BODY_00,
      black: PALETTE.BODY_10
    },
    primary: {
      light: TOKENS.CORE_COLOR_TEAL_400,
      main: TOKENS.CORE_COLOR_TEAL_500,
      dark: TOKENS.CORE_COLOR_TEAL_600
    },
    success: {
      light: PALETTE.SUCCESS_35,
      main: PALETTE.SUCCESS_30,
      dark: PALETTE.SUCCESS_20
    },
    error: {
      main: PALETTE.DANGER_10
    },
    warning: {
      light: PALETTE.WARNING_20,
      main: PALETTE.WARNING_10
    },
    borders: {
      main: PALETTE.BODY_60
    },
    gray: {
      light: PALETTE.BODY_90,
      main: PALETTE.BODY_80,
      dark: PALETTE.BODY_50
    }
  },
  typography: {
    h1: {
      fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H1_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H1_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H1_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H1_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H1_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H1_TEXT_DECORATION,

      [theme.breakpoints.down('sm')]: {
        fontFamily: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H1_FONT_FAMILY,
        fontWeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H1_FONT_WEIGHT,
        fontSize: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H1_FONT_SIZE,
        lineHeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H1_LINE_HEIGHT,
        letterSpacing: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H1_LETTER_SPACING,
        textDecoration: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H1_TEXT_DECORATION
      }
    },
    h2: {
      fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H2_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H2_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H2_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H2_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H2_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H2_TEXT_DECORATION,

      [theme.breakpoints.down('sm')]: {
        fontFamily: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H2_FONT_FAMILY,
        fontWeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H2_FONT_WEIGHT,
        fontSize: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H2_FONT_SIZE,
        lineHeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H2_LINE_HEIGHT,
        letterSpacing: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H2_LETTER_SPACING,
        textDecoration: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H2_TEXT_DECORATION
      }
    },
    h3: {
      fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_TEXT_DECORATION,

      [theme.breakpoints.down('sm')]: {
        fontFamily: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H3_FONT_FAMILY,
        fontWeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H3_FONT_WEIGHT,
        fontSize: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H3_FONT_SIZE,
        lineHeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H3_LINE_HEIGHT,
        letterSpacing: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H3_LETTER_SPACING,
        textDecoration: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H3_TEXT_DECORATION
      }
    },
    h5: {
      fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_TEXT_DECORATION,

      [theme.breakpoints.down('sm')]: {
        fontFamily: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H5_FONT_FAMILY,
        fontWeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H5_FONT_WEIGHT,
        fontSize: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H5_FONT_SIZE,
        lineHeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H5_LINE_HEIGHT,
        letterSpacing: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H5_LETTER_SPACING,
        textDecoration: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H5_TEXT_DECORATION
      }
    },
    h6: {
      fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H6_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H6_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H6_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H6_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H6_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H6_TEXT_DECORATION,

      [theme.breakpoints.down('sm')]: {
        fontFamily: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H6_FONT_FAMILY,
        fontWeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H6_FONT_WEIGHT,
        fontSize: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H6_FONT_SIZE,
        lineHeight: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H6_LINE_HEIGHT,
        letterSpacing: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H6_LETTER_SPACING,
        textDecoration: TOKENS.TYPOGRAPHY_HEADING_MOBILE_H6_TEXT_DECORATION
      }
    },
    LSemiBold: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_L_SEMI_BOLD_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_L_SEMI_BOLD_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_L_SEMI_BOLD_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_L_SEMI_BOLD_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_L_SEMI_BOLD_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_L_SEMI_BOLD_TEXT_DECORATION
    },
    LMedium: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_L_MEDIUM_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_L_MEDIUM_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_L_MEDIUM_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_L_MEDIUM_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_L_MEDIUM_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_L_MEDIUM_TEXT_DECORATION
    },
    LRegular: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_L_REGULAR_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_L_REGULAR_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_L_REGULAR_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_L_REGULAR_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_L_REGULAR_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_L_REGULAR_TEXT_DECORATION
    },
    MSemiBold: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_TEXT_DECORATION
    },
    MMedium: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_TEXT_DECORATION
    },
    MRegular: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_TEXT_DECORATION
    },
    SSemiBold: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_TEXT_DECORATION
    },
    SMedium: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_TEXT_DECORATION
    },
    SRegular: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION
    },
    XSSemiBold: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_TEXT_DECORATION
    },
    XSMedium: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_TEXT_DECORATION
    },
    XSRegular: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION
    },
    XXSSemiBold: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_SEMI_BOLD_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_SEMI_BOLD_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_SEMI_BOLD_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_SEMI_BOLD_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_SEMI_BOLD_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_SEMI_BOLD_TEXT_DECORATION
    },
    XXSMedium: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_MEDIUM_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_MEDIUM_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_MEDIUM_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_MEDIUM_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_MEDIUM_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_MEDIUM_TEXT_DECORATION
    },
    XXSRegular: {
      fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_FAMILY,
      fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_WEIGHT,
      fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE,
      lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LINE_HEIGHT,
      letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LETTER_SPACING,
      textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_DECORATION
    }
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            border: 'none',
            background: TOKENS.COMPONENT_COLOR_CARD_SIDEBAR
          },

          '& .MuiListItemButton-root': {
            height: TOKENS.BASE_SIZING_SIZING_6,
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 2),
            borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
            marginBottom: theme.spacing(1),
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,

            '&.Mui-selected': {
              background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER,
              color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,

              '&:hover': {
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER
              }
            }
          },

          '& .MuiListItemIcon-root': {
            marginRight: theme.spacing(1.5)
          },

          '& .MuiListItemText-root': {
            margin: 0
          },

          '& .MuiListItemText-primary': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,
            fontFamily: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_FAMILY,
            fontSize: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_SIZE,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_FONT_WEIGHT,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_S_SEMI_BOLD_TEXT_DECORATION
          },

          '& .Mui-selected .MuiListItemText-primary': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY
          }
        }
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: TOKENS.BASE_SIZING_SIZING_10,
          background: TOKENS.COMPONENT_COLOR_CARD_SIDEBAR,
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,
          maxWidth: 640,
          flex: 1
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          maxWidth: 'none',
          minWidth: 'auto',
          padding: theme.spacing(0, 0.5),
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,
          borderBottom: 'solid 4px transparent',

          '&.Mui-selected': {
            borderColor: TOKENS.CORE_COLOR_TEAL_500,
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY
          }
        },
        label: {
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_TEXT_DECORATION,
          marginTop: theme.spacing(1.5),

          '&.Mui-selected': {
            fontSize: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_SIZE
          },

          '@media screen and (max-width: 267px)': {
            display: 'none'
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          LSemiBold: 'p',
          LMedium: 'p',
          LRegular: 'p',
          MSemiBold: 'p',
          MMedium: 'p',
          MRegular: 'p',
          SSemiBold: 'p',
          SMedium: 'p',
          SRegular: 'p',
          XSSemiBold: 'p',
          XSMedium: 'p',
          XSRegular: 'p',
          XXSSemiBold: 'p',
          XXSMedium: 'p',
          XXSRegular: 'p'
        }
      },
      styleOverrides: {
        root: {
          '& a': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
          textDecorationColor: 'inherit'
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeIcon12: {
          fontSize: TOKENS.BASE_SIZING_SIZING_1_5
        },
        fontSizeIcon16: {
          fontSize: TOKENS.BASE_SIZING_SIZING_2
        },
        fontSizeIcon20: {
          fontSize: TOKENS.BASE_SIZING_SIZING_2_5
        },
        fontSizeIcon24: {
          fontSize: TOKENS.BASE_SIZING_SIZING_3
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          textTransform: 'none',
          whiteSpace: 'nowrap',
          boxShadow: 'none',
          color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,

          '&:hover': {
            boxShadow: 'none'
          },

          '&:active': {
            boxShadow: 'none'
          },

          '&.Mui-disabled': {
            opacity: TOKENS.CORE_OPACITY_OPCT_40
          }
        },
        startIcon: {
          marginLeft: 0
        },
        sizeSmall: {
          borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
          height: TOKENS.BASE_SIZING_SIZING_4,
          padding: theme.spacing(0, 1.5),
          fontFamily: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_WEIGHT,
          lineHeight: TOKENS.TYPOGRAPHY_BUTTON_S_LINE_HEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_SIZE,
          letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_S_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BUTTON_S_TEXT_DECORATION
        },
        iconSizeSmall: {
          '&.MuiButton-startIcon': {
            marginRight: theme.spacing(1)
          },

          '&.MuiButton-endIcon': {
            marginLeft: theme.spacing(1)
          },

          '& > *:nth-of-type(1)': {
            fontSize: TOKENS.BASE_SIZING_SIZING_2
          }
        },
        sizeMedium: {
          height: TOKENS.BASE_SIZING_SIZING_5,
          padding: theme.spacing(0, 3),
          fontFamily: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_WEIGHT,
          lineHeight: TOKENS.TYPOGRAPHY_BUTTON_M_LINE_HEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_SIZE,
          letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_M_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BUTTON_M_TEXT_DECORATION
        },
        iconSizeMedium: {
          '&.MuiButton-startIcon': {
            marginRight: theme.spacing(1.5)
          },

          '&.MuiButton-endIcon': {
            marginLeft: theme.spacing(1.5)
          },

          '& > *:nth-of-type(1)': {
            fontSize: TOKENS.BASE_SIZING_SIZING_2_5
          }
        },
        sizeLarge: {
          height: TOKENS.BASE_SIZING_SIZING_6,
          padding: theme.spacing(0, 3),
          fontFamily: TOKENS.TYPOGRAPHY_BUTTON_L_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BUTTON_L_FONT_WEIGHT,
          lineHeight: TOKENS.TYPOGRAPHY_BUTTON_L_LINE_HEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BUTTON_L_FONT_SIZE,
          letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_L_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BUTTON_L_TEXT_DECORATION
        },
        iconSizeLarge: {
          '&.MuiButton-startIcon': {
            marginRight: theme.spacing(1.5)
          },

          '&.MuiButton-endIcon': {
            marginLeft: theme.spacing(1.5)
          },

          '& > *:nth-of-type(1)': {
            fontSize: TOKENS.BASE_SIZING_SIZING_3
          }
        },
        containedPrimary: {
          background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_DEFAULT,

          '&:hover': {
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_HOVER
          },

          '&:focus': {
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_FOCUSED
          },

          '&.Mui-disabled': {
            color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_DEFAULT
          }
        },
        containedSecondary: {
          color: TOKENS.CORE_COLOR_ZINC_900,
          background: TOKENS.CORE_COLOR_ABSOLUT_WHITE,

          '&:hover': {
            background: TOKENS.CORE_COLOR_ABSOLUT_WHITE
          },

          '&:focus': {
            background: TOKENS.CORE_COLOR_ABSOLUT_WHITE
          },

          '&.Mui-disabled': {
            color: TOKENS.CORE_COLOR_ZINC_900,
            background: TOKENS.CORE_COLOR_ABSOLUT_WHITE
          }
        },
        outlinedSecondary: {
          border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT),

          '&:hover': {
            border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER),
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER
          },

          '&:focus': {
            border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER),
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
          },

          '&.Mui-disabled': {
            color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
            border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT)
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT),
          borderColor: 'transparent',

          '&.Mui-disabled': {
            opacity: TOKENS.CORE_OPACITY_OPCT_40
          }
        },
        sizeSmall: {
          height: TOKENS.BASE_SIZING_SIZING_4,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
          padding: theme.spacing(0, 0.875),

          '& .MuiSvgIcon-root': {
            fontSize: TOKENS.BASE_SIZING_SIZING_2
          }
        },
        sizeMedium: {
          height: TOKENS.BASE_SIZING_SIZING_5,
          padding: theme.spacing(0, 1.125),

          '& .MuiSvgIcon-root': {
            fontSize: TOKENS.BASE_SIZING_SIZING_2_5
          }
        },
        sizeLarge: {
          height: TOKENS.BASE_SIZING_SIZING_6,
          padding: theme.spacing(0, 1.375),

          '& .MuiSvgIcon-root': {
            fontSize: TOKENS.BASE_SIZING_SIZING_3
          }
        },
        colorPrimary: {
          background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_DEFAULT,

          '&:hover': {
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_HOVER
          },

          '&:focus': {
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_FOCUSED
          },

          '&.Mui-disabled': {
            color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_DEFAULT
          }
        },
        colorOutlinedSecondary: {
          border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT),

          '&:hover': {
            border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER),
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER
          },

          '&:focus': {
            border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER),
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
          },

          '&.Mui-disabled': {
            color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
            border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT)
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0px 2px',
          backgroundColor: TOKENS.COMPONENT_COLOR_CARD_LEVEL1_CONTRASTED,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          border: getBorderProp(TOKENS.CARD_DEFAULT),
          padding: theme.spacing(1.75, 0)
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: TOKENS.COMPONENT_COLOR_TABLE_BG_HOVER
          },

          '&.MuiTableRow-hover:hover': {
            backgroundColor: TOKENS.COMPONENT_COLOR_TABLE_BG_HOVER
          },

          '&.Mui-selected': {
            backgroundColor: TOKENS.COMPONENT_COLOR_TABLE_BG_SELECTED,

            '&:hover': {
              backgroundColor: TOKENS.COMPONENT_COLOR_TABLE_BG_SELECTED
            }
          }
        },
        head: {
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          background: 'transparent',
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION,
          height: TOKENS.BASE_SIZING_SIZING_5,
          padding: theme.spacing(0, 2)
        },
        head: {
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_SEMI_BOLD_TEXT_DECORATION,
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,
          padding: theme.spacing(0, 2)
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,

          '&:hover': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY,

            '& .MuiTableSortLabel-icon': {
              opacity: 1
            }
          },

          '&.Mui-active': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY
          }
        },
        icon: {
          opacity: 1,
          fontSize: TOKENS.BASE_SIZING_SIZING_2,
          color: TOKENS.COMPONENT_COLOR_ICON_SECONDARY
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          '&.table-pagination ': {
            '& .MuiTablePagination-toolbar': {
              padding: 0,
              minHeight: 'auto'
            },

            '& .MuiInputBase-root': {
              margin: 0,
              marginRight: theme.spacing(0.5),
              background: 'transparent',
              borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
              height: TOKENS.BASE_SIZING_SIZING_4,
              padding: theme.spacing(0, 1.5),

              '& .MuiOutlinedInput-input': {
                fontFamily: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_FAMILY,
                fontWeight: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_WEIGHT,
                lineHeight: TOKENS.TYPOGRAPHY_BUTTON_S_LINE_HEIGHT,
                fontSize: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_SIZE,
                letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_S_LETTER_SPACING,
                textDecoration: TOKENS.TYPOGRAPHY_BUTTON_S_TEXT_DECORATION,
                color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
                padding: 0
              },

              '&:hover': {
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER,

                '& .MuiOutlinedInput-notchedOutline': {
                  border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER)
                }
              },

              '&:focus': {
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED,

                '& .MuiOutlinedInput-notchedOutline': {
                  border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER)
                }
              },

              '& .MuiOutlinedInput-notchedOutline': {
                border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT)
              }
            }
          }
        },
        selectIcon: {
          display: 'none'
        },
        displayedRows: {
          margin: 0,
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_WEIGHT,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_LINE_HEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_SIZE,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_TEXT_DECORATION
        },
        spacer: {
          display: 'none'
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: 552,
          padding: theme.spacing(10),
          background: TOKENS.COMPONENT_COLOR_CARD_POPUP,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_L,
          border: getBorderProp(TOKENS.CARD_DEFAULT),

          [theme.breakpoints.down('sm')]: {
            width: 446,
            padding: theme.spacing(8, 4, 6)
          },

          [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(5, 0),
            maxWidth: 'calc(100% - 32px)'
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          marginBottom: theme.spacing(8),
          textAlign: 'center',
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
          fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H3_TEXT_DECORATION,

          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6),
            fontFamily: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_HEADING_DESKTOP_H5_TEXT_DECORATION
          }
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
          overflow: 'visible'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 0,
          marginTop: theme.spacing(6),

          [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap'
          },

          '& .MuiButton-root': {
            flex: 1,

            [theme.breakpoints.down('xs')]: {
              flex: 'none',
              width: '100%'
            },

            '&:last-child': {
              marginLeft: theme.spacing(2),

              [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginTop: theme.spacing(2)
              }
            }
          }
        }
      }
    },
    MuiOutlinedInput: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            minHeight: TOKENS.BASE_SIZING_SIZING_6,

            '& input': {
              fontFamily: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_FAMILY,
              fontWeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_WEIGHT,
              fontSize: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_SIZE,
              lineHeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LINE_HEIGHT,
              letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LETTER_SPACING,
              textDecoration: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_TEXT_DECORATION
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          minHeight: TOKENS.BASE_SIZING_SIZING_5,
          background: TOKENS.COMPONENT_COLOR_INPUT_BG_DEFAULT,

          '&.MuiInputBase-multiline': {
            padding: 0
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: getBorderProp(TOKENS.INPUT_HOVER)
          },

          '&.Mui-error': {
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_ERROR,

            '& .MuiOutlinedInput-notchedOutline': {
              border: getBorderProp(TOKENS.INPUT_DEFAULT_ERROR)
            }
          },

          '&.Mui-focused': {
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_FOCUSED,

            '& .MuiOutlinedInput-notchedOutline': {
              border: getBorderProp(TOKENS.INPUT_HOVER)
            }
          },

          '&.Mui-disabled': {
            opacity: TOKENS.CORE_OPACITY_OPCT_40,
            color: 'inherit',

            '& .MuiOutlinedInput-notchedOutline': {
              border: getBorderProp(TOKENS.INPUT_DEFAULT)
            }
          },

          '& legend': {
            fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_DECORATION
          }
        },
        notchedOutline: {
          border: getBorderProp(TOKENS.INPUT_DEFAULT),
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M
        },
        input: {
          color: TOKENS.COMPONENT_COLOR_INPUT_FG_PRIMARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION,
          padding: theme.spacing(0, 1.5),
          backgroundClip: 'text !important',
          WebkitBackgroundClip: 'text !important',
          WebkitTextFillColor: `${TOKENS.COMPONENT_COLOR_INPUT_FG_PRIMARY} !important`,

          '&.MuiInputBase-inputMultiline': {
            padding: theme.spacing(1.5)
          }
        },
        sizeSmall: {
          minHeight: TOKENS.BASE_SIZING_SIZING_4,

          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: TOKENS.BASE_BORDER_RADIUS_S
          },

          '& input': {
            padding: theme.spacing(0, 1),

            '&.MuiInputBase-inputMultiline': {
              padding: theme.spacing(1)
            }
          }
        }
      }
    },
    MuiInputLabel: {
      variants: [
        {
          props: { size: 'large' },
          style: {
            fontFamily: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_TEXT_DECORATION,
            transform: `translate(${theme.spacing(1.5)}, 12px) scale(1)`,

            '&.MuiInputLabel-shrink': {
              color: TOKENS.COMPONENT_COLOR_BADGE_FG_PRIMARY,
              fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_FAMILY,
              fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_WEIGHT,
              fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE,
              lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LINE_HEIGHT,
              letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LETTER_SPACING,
              textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_DECORATION,
              transform: `translate(14px, -6px) scale(1)`
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          color: TOKENS.COMPONENT_COLOR_INPUT_FG_SECONDARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION,
          transform: `translate(${theme.spacing(1.5)}, 10px) scale(1)`,

          '&.Mui-focused': {
            color: TOKENS.COMPONENT_COLOR_BADGE_FG_PRIMARY
          },

          '&.Mui-error': {
            color: TOKENS.COMPONENT_COLOR_INPUT_FG_PRIMARY
          }
        },
        shrink: {
          color: TOKENS.COMPONENT_COLOR_BADGE_FG_PRIMARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_DECORATION,
          transform: `translate(14px, -7px) scale(1)`
        },

        sizeSmall: {
          transform: `translate(${theme.spacing(1)}, 5px) scale(1)`,

          '&.MuiInputLabel-shrink': {
            transform: `translate(14px, -7px) scale(1)`
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'relative',
          margin: theme.spacing(0, 1),
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION,
          color: TOKENS.COMPONENT_COLOR_INPUT_FG_SECONDARY,

          '&.Mui-error': {
            color: TOKENS.COMPONENT_COLOR_INPUT_FG_DANGER
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            padding: 0,
            height: TOKENS.BASE_SIZING_SIZING_6,
            overflow: 'hidden',
            paddingRight: theme.spacing(4),

            '& .MuiAutocomplete-input': {
              padding: theme.spacing(0, 1.5)
            },

            '&.MuiInputBase-sizeSmall': {
              padding: 0,

              '& .MuiAutocomplete-input': {
                padding: theme.spacing(0, 1)
              }
            }
          },
          '&.multiple .MuiOutlinedInput-root': {
            alignItems: 'flex-start',
            padding: theme.spacing(1.75, 4, 1.5, 1.75),
            flexWrap: 'nowrap',
            overflow: 'hidden',

            '&:after': {
              content: '" "',
              position: 'absolute',
              width: TOKENS.BASE_SIZING_SIZING_4,
              right: 1,
              top: 1,
              height: 46,
              background: '#212224',
              borderRadius: '0 8px 8px 0'
            },

            '& .MuiAutocomplete-endAdornment': {
              top: 4,
              zIndex: 1
            },

            '& input': {
              display: 'none'
            }
          },
          '&.Mui-expanded .MuiOutlinedInput-root': {
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_FOCUSED,

            '& .MuiOutlinedInput-notchedOutline': {
              border: getBorderProp(TOKENS.INPUT_HOVER)
            },

            '&:after': {
              background: '#27272a'
            }
          }
        },
        tag: {
          margin: theme.spacing(0, 1, 2, 0)
        },
        listbox: {
          padding: theme.spacing(0, 1.5, 1, 1),

          '& .MuiAutocomplete-option': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION,
            minHeight: TOKENS.BASE_SIZING_SIZING_4,
            padding: theme.spacing(0.5, 1.5),
            borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
            marginTop: theme.spacing(0.5),
            whiteSpace: 'break-spaces',

            '&:hover': {
              background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
            },

            '&[aria-selected="true"]': {
              background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED,

              '&:hover': {
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
              }
            },

            '&[aria-selected="true"].Mui-focused': {
              background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED,

              '&:hover': {
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
              }
            }
          }
        },
        noOptions: {
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION,
          minHeight: TOKENS.BASE_SIZING_SIZING_4,
          padding: theme.spacing(0.5, 1.5),
          display: 'flex',
          alignItems: 'center'
        },
        endAdornment: {
          top: 'auto'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root.Mui-disabled': {
            color: TOKENS.COMPONENT_COLOR_INPUT_FG_SECONDARY,
            opacity: TOKENS.CORE_OPACITY_OPCT_40
          }
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        label: {
          fontFamily: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_TEXT_DECORATION,
          color: TOKENS.CORE_COLOR_ZINC_600
        },
        switchViewButton: {
          border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT),
          width: TOKENS.BASE_SIZING_SIZING_4,
          height: TOKENS.BASE_SIZING_SIZING_4
        }
      }
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT),
          width: TOKENS.BASE_SIZING_SIZING_4,
          height: TOKENS.BASE_SIZING_SIZING_4,
          marginRight: 0,
          marginLeft: theme.spacing(1),

          '&.Mui-disabled': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY
          }
        },
        spacer: {
          display: 'none'
        }
      }
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          '&.MuiDayCalendar-slideTransition': {
            minHeight: 206
          }
        }
      }
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          maxHeight: 300
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          '& .MuiDayCalendar-weekDayLabel': {
            fontFamily: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_M_REGULAR_TEXT_DECORATION,
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY
          }
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          height: TOKENS.BASE_SIZING_SIZING_4,
          width: TOKENS.BASE_SIZING_SIZING_4,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_TEXT_DECORATION,
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,

          '&:hover': {
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER
          },

          '&.Mui-disabled:not(.Mui-selected)': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_SECONDARY
          },
          '&:not(.Mui-selected)': {
            border: 'none'
          }
        },
        today: {
          background: TOKENS.CORE_COLOR_ZINC_700,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,

          '&:focus': {
            background: TOKENS.CORE_COLOR_ZINC_700
          }
        }
      }
    },
    MuiPickersToolbar: {
      styleOverrides: {
        root: {
          display: 'none'
        }
      }
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '& .MuiPickersLayout-actionBar': {
            marginTop: theme.spacing(1),
            padding: theme.spacing(0, 2, 3)
          },
          '& .MuiDivider-root.MuiDivider-fullWidth': {
            display: 'none'
          },
          '& .MuiDialogActions-root .MuiButton-root': {
            height: TOKENS.BASE_SIZING_SIZING_5,
            padding: theme.spacing(0, 3),
            fontFamily: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_WEIGHT,
            lineHeight: TOKENS.TYPOGRAPHY_BUTTON_M_LINE_HEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_SIZE,
            letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_M_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BUTTON_M_TEXT_DECORATION,
            borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
            color: TOKENS.COMPONENT_COLOR_BUTTON_FG_PRIMARY,
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_DEFAULT,

            '&:hover': {
              background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_HOVER
            },

            '&:focus': {
              background: TOKENS.COMPONENT_COLOR_BUTTON_BG_PRIMARY_FOCUSED
            }
          },
          '& .MuiDialogActions-root .MuiButton-root:last-child': {
            margin: 0
          }
        }
      }
    },
    MuiMultiSectionDigitalClock: {
      styleOverrides: {
        root: {
          maxHeight: 300
        }
      }
    },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          '&:after': {
            display: 'none'
          }
        },
        item: {
          width: TOKENS.BASE_SIZING_SIZING_4,
          minHeight: TOKENS.BASE_SIZING_SIZING_4,
          height: TOKENS.BASE_SIZING_SIZING_4,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_M_MEDIUM_TEXT_DECORATION,

          '&:hover': {
            background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: theme.spacing(0.5),

          '&:before': {
            content: '" "',
            position: 'absolute',
            display: 'block',
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_DEFAULT,
            borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
            border: getBorderProp(TOKENS.INPUT_DEFAULT),
            width: TOKENS.BASE_SIZING_SIZING_3,
            height: TOKENS.BASE_SIZING_SIZING_3
          },

          '&.Mui-focusVisible:before': {
            border: getBorderProp(TOKENS.INPUT_HOVER),
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_FOCUSED
          },

          '& .MuiSvgIcon-root': {
            fontSize: TOKENS.BASE_SIZING_SIZING_2,
            opacity: 0
          },

          '&.Mui-checked .MuiSvgIcon-root': {
            opacity: 1,
            zIndex: 1
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,

          '&:hover .MuiCheckbox-colorPrimary:before': {
            border: getBorderProp(TOKENS.INPUT_HOVER)
          },

          '&:active .MuiCheckbox-colorPrimary:before': {
            border: getBorderProp(TOKENS.INPUT_HOVER),
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_FOCUSED
          },

          '&:focus .MuiCheckbox-colorPrimary:before': {
            border: getBorderProp(TOKENS.INPUT_HOVER),
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_FOCUSED
          }
        },
        label: {
          marginLeft: theme.spacing(1.5),
          color: TOKENS.CORE_COLOR_ZINC_400,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-paper': {
            background: TOKENS.COMPONENT_COLOR_INPUT_BG_FOCUSED,
            border: getBorderProp(TOKENS.INPUT_HOVER),
            borderTop: 'none',
            borderRadius: `0 0 ${TOKENS.BASE_BORDER_RADIUS_M} ${TOKENS.BASE_BORDER_RADIUS_M}`,
            marginTop: -6,

            '& .MuiAutocomplete-listbox': {
              maxHeight: 136
            },

            '&.size-small': {
              marginTop: -5,

              '& .MuiAutocomplete-listbox': {
                maxHeight: 130
              }
            },

            '&.size-large': {
              marginTop: -7
            }
          },

          '&.MuiPickersPopper-paper': {
            background: TOKENS.CORE_COLOR_ZINC_800,
            borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
            border: getBorderProp(TOKENS.CARD_DEFAULT),
            boxShadow: '0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.10)'
          },

          '&.picker-mobile-paper': {
            padding: 0,
            minWidth: 'auto',

            '@media screen and (max-width: 355px)': {
              maxWidth: '95%',

              '& .MuiDateCalendar-root': {
                width: '100%'
              },

              '& .MuiTimeClock-root': {
                width: '100%'
              },

              '& .MuiYearCalendar-root': {
                width: '100%'
              },

              '& .MuiMonthCalendar-root': {
                width: '100%'
              }
            },

            '& .MuiDateTimePickerTabs-root': {
              margin: theme.spacing(1),
              boxShadow: 'none'
            },

            '& .MuiClockNumber-root.Mui-disabled': {
              color: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
            },

            '& .MuiDialogActions-root .MuiButton-root': {
              margin: theme.spacing(2, 0.5, 0, 0.5)
            },

            '& .MuiDialogActions-root .MuiButton-root:last-child': {
              margin: theme.spacing(2, 0.5, 0, 0.5)
            },

            '& .MuiDialogActions-root .MuiButton-root:first-of-type': {
              border: getBorderProp(TOKENS.BUTTON_SECONDARY_DEFAULT),
              background: 'transparent',

              '&:hover': {
                border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER),
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_HOVER
              },

              '&:focus': {
                border: getBorderProp(TOKENS.BUTTON_SECONDARY_HOVER),
                background: TOKENS.COMPONENT_COLOR_BUTTON_BG_SECONDARY_FOCUSED
              }
            }
          },

          '&.pagination-select-menu': {
            marginTop: theme.spacing(-1),
            padding: theme.spacing(2),
            background: TOKENS.COMPONENT_COLOR_CARD_LEVEL2,
            borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
            boxShadow: '0px 12px 12px 0px rgba(0, 0, 0, 0.30)',

            '& .MuiMenuItem-root': {
              padding: theme.spacing(1),
              marginBottom: theme.spacing(1),
              borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
              color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
              fontFamily: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_FAMILY,
              fontWeight: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_WEIGHT,
              lineHeight: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_LINE_HEIGHT,
              fontSize: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_FONT_SIZE,
              letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_LETTER_SPACING,
              textDecoration: TOKENS.TYPOGRAPHY_BODY_S_MEDIUM_TEXT_DECORATION,

              '&:last-child': {
                margin: 0
              },

              '&.Mui-selected': {
                background: TOKENS.COMPONENT_COLOR_TABLE_BG_HOVER
              }
            }
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          background: TOKENS.COMPONENT_COLOR_ALERT_BG_DEFAULT,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          lineHeight: 1.5,
          boxShadow: 'none',
          position: 'relative',
          padding: theme.spacing(2, 3, 1.5, 1.5),
          width: 343,

          '@media screen and (max-width: 330px)': {
            width: '100%'
          },

          '&:before': {
            content: '" "',
            position: 'absolute',
            width: '100%',
            height: 4,
            background: 'transparent',
            borderRadius: `${TOKENS.BASE_BORDER_RADIUS_M} ${TOKENS.BASE_BORDER_RADIUS_M} 0 0`,
            top: 0,
            left: 0
          }
        },
        standardSuccess: {
          '&:before': {
            background: TOKENS.COMPONENT_COLOR_ALERT_BG_SUCCESS
          }
        },
        standardError: {
          '&:before': {
            background: TOKENS.COMPONENT_COLOR_ALERT_FG_DANGER
          }
        },
        standardWarning: {
          '&:before': {
            background: TOKENS.COMPONENT_COLOR_ALERT_BG_WARNING
          }
        },
        message: {
          flex: 1,
          padding: 0,
          color: TOKENS.COMPONENT_COLOR_ALERT_FG_SECONDARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION,

          '& span': {
            color: TOKENS.COMPONENT_COLOR_ALERT_FG_PRIMARY,
            fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION
          }
        },
        icon: {
          opacity: 1,
          fontSize: TOKENS.BASE_SIZING_SIZING_2_5,
          marginRight: theme.spacing(1),
          padding: 0,
          width: TOKENS.BASE_SIZING_SIZING_3,
          justifyContent: 'center',
          paddingTop: theme.spacing(0.25)
        },
        action: {
          padding: 0,
          marginRight: theme.spacing(-1.5),

          '& button': {
            padding: 0,
            border: 'none',
            borderRadius: 0,
            width: TOKENS.BASE_SIZING_SIZING_1_5,
            height: TOKENS.BASE_SIZING_SIZING_1_5,
            color: TOKENS.COMPONENT_COLOR_ICON_SECONDARY,

            '& .MuiSvgIcon-root': {
              fontSize: TOKENS.BASE_SIZING_SIZING_1_5
            }
          }
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: TOKENS.COMPONENT_COLOR_ALERT_FG_PRIMARY,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_TEXT_DECORATION,
          margin: 0,
          marginBottom: theme.spacing(0.5),

          '&:only-child': {
            margin: 0
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: TOKENS.BASE_SIZING_SIZING_2_5,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
          border: getBorderProp(TOKENS.BADGE_DEFAULT),
          background: TOKENS.COMPONENT_COLOR_BADGE_BG_DEFAULT,
          padding: theme.spacing(0.25, 0.75),
          margin: theme.spacing(1, 0.5),

          '& .MuiChip-deleteIcon': {
            margin: 0,
            marginLeft: theme.spacing(0.5),
            fontSize: 12,
            color: TOKENS.COMPONENT_COLOR_ICON_SECONDARY
          }
        },
        label: {
          fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_MEDIUM_TEXT_DECORATION,
          padding: 0
        },
        deleteIcon: {
          fontSize: 16,
          margin: '0 8px 0 -4px',
          color: PALETTE.BODY_20
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'initial',
          color: 'inherit'
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: TOKENS.CORE_COLOR_ZINC_800,
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          padding: theme.spacing(2),
          margin: 0,
          fontFamily: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_FAMILY,
          fontWeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_WEIGHT,
          fontSize: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_FONT_SIZE,
          lineHeight: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LINE_HEIGHT,
          letterSpacing: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_LETTER_SPACING,
          textDecoration: TOKENS.TYPOGRAPHY_BODY_S_REGULAR_TEXT_DECORATION,
          boxShadow: '0px 12px 16px 0px rgba(0, 0, 0, 0.15)'
        },
        arrow: {
          color: TOKENS.CORE_COLOR_ZINC_800
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'auto'
        },
        indicator: {
          height: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          minHeight: 'auto',
          minWidth: 'auto',
          color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
          textTransform: 'none',

          '&:hover': {
            background: TOKENS.COMPONENT_COLOR_CARD_ACTIVE
          },

          '&.Mui-selected': {
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
            background: TOKENS.COMPONENT_COLOR_CARD_ACTIVE
          },

          '&.size-small': {
            height: TOKENS.BASE_SIZING_SIZING_4,
            borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
            padding: theme.spacing(0.75, 1.5),
            fontFamily: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BUTTON_S_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BUTTON_S_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_S_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BUTTON_S_TEXT_DECORATION,
            marginLeft: theme.spacing(1),

            '@media screen and (max-width: 330px)': {
              fontSize: TOKENS.FONT_SIZE_XXS
            },

            '& .MuiSvgIcon-root': {
              marginRight: theme.spacing(1),
              fontSize: TOKENS.BASE_SIZING_SIZING_2
            }
          },

          '&.size-medium': {
            height: TOKENS.BASE_SIZING_SIZING_5,
            padding: theme.spacing(1, 3),
            fontFamily: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BUTTON_M_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BUTTON_M_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BUTTON_M_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BUTTON_M_TEXT_DECORATION,

            '& .MuiSvgIcon-root': {
              marginRight: theme.spacing(1.5),
              fontSize: TOKENS.BASE_SIZING_SIZING_2_5
            }
          },

          '&.size-large': {
            height: TOKENS.BASE_SIZING_SIZING_6,
            padding: theme.spacing(1.5, 3),
            fontFamily: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_M_SEMI_BOLD_TEXT_DECORATION,
            marginLeft: theme.spacing(2),

            '& .MuiSvgIcon-root': {
              marginRight: theme.spacing(1.5),
              fontSize: TOKENS.BASE_SIZING_SIZING_3
            }
          },
          '&:first-of-type': {
            marginLeft: 0
          }
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: theme.spacing(2),
          marginTop: theme.spacing(1.75),
          borderRadius: TOKENS.BASE_BORDER_RADIUS_M,
          background: TOKENS.COMPONENT_COLOR_CARD_LEVEL2,
          boxShadow: '0px 12px 12px 0px rgba(0, 0, 0, 0.30)',

          '& .MuiListItem-root': {
            marginBottom: theme.spacing(1),

            '&:last-child': {
              margin: 0
            }
          },
          '& .MuiListItemButton-root': {
            padding: theme.spacing(1),
            borderRadius: TOKENS.BASE_BORDER_RADIUS_S,
            color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY
          },
          '& .MuiListItemIcon-root': {
            marginRight: theme.spacing(1)
          },
          '& .MuiListItemText-root': {
            margin: 0,
            fontFamily: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_FAMILY,
            fontWeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_WEIGHT,
            fontSize: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_FONT_SIZE,
            lineHeight: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LINE_HEIGHT,
            letterSpacing: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_LETTER_SPACING,
            textDecoration: TOKENS.TYPOGRAPHY_BODY_XS_REGULAR_TEXT_DECORATION
          }
        }
      }
    }
  }
})

export default theme
