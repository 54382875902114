import React, { FC, ReactNode } from 'react'
import Box from '@mui/material/Box'
import styles from './AuthPageLayout.styles'

interface AuthPageLayoutProps {
  children?: ReactNode
}

const AuthPageLayout: FC<AuthPageLayoutProps> = ({ children }) => <Box sx={styles.layout}>{children || ''}</Box>

export default AuthPageLayout
