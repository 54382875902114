import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SnippetsIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00001 3V5H5.00001V9.30278L3.20186 12L5.00001 14.6972V19H7.00001L7.00001 21L3.00001 21V15.3028L0.798157 12L3.00001 8.69723V3H7.00001ZM17 21L17 19L19 19V14.6972L20.7981 12L19 9.30278V5L17 5L17 3H21L21 8.69723L23.2018 12L21 15.3028V21H17ZM15 11H9V13H15V11ZM7 7H17V9H7V7ZM17 15H7V17H17V15Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default SnippetsIcon24
