import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const SuccessIcon20: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 20 20" fontSize="icon20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3333 10.0004C18.3333 14.6027 14.6024 18.3337 9.99999 18.3337C5.39762 18.3337 1.66666 14.6027 1.66666 10.0004C1.66666 5.39798 5.39762 1.66702 9.99999 1.66702C14.6024 1.66702 18.3333 5.39798 18.3333 10.0004ZM8.375 12.8004C8.49167 12.917 8.65 12.9837 8.81667 12.9837C8.98334 12.9837 9.14167 12.917 9.25834 12.8004L13.9833 8.07536C14.225 7.83369 14.225 7.43369 13.9833 7.19202C13.7417 6.95036 13.3417 6.95036 13.1 7.19202L8.81667 11.4754L6.9 9.55869C6.65834 9.31702 6.25834 9.31702 6.01667 9.55869C5.775 9.80036 5.775 10.2004 6.01667 10.442L8.375 12.8004Z"
        fill="#22C55E"
      />
    </SvgIcon>
  )
}

export default SuccessIcon20
