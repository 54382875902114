import { SIDE_BAR_WIDTH } from 'constants/theme.constants'
import BGMain from 'components/shared/images/webp/bg.main.webp'
import theme from 'configs/theme.config'
import * as TOKENS from 'constants/tokens'

const styles = {
  layout: {
    background: `url(${BGMain}) no-repeat top center`,
    backgroundAttachment: 'fixed',
    display: 'flex',
    minHeight: '100vh',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    mb: 3,
    height: 28,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      mb: 5,
      height: TOKENS.BASE_SIZING_SIZING_6
    }
  },
  menuBtn: {
    position: 'absolute',
    right: 0
  },
  main: {
    px: 6,
    pb: 8,
    pt: 3,
    width: `calc(100% - ${SIDE_BAR_WIDTH}px)`,
    flexDirection: 'column',
    flexWrap: 'nowrap',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      p: 3,
      pb: 20
    },

    [theme.breakpoints.down('xs')]: {
      pt: 4,
      px: 2
    }
  }
}

export default styles
