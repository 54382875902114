import React, { HTMLAttributes, SyntheticEvent, ReactNode } from 'react'
import { useIntl, IntlShape } from 'react-intl'
import { useController } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import FieldText from 'components/shared/form/FieldText'
import { Controller, AfterFieldChange } from 'types/form.types'
import { SXProps } from 'types/theme.types'

interface FieldAutocompleteProps<TOptionsList, TOption, TValue> extends Controller {
  optionsList: TOptionsList
  label?: string
  getValue: (value: TValue) => TValue
  getNewValue: (newValue: TOption | null) => TValue
  afterFieldChange?: AfterFieldChange
  getOptionLabel: (option: TOption) => string
  renderOption: (props: HTMLAttributes<HTMLElement>, option: TOption) => ReactNode
  fullWidth?: boolean
  size?: 'small' | 'medium' | 'large'
  sx?: SXProps
  readOnly?: boolean
  helperText?: string
  multiple?: boolean
  renderTags?: (value: TOption[], getTagProps: (index: number) => HTMLAttributes<HTMLElement>) => ReactNode
}

const FieldAutocomplete = <TOptionsList, TOption, TValue>({
  control,
  name,
  optionsList,
  label,
  getValue,
  getNewValue,
  afterFieldChange,
  getOptionLabel,
  renderOption,
  fullWidth = true,
  size = 'large',
  multiple,
  renderTags,
  sx,
  readOnly,
  helperText
}: FieldAutocompleteProps<TOptionsList, TOption, TValue>) => {
  const intl: IntlShape = useIntl()
  const {
    field: { onChange, value, ...field },
    fieldState: { error }
  } = useController({ name, control })

  const handleChange = (event: SyntheticEvent, newValue: TOption) => {
    onChange(getNewValue(newValue))

    afterFieldChange?.()
  }

  return (
    <Autocomplete
      {...field}
      classes={{ root: multiple ? 'multiple' : 'inline' }}
      disableCloseOnSelect={multiple}
      multiple={multiple}
      disableClearable
      value={getValue(value)}
      sx={sx}
      fullWidth={fullWidth}
      size={size}
      options={optionsList}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      renderTags={renderTags}
      renderOption={renderOption}
      noOptionsText={intl.formatMessage({ id: 'FORM.TEXT.NO_OPTIONS' })}
      renderInput={params => (
        <FieldText
          {...params}
          size={size}
          disabled={readOnly}
          label={label}
          errorMessage={error?.message}
          helperText={helperText}
        />
      )}
      readOnly={readOnly}
      componentsProps={{
        paper: {
          classes: {
            root: `size-${size}`
          }
        },
        popper: {
          modifiers: [
            {
              name: 'flip',
              enabled: false
            },
            {
              name: 'preventOverflow',
              enabled: false
            }
          ]
        }
      }}
    />
  )
}

export default FieldAutocomplete
