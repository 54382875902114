import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CloseIcon12: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 12 12" fontSize="icon12" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.96828 1.9683C9.57776 1.57778 8.94459 1.57778 8.55407 1.9683L5.93659 4.58577L3.38251 2.0317C2.99199 1.64118 2.35882 1.64118 1.9683 2.0317C1.57777 2.42222 1.57777 3.05539 1.9683 3.44591L4.52238 5.99998L1.96825 8.55411C1.57772 8.94463 1.57772 9.57779 1.96825 9.96832C2.35877 10.3588 2.99194 10.3588 3.38246 9.96832L5.93659 7.4142L8.55408 10.0317C8.94461 10.4222 9.57777 10.4222 9.9683 10.0317C10.3588 9.64116 10.3588 9.00799 9.9683 8.61747L7.35081 5.99998L9.96828 3.38252C10.3588 2.99199 10.3588 2.35883 9.96828 1.9683Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default CloseIcon12
