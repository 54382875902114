import React, { FC } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import PageLayout from 'components/PageLayout'
import Tabs from 'components/shared/Tabs'
import StorageIcon24 from 'components/shared/icons/24x24/StorageIcon24'
import InProgressIcon24 from 'components/shared/icons/24x24/InProgressIcon24'
import {
  PATH_DOMAINS,
  SUB_PATH_DOMAINS_STORAGE,
  SUB_PATH_DOMAINS_ACTIVE,
  PATH_DOMAINS_STORAGE,
  PATH_DOMAINS_ACTIVE,
  PATH_DOMAINS_ACTIVE_DEFAULT
} from 'constants/routes.constants'
import DomainsActive from './DomainsActive'
import DomainsStorage from './DomainsStorage'
import styles from './DomainsPage.styles'

const DOMAINS_ROUTES = [
  {
    path: `${SUB_PATH_DOMAINS_STORAGE}/*`,
    Component: DomainsStorage
  },
  {
    path: `${SUB_PATH_DOMAINS_ACTIVE}/*`,
    Component: DomainsActive
  }
]

const TABS_LIST = [
  {
    label: 'DOMAINS_PAGE.TABS.TITLE.DOMAINS_ACTIVE',
    path: PATH_DOMAINS_ACTIVE,
    icon: <InProgressIcon24 />
  },
  {
    label: 'DOMAINS_PAGE.TABS.TITLE.DOMAINS_STORAGE',
    path: PATH_DOMAINS_STORAGE,
    icon: <StorageIcon24 />
  }
]

const DomainsPage: FC = () => {
  const { pathname } = useLocation()

  return pathname === PATH_DOMAINS ? (
    <Navigate to={PATH_DOMAINS_ACTIVE_DEFAULT} replace />
  ) : (
    <PageLayout title="DOMAINS_PAGE.TITLE" titleContent={<Tabs sx={styles.tabs} tabs={TABS_LIST} />}>
      <Routes>
        {DOMAINS_ROUTES.map(route => (
          <Route key={route.path} {...route} />
        ))}
      </Routes>
    </PageLayout>
  )
}

export default DomainsPage
