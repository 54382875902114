import * as TOKENS from 'constants/tokens'

const styles = {
  container: {
    background: TOKENS.COMPONENT_COLOR_CARD_SIDEBAR,
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    p: 1,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 20
  }
}

export default styles
