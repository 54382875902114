import { object, number, string } from 'yup'
import moment from 'moment'
import { requiredStringSchema, requiredCheckboxGroupSchema, convertStringToNumber } from 'utils/schemas.utils'

export default object({
  domains: requiredCheckboxGroupSchema,
  linksGenerationType: requiredStringSchema,
  anchorGenerationType: requiredStringSchema,
  serverId: number().typeError('FORM.FIELD.VALIDATION.ERROR.REQUIRED').required('FORM.FIELD.VALIDATION.ERROR.REQUIRED'),
  groupId: requiredCheckboxGroupSchema,
  farmerId: requiredStringSchema,
  datetimeStart: string()
    .typeError('FORM.FIELD.VALIDATION.ERROR.REQUIRED')
    .test(
      'datetimeStart',
      'FORM.FIELD.VALIDATION.ERROR.DATE_MIN',
      date => moment.utc(date) >= moment.utc().endOf('hour')
    ),
  campaignId: requiredStringSchema,
  linksLimit: number()
    .transform(convertStringToNumber)
    .min(1, 'FORM.FIELD.VALIDATION.ERROR.LINKS_AMOUNT_MIN')
    .required('FORM.FIELD.VALIDATION.ERROR.REQUIRED'),
  template: requiredStringSchema
})
