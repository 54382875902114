import theme from 'configs/theme.config'
import * as TOKENS from 'constants/tokens'

const styles = {
  actions: {
    position: 'relative',
    padding: 0,
    alignItems: 'center',
    display: 'flex',
    opacity: 0,
    transition: 'opacity 200ms ease-out',

    '& button': {
      position: 'absolute',
      right: theme.spacing(2)
    }
  },
  copy: {
    opacity: 0,
    transition: 'opacity 200ms ease-out',
    ml: 1
  },
  table: {
    '& th': {
      width: 0,
      minWidth: 'fit-content',
      whiteSpace: 'nowrap',

      '&:nth-of-type(4)': {
        width: 'auto',
        minWidth: 'auto'
      }
    },
    '& tr': {
      position: 'relative',

      '&:hover td:last-child': {
        opacity: 1
      },

      '&:hover .copyBtn': {
        opacity: 1
      },

      '@media (hover: none)': {
        '& td:last-child': {
          opacity: 1,
          width: TOKENS.BASE_SIZING_SIZING_7
        },

        '& .copyBtn': {
          opacity: 1
        }
      }
    }
  },
  warning: {
    backgroundColor: TOKENS.COMPONENT_COLOR_TABLE_BG_DANGER,

    '&:hover': {
      backgroundColor: TOKENS.COMPONENT_COLOR_TABLE_BG_DANGER
    }
  },
  opacity: {
    opacity: TOKENS.CORE_OPACITY_OPCT_40
  },
  secondaryIcon: {
    color: TOKENS.COMPONENT_COLOR_ICON_SECONDARY
  }
}

export default styles
