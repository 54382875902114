import {
  DomainsActionType,
  DomainsState,
  SetParsersInfoAction,
  SetDomainsActiveListAction,
  SetDomainsStorageListAction
} from 'types/domains.types'
import { DEFAULT_PAGINATION } from 'constants/shared.contants'
import { camelCaseArray } from 'utils/shared.utils'
import { normalizePagination } from 'modules/shared.normalizers'
import { normalizeDomainsActiveList } from './domains.normalizers'

type StorageAction = SetDomainsActiveListAction | SetParsersInfoAction | SetDomainsStorageListAction

const initialState: DomainsState = {
  parsersInfo: null,
  domainsActiveList: [],
  domainsActiveListPagination: DEFAULT_PAGINATION,
  domainsStorageList: [],
  domainsStorageListPagination: DEFAULT_PAGINATION
}

const domains = (state = initialState, { type, payload }: StorageAction) => {
  switch (type) {
    case DomainsActionType.SET_PARSERS_INFO:
      return {
        ...state,
        parsersInfo: payload
      }
    case DomainsActionType.SET_DOMAINS_ACTIVE_LIST:
      const { items: domainsActiveList, ...domainsActiveListPagination } = payload

      return {
        ...state,
        domainsActiveList: normalizeDomainsActiveList(domainsActiveList),
        domainsActiveListPagination: normalizePagination(domainsActiveListPagination)
      }
    case DomainsActionType.SET_DOMAINS_STORAGE_LIST:
      const { items: domainsStorageList, ...domainsStorageListPagination } = payload
      return {
        ...state,
        domainsStorageList: camelCaseArray(domainsStorageList),
        domainsStorageListPagination: normalizePagination(domainsStorageListPagination)
      }
    default:
      return state
  }
}

export default domains
