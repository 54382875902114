import React, { FC, useState, SyntheticEvent, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl, IntlShape } from 'react-intl'
import Box from '@mui/material/Box'
import MuiBottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import { nav } from 'configs/sideBar.config'
import styles from './BottomNavigation.styles'

const BottomNavigation: FC = () => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const getValue = () => {
    const index = nav.findIndex(({ selectedPath }) => pathname.includes(selectedPath))

    return index > -1 ? index : 0
  }

  const [value, setValue] = useState<number>(getValue())

  useEffect(() => {
    setValue(getValue())
  }, [pathname])

  const handleNavigation = (event: SyntheticEvent, newValue: number) => {
    navigate(nav[newValue].path)
  }

  return (
    <Box sx={styles.container}>
      <MuiBottomNavigation showLabels value={value} onChange={handleNavigation}>
        {nav.map(({ mobileTitle, icon, path }) => {
          return <BottomNavigationAction key={path} label={intl.formatMessage({ id: mobileTitle })} icon={icon} />
        })}
      </MuiBottomNavigation>
    </Box>
  )
}

export default BottomNavigation
