import { AxiosDispatch } from 'types/system.types'
import {
  DomainsActionType,
  ValidateDomains,
  FetchParsersInfo,
  ParsersInfo,
  FetchDomainsStorageList,
  CreateDomains,
  FetchDomainsActiveList,
  FetchDomainsActiveListPayload,
  FetchDomainsStorageListPayload,
  DeleteDomains
} from 'types/domains.types'
import { addErrorNotificationsItem, addSuccessNotificationsItem } from 'modules/notifications'
import { snakeCaseKeys } from 'utils/shared.utils'
import { logError } from 'utils/system.utils'

export const validateDomains: ValidateDomains = data => {
  return {
    type: DomainsActionType.VALIDATE_DOMAINS,
    payload: {
      request: {
        url: '/materials/domains/verify',
        method: 'post',
        data: snakeCaseKeys(data)
      },
      options: {
        onSuccess({ dispatch }: { dispatch: AxiosDispatch }) {
          dispatch(addSuccessNotificationsItem({ title: 'NOTIFIER.TITLE.SUCCESS.DOMAINS_ADDED' }))
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}

export const fetchParsersInfo: FetchParsersInfo = () => {
  return {
    type: DomainsActionType.FETCH_PARSERS_INFO,
    payload: {
      request: {
        url: '/materials/parsers/info'
      },
      options: {
        onSuccess({ dispatch, response }: { response: ParsersInfo; dispatch: AxiosDispatch }) {
          dispatch({
            type: DomainsActionType.SET_PARSERS_INFO,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const fetchDomainsActiveList: FetchDomainsActiveList = (sortMethod, sortBy, page, perPage) => {
  return {
    type: DomainsActionType.FETCH_DOMAINS_ACTIVE_LIST,
    payload: {
      request: {
        url: `/materials/domains?sort_method=${sortMethod}&sort_by=${sortBy}&page=${page}&per_page=${perPage}`
      },
      options: {
        onSuccess({ dispatch, response }: { response: FetchDomainsActiveListPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: DomainsActionType.SET_DOMAINS_ACTIVE_LIST,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const fetchDomainsStorageList: FetchDomainsStorageList = (sortMethod, sortBy, page, perPage) => {
  return {
    type: DomainsActionType.FETCH_DOMAINS_STORAGE_LIST,
    payload: {
      request: {
        url: `/materials/domainsSandbox?sort_method=${sortMethod}&sort_by=${sortBy}&page=${page}&per_page=${perPage}&status=verified`
      },
      options: {
        onSuccess({ dispatch, response }: { response: FetchDomainsStorageListPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: DomainsActionType.SET_DOMAINS_STORAGE_LIST,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
          throw new Error(error)
        }
      }
    }
  }
}

export const createDomains: CreateDomains = data => {
  return {
    type: DomainsActionType.CREATE_DOMAINS,
    payload: {
      request: {
        url: '/materials/domains',
        method: 'post',
        data: snakeCaseKeys(data)
      },
      options: {
        onSuccess({ dispatch }: { dispatch: AxiosDispatch }) {
          dispatch(addSuccessNotificationsItem({ title: 'NOTIFIER.TITLE.SUCCESS.CREATE_DOMAINS' }))
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}

export const deleteDomains: DeleteDomains = domains => {
  return {
    type: DomainsActionType.DELETE_DOMAINS,
    payload: {
      request: {
        url: '/materials/domainsSandbox/delete',
        method: 'post',
        data: { domains }
      },
      options: {
        onSuccess({ dispatch }: { dispatch: AxiosDispatch }) {
          dispatch(addSuccessNotificationsItem({ title: 'NOTIFIER.TITLE.SUCCESS.DELETE_DOMAINS' }))
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}
