import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const LogInIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 22V8.07927L6.97388 2H17.0261L22 8.07927V22H2ZM1 23V7.72231L6.5 1H17.5L23 7.72231V23H1ZM4 20V8.97578L7.98063 4H16.0194L20 8.97578V20H4ZM3 21V8.625L7.5 3H16.5L21 8.625V21H3ZM12 8L12.6838 10.0513C12.8828 10.6485 13.3515 11.1172 13.9487 11.3162L16 12L13.9487 12.6838C13.3515 12.8828 12.8828 13.3515 12.6838 13.9487L12 16L11.3162 13.9487C11.1172 13.3515 10.6485 12.8828 10.0513 12.6838L8 12L10.0513 11.3162C10.6485 11.1172 11.1172 10.6485 11.3162 10.0513L12 8ZM6 9.84311V18H18V9.84311L15.0109 6H8.98908L6 9.84311ZM5 9.5V19H19V9.5L15.5 5H8.5L5 9.5Z"
        fill="currentcolor"
      />
    </SvgIcon>
  )
}

export default LogInIcon24
