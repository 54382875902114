import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const StorageIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6C4 4.89543 4.89543 4 6 4H18C19.1046 4 20 4.89543 20 6V13V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V13V6ZM18 6V13H16.4444L15 15H9L7.55556 13H6V6H18ZM17 9H7V7H17V9ZM17 12H7V10H17V12Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default StorageIcon24
