import React, { FC, useState, ReactNode, MouseEvent } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useIntl, IntlShape } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import Grid from '@mui/material/Grid'
import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import BackButton from 'components/shared/BackButton'
import LogoTextEnIcon from 'components/shared/icons/LogoTextEnIcon'
import MenuIcon20 from 'components/shared/icons/20x20/MenuIcon20'
import { ResetUser } from 'types/user.types'
import { PATH_LANDING } from 'constants/routes.constants'
import theme from 'configs/theme.config'
import SideBar from './SideBar'
import BottomNavigation from './BottomNavigation'
import UserName from './UserName'
import styles from './PageLayout.styles'

interface PageLayoutProps extends BoxProps {
  resetUser: ResetUser
  title?: string
  isBackBtn?: boolean
  titleContent?: ReactNode
  children: ReactNode
}

const PageLayout: FC<PageLayoutProps> = ({ children, resetUser, title, isBackBtn, titleContent, sx, ...props }) => {
  const intl: IntlShape = useIntl()
  const isSMSize = useMediaQuery(theme.breakpoints.down('sm'))
  const isXSSize = useMediaQuery(theme.breakpoints.down('xs'))
  const is370Size = useMediaQuery('(max-width:370px)')

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    resetUser()
  }

  return (
    <Box sx={{ ...styles.layout, ...sx }} {...props}>
      {!isSMSize && <SideBar />}
      <Grid sx={styles.main} container>
        <Box sx={styles.header}>
          {isSMSize ? (
            <>
              <Link sx={{ display: 'flex' }} component={RouterLink} to={PATH_LANDING}>
                <LogoTextEnIcon sx={{ width: 115, height: 48 }} />
              </Link>
              <IconButton sx={styles.menuBtn} color="outlinedSecondary" onClick={handlePopoverOpen}>
                <MenuIcon20 />
              </IconButton>
            </>
          ) : (
            <UserName sx={{ cursor: 'pointer' }} onClick={handlePopoverOpen} />
          )}
        </Box>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          slotProps={{
            paper: {
              sx: {
                width: isSMSize ? 'auto' : 114
              }
            }
          }}
        >
          {isSMSize ? (
            <Box my={2} mx={1}>
              <UserName mb={2} />
              <Button size="small" color="secondary" variant="outlined" fullWidth onClick={handleLogOut}>
                {intl.formatMessage({ id: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.LOGOUT' })}
              </Button>
            </Box>
          ) : (
            <List>
              <ListItem>
                <ListItemButton onClick={handleLogOut}>
                  <ListItemText
                    disableTypography
                    primary={intl.formatMessage({ id: 'PAGE_LAYOUT.SIDE_BAR.NAV.TITLE.LOGOUT' })}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}
        </Popover>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          mb={isSMSize ? 5 : 6}
          height={isXSSize ? 'auto' : 48}
        >
          <Typography variant="h5" mr={2}>
            {isBackBtn && <BackButton sx={{ mr: 6 }} />}
            {title ? intl.formatMessage({ id: title }) : null}
          </Typography>
          {titleContent ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flex={is370Size ? 'none' : 1}
              width="100%"
            >
              {titleContent}
            </Box>
          ) : null}
        </Box>
        <Box>{children}</Box>
      </Grid>
      {isSMSize && <BottomNavigation />}
    </Box>
  )
}

export default PageLayout
