import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const UserIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 22" fontSize="icon24" {...props}>
      <path
        d="M12 12.25C14.7614 12.25 17 10.0114 17 7.25C17 4.48858 14.7614 2.25 12 2.25C9.23858 2.25 7 4.48858 7 7.25C7 10.0114 9.23858 12.25 12 12.25Z"
        fill="#52525B"
      />
      <path
        d="M12 14.75C6.99 14.75 2.91 18.11 2.91 22.25C2.91 22.53 3.13 22.75 3.41 22.75H20.59C20.87 22.75 21.09 22.53 21.09 22.25C21.09 18.11 17.01 14.75 12 14.75Z"
        fill="#52525B"
      />
    </SvgIcon>
  )
}

export default UserIcon24
