import React, { FC } from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { BoxProps } from '@mui/material/Box'
import Typography, { TypographyProps } from '@mui/material/Typography'

interface ThousandNumberProps extends TypographyProps {
  value: number
}

const ThousandNumber: FC<ThousandNumberProps & BoxProps & NumberFormatProps> = ({
  value,
  prefix,
  suffix,
  ...props
}) => {
  return (
    <Typography variant="inherit" {...props}>
      <NumberFormat value={value} displayType={'text'} thousandSeparator={','} prefix={prefix} suffix={suffix} />
    </Typography>
  )
}

export default ThousandNumber
