import {
  MaterialsActionType,
  FetchServersList,
  ServersListPayload,
  FetchFarmersList,
  FarmersListPayload
} from 'types/materials.types'
import { AxiosDispatch } from 'types/system.types'
import { logError } from 'utils/system.utils'

export const fetchServersList: FetchServersList = () => {
  return {
    type: MaterialsActionType.FETCH_SERVERS_LIST,
    payload: {
      request: {
        url: '/materials/servers'
      },
      options: {
        onSuccess({ dispatch, response }: { response: ServersListPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: MaterialsActionType.SET_SERVERS_LIST,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const fetchFarmersList: FetchFarmersList = () => {
  return {
    type: MaterialsActionType.FETCH_FARMERS_LIST,
    payload: {
      request: {
        url: '/materials/farmers'
      },
      options: {
        onSuccess({ dispatch, response }: { response: FarmersListPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: MaterialsActionType.SET_FARMERS_LIST,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}
