import React, { FC } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { BASE_SIZING_SIZING_4 } from 'constants/tokens'
import theme from 'configs/theme.config'
import styles from './SubtitleBox.styles'

interface SubtitleBoxProps extends BoxProps {
  title?: string
}

const SubtitleBox: FC<SubtitleBoxProps> = ({ title, children, ...props }) => {
  const intl: IntlShape = useIntl()
  const isBetweenSize = useMediaQuery(theme.breakpoints.between('sm', 875))
  const is630Size = useMediaQuery('(max-width:630px)')

  return (
    <Box
      mb={3}
      display="flex"
      alignItems="center"
      justifyContent={title ? 'space-between' : 'flex-end'}
      minHeight={BASE_SIZING_SIZING_4}
      {...props}
    >
      {title && !isBetweenSize && !is630Size ? (
        <Typography variant="h6" mr={2}>
          {intl.formatMessage({ id: title })}
        </Typography>
      ) : null}
      <Box sx={styles.buttons}>{children}</Box>
    </Box>
  )
}

export default SubtitleBox
