import { ShellsTableHead, ShellsTableHeadIds } from 'types/shells.types'

export const SHELLS_TABLE_HEAD: ShellsTableHead = [
  {
    id: ShellsTableHeadIds.ID,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.ID',
    sortable: true
  },
  {
    id: ShellsTableHeadIds.CREATED_AT,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.CREATED_AT',
    sortable: true
  },
  {
    id: ShellsTableHeadIds.COST,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.COST'
  },
  {
    id: ShellsTableHeadIds.DOMAIN,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.DOMAIN',
    sortable: true
  },
  {
    id: ShellsTableHeadIds.METHOD,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.METHOD'
  },
  {
    id: ShellsTableHeadIds.FILE_STATUS,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.FILE_STATUS'
  },
  {
    id: ShellsTableHeadIds.CACHED_STATUS,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.CACHED_STATUS'
  },
  {
    id: ShellsTableHeadIds.REQUESTS_TODAY,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.REQUESTS_TODAY',
    sortable: true
  },
  {
    id: ShellsTableHeadIds.REQUESTS_YESTERDAY,
    label: 'SHELLS_PAGE.SHELLS_TABLE.HEAD.TITLE.REQUESTS_YESTERDAY',
    sortable: true
  }
]

export const SHELLS_FGET_STATUS_OPTIONS = [
  {
    value: true,
    title: 'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_FGET_STATUS_OPTION.TRUE'
  },
  {
    value: false,
    title: 'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_FGET_STATUS_OPTION.FALSE'
  }
]

export const SHELLS_CACHE_STATUS_OPTIONS = [
  {
    value: true,
    title: 'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_CACHE_STATUS_OPTION.TRUE'
  },
  {
    value: false,
    title: 'SHELLS_PAGE.ADD_SHELL_FORM.SHELLS_CACHE_STATUS_OPTION.FALSE'
  }
]

export const SHELLS_DEFAULT_PAGINATION_PER_PAGE = 100
export const SHELLS_PAGINATION_OPTIONS = [100, 200, 300, 400]

export const SHELLS_DEFAULT_PAGINATION = {
  count: 0,
  page: 1,
  lastPage: 1,
  perPage: SHELLS_DEFAULT_PAGINATION_PER_PAGE,
  total: 0
}
