import { SetShellAction, SetShellsListAction, ShellsActionType, ShellsState } from 'types/shells.types'
import { SHELLS_DEFAULT_PAGINATION } from 'constants/shells.constants'
import { camelCaseArray, camelCaseKeys } from 'utils/shared.utils'
import { normalizePagination } from 'modules/shared.normalizers'

type ShellsAction = SetShellsListAction | SetShellAction

const initialState: ShellsState = {
  shellsList: [],
  shellsListPagination: SHELLS_DEFAULT_PAGINATION
}

const shells = (state = initialState, { type, payload }: ShellsAction) => {
  switch (type) {
    case ShellsActionType.SET_SHELLS_LIST:
      const { items: shellsList, ...shellsListPagination } = payload

      return {
        ...state,
        shellsList: camelCaseArray(shellsList),
        shellsListPagination: normalizePagination(shellsListPagination)
      }
    case ShellsActionType.SET_SHELL:
      const { data, shellId } = payload

      return {
        ...state,
        shellsList: state.shellsList.map(shell => (shell.id === shellId ? camelCaseKeys(data) : shell))
      }
    default:
      return state
  }
}

export default shells
