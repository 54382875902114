import theme from 'configs/theme.config'
import * as TOKENS from 'constants/tokens'

const styles = {
  statistics: {
    '& .highchartsXAxis': {
      my: 2,

      '& text': {
        fontFamily: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_FAMILY,
        fontWeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_WEIGHT,
        lineHeight: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LINE_HEIGHT,
        fontSize: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_FONT_SIZE,
        letterSpacing: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_LETTER_SPACING,
        textDecoration: TOKENS.TYPOGRAPHY_BODY_XXS_REGULAR_TEXT_DECORATION
      }
    }
  },
  tabs: {
    mb: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  chart: {
    overflowX: 'auto',

    '& > *': {
      minWidth: 795
    }
  }
}

export default styles
