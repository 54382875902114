import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ShellsIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66827 3.14552C3.26791 3.28631 3.00003 3.66448 3.00003 4.08888V12.561C3.00003 13.0789 3.10062 13.5919 3.29623 14.0715L3.58384 14.7767L2.57758 15.6303L1.5647 17.1912L3.348 16.8193L2.60498 17.7548L2.09716 19.545L3.72585 18.645L4.23552 17.982L3.07468 20.0857L2.87144 21.9354L4.32791 20.7773L4.64564 20.3386L4.5124 21.0619L4.9051 22.8808L5.9201 21.3212L6.16571 19.8724L8.70234 22.6712C8.8919 22.8803 9.16103 22.9996 9.4433 22.9996H14.0936C14.3542 22.9996 14.6045 22.8979 14.7912 22.7162L18.122 19.4735L18.4352 21.3212L19.4502 22.8808L19.8429 21.0619L19.7097 20.3386L20.0274 20.7772L21.4839 21.9353L21.2806 20.0857L19.8962 17.5768L20.6295 18.645L22.2582 19.545L21.7504 17.7548L21.0073 16.8193L22.7906 17.1912L21.7777 15.6302L20.3124 15.0314L20.7038 14.0715C20.8994 13.5919 21 13.0789 21 12.561V4.11068C21 3.67571 20.7189 3.29063 20.3046 3.15817L15.7971 1.71712C15.6003 1.65418 15.3948 1.62213 15.1881 1.62213H8.34145C8.11547 1.62213 7.89112 1.66043 7.67793 1.7354L3.66827 3.14552ZM18.6692 18.9021L18.6936 18.9356L18.5995 18.9918C18.6236 18.9626 18.6469 18.9326 18.6692 18.9021ZM13.8334 5.99965H15.5L18 6.97136H15.5H14.6667L13 7.94306L13.8334 5.99965ZM13 9.49965L14.7572 8.79676C15.2341 8.60603 15.766 8.60603 16.2428 8.79676L18 9.49965L16.2428 10.2025C15.766 10.3933 15.2341 10.3933 14.7572 10.2025L13 9.49965ZM14 13.9996H9.50003L7.33336 16.0034H6.55558L5.00003 14.8373L5.7778 17.1694H8.50003L10 15.9996H13.5L15.1111 17.1694H18.2222L19 14.8373L17.4445 16.0034H16.6667L14 13.9996ZM11.7631 18.1323L10.3317 19.5232L11.7631 20.914L13.1944 19.5232L11.7631 18.1323ZM8.50003 5.99965H10.1667L11 7.94306L9.33336 6.97136H8.50003H6.00003L8.50003 5.99965ZM6.00003 9.49965L7.75724 8.79676C8.23407 8.60603 8.76599 8.60603 9.24281 8.79676L11 9.49965L9.24281 10.2025C8.76599 10.3933 8.23407 10.3933 7.75724 10.2025L6.00003 9.49965Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ShellsIcon24
