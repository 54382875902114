import React, { FC } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PageLayout from 'components/PageLayout'
import FieldTextControlled from 'components/shared/form/FieldTextControlled'
import CampaignsIcon24 from 'components/shared/icons/24x24/CampaignsIcon24'
import { CreateCampaignValues, CreateCampaign } from 'types/campaigns.types'
import theme from 'configs/theme.config'
import schema from './CampaignsPage.schemas'

interface CampaignsPageParams {
  createCampaign: CreateCampaign
}

const CampaignsPage: FC<CampaignsPageParams> = ({ createCampaign }) => {
  const intl: IntlShape = useIntl()
  const isXSSize = useMediaQuery(theme.breakpoints.down('xs'))

  const createCampaignValues: CreateCampaignValues = {
    title: '',
    description: '',
    sourceId: '',
    link: ''
  }

  const { control, handleSubmit, reset } = useForm({
    defaultValues: createCampaignValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const handleCreateCampaign = async (values: CreateCampaignValues) => {
    try {
      await createCampaign(values)

      reset()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <PageLayout title="CAMPAIGNS_PAGE.TITLE">
      <form onSubmit={handleSubmit(handleCreateCampaign)}>
        <FieldTextControlled
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.CAMPAIGN_TITLE"
          name="title"
          control={control}
          helperText="FORM.FIELD.HELPER_TEXT.MAX_255"
        />
        <FieldTextControlled
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.CAMPAIGN_DESCRIPTION"
          name="description"
          control={control}
          helperText="FORM.FIELD.HELPER_TEXT.MAX_255"
        />
        <FieldTextControlled
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.CAMPAIGN_SOURCE_ID"
          name="sourceId"
          control={control}
        />
        <FieldTextControlled sx={{ mb: 2 }} label="FORM.FIELD.TITLE.CAMPAIGN_LINK" name="link" control={control} />
        <Box display="flex" justifyContent="center" mt={4}>
          <Button type="submit" variant="contained" size="large" startIcon={<CampaignsIcon24 />} fullWidth={isXSSize}>
            {intl.formatMessage({ id: 'CAMPAIGNS_PAGE.BUTTON.SUBMIT.CREATE' })}
          </Button>
        </Box>
      </form>
    </PageLayout>
  )
}

export default CampaignsPage
