import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import {
  fetchDomainsStorageList,
  getDomainsStorageListPagination,
  getDomainsStorageList,
  deleteDomains
} from 'modules/domains'
import DomainsStorageTable from './DomainsStorageTable'

const mapStateToProps = (state: RootState) => {
  return {
    domainsStorageList: getDomainsStorageList(state),
    domainsStorageListPagination: getDomainsStorageListPagination(state)
  }
}

const mapDispatchToProps = {
  fetchDomainsStorageList,
  deleteDomains
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsStorageTable)
