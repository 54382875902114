import React, { forwardRef, ForwardedRef } from 'react'
import MuiAlert, { AlertProps as MuiAlertProps } from '@mui/material/Alert'
import SuccessIcon20 from 'components/shared/icons/20x20/SuccessIcon20'
import WarningIcon20 from 'components/shared/icons/20x20/WarningIcon20'
import ErrorIcon20 from 'components/shared/icons/20x20/ErrorIcon20'
import CloseIcon12 from 'components/shared/icons/12x12/CloseIcon12'

const Alert = forwardRef(({ children, ...props }: MuiAlertProps, ref: ForwardedRef<HTMLDivElement>) => (
  <MuiAlert
    ref={ref}
    iconMapping={{ success: <SuccessIcon20 />, error: <ErrorIcon20 />, warning: <WarningIcon20 /> }}
    slots={{
      closeIcon: CloseIcon12
    }}
    {...props}
  >
    {children}
  </MuiAlert>
))

export default Alert
