import {
  KeysActionType,
  FetchKeysGroupsList,
  KeysGroupsListPayload,
  ImportKeys,
  ImportedKeysStatusPayload,
  CreateSnippets
} from 'types/keys.types'
import { AxiosDispatch } from 'types/system.types'
import { addErrorNotificationsItem, addSuccessNotificationsItem } from 'modules/notifications'
import { snakeCaseKeys } from 'utils/shared.utils'
import { logError } from 'utils/system.utils'

export const fetchKeysGroupsList: FetchKeysGroupsList = (sortMethod, sortBy) => {
  return {
    type: KeysActionType.FETCH_KEYS_GROUPS_LIST,
    payload: {
      request: {
        url: `/materials/keyGroups${sortMethod ? `?sort_method=${sortMethod}&sort_by=${sortBy}` : ''}`
      },
      options: {
        onSuccess({ dispatch, response }: { response: KeysGroupsListPayload; dispatch: AxiosDispatch }) {
          if (sortMethod) {
            dispatch({
              type: KeysActionType.SET_SORTED_KEYS_GROUPS_LIST,
              payload: response
            })
          } else {
            dispatch({
              type: KeysActionType.SET_KEYS_GROUPS_LIST,
              payload: response
            })
          }
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const importKeys: ImportKeys = ({ file, title, groupId }) => {
  const formData = new FormData()

  formData.append('file', file as File)

  if (title) {
    formData.append('title', title)
  } else {
    formData.append('group_id', groupId)
  }

  return {
    type: KeysActionType.IMPORT_KEYS,
    payload: {
      request: {
        url: '/materials/keys/import',
        method: 'post',
        data: formData
      },
      options: {
        onSuccess({ response, dispatch }: { response: ImportedKeysStatusPayload; dispatch: AxiosDispatch }) {
          dispatch(
            addSuccessNotificationsItem({
              title: groupId || title,
              severity: 'warning',
              info: [
                { text: 'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.UPLOADED', value: response.uploaded },
                { text: 'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.IMPORTED', value: response.imported },
                { text: 'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.FAILED_DUPLICATED', value: response.failed_text },
                { text: 'NOTIFIER.INFO.TEXT.IMPORTED_KEYS_STATUS.FAILED_TEXT', value: response.failed_duplicated }
              ],
              isAction: true,
              isClose: false
            })
          )
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}

export const createSnippets: CreateSnippets = (data, successInfo) => {
  return {
    type: KeysActionType.CREATE_SNIPPETS,
    payload: {
      request: {
        url: '/materials/snippets/generate',
        method: 'post',
        data: snakeCaseKeys(data)
      },
      options: {
        onSuccess({ dispatch }: { dispatch: AxiosDispatch }) {
          dispatch(
            addSuccessNotificationsItem({
              title: 'NOTIFIER.TITLE.SUCCESS.CREATE_SNIPPETS',
              info: successInfo
            })
          )
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}
