import React, { FC, ReactNode } from 'react'
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon16 from 'components/shared/icons/16x16/CloseIcon16'
import { SXProps } from 'types/theme.types'
import styles from './Dialog.styles'

interface DialogProps extends MuiDialogProps {
  dialogTitle?: ReactNode
  dialogActions?: ReactNode
  onClose?: () => void
  titleSX?: SXProps
  contentSX?: SXProps
  actionsSX?: SXProps
}

const Dialog: FC<DialogProps> = ({
  open,
  onClose,
  dialogTitle,
  dialogActions,
  children,
  titleSX,
  contentSX,
  actionsSX,
  ...props
}) => {
  return (
    <MuiDialog open={open} onClose={onClose} maxWidth={false} scroll="body" {...props}>
      {Boolean(onClose) && (
        <IconButton sx={styles.close} size="small" color="outlinedSecondary" onClick={onClose}>
          <CloseIcon16 />
        </IconButton>
      )}
      {Boolean(dialogTitle) && <DialogTitle sx={titleSX}>{dialogTitle}</DialogTitle>}
      <DialogContent sx={contentSX}>{children}</DialogContent>
      {Boolean(dialogActions) && <DialogActions sx={actionsSX}>{dialogActions}</DialogActions>}
    </MuiDialog>
  )
}

export default Dialog
