import React, { FC } from 'react'
import { Provider } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { SnackbarProvider } from 'notistack'
import { CacheProvider } from '@emotion/react'
import { EmotionCache } from '@emotion/cache'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from 'configs/theme.config'
import configureStore from 'store/configure-store'
import App from 'components/App'
import locales from 'locales'
import { LOCALE } from 'constants/system.constants'
import { PATH_LANDING_EN } from 'constants/routes.constants'

interface RootProps {
  cache: EmotionCache
}

export const store = configureStore()

const Root: FC<RootProps> = ({ cache }) => {
  const { pathname } = useLocation()
  const currentLocale = pathname === PATH_LANDING_EN ? 'en' : LOCALE

  const messages =
    process.env.NODE_ENV === 'production'
      ? {
          ...locales.uk,
          ...locales[currentLocale]
        }
      : locales[currentLocale]

  return (
    <Provider store={store}>
      <IntlProvider
        messages={messages}
        locale={LOCALE}
        defaultLocale="en"
        onError={error => {
          if (error.code === 'MISSING_TRANSLATION' && process.env.NODE_ENV !== 'production') {
            return
          }

          console.log(error)
        }}
      >
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider preventDuplicate maxSnack={1}>
              <>
                <CssBaseline />
                <App />
              </>
            </SnackbarProvider>
          </ThemeProvider>
        </CacheProvider>
      </IntlProvider>
    </Provider>
  )
}

export default Root
