import { AxiosDispatch } from 'types/system.types'
import {
  AddShell,
  FetchShellsList,
  FetchShellsListPayload,
  ShellsActionType,
  ShellsItemPayload,
  UpdateShell
} from 'types/shells.types'
import { addErrorNotificationsItem, addSuccessNotificationsItem } from 'modules/notifications'
import { logError } from 'utils/system.utils'
import { snakeCaseKeys } from 'utils/shared.utils'

export const fetchShellsList: FetchShellsList = (sortMethod, sortBy, page, perPage) => {
  return {
    type: ShellsActionType.FETCH_SHELLS_LIST,
    payload: {
      request: {
        url: `/farms/domainsExternal?sort_method=${sortMethod}&sort_by=${sortBy}&page=${page}&per_page=${perPage}&status=1`
      },
      options: {
        onSuccess({ dispatch, response }: { response: FetchShellsListPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: ShellsActionType.SET_SHELLS_LIST,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const addShell: AddShell = data => {
  return {
    type: ShellsActionType.ADD_SHELL,
    payload: {
      request: {
        url: '/farms/domainsExternal',
        method: 'post',
        data: snakeCaseKeys(data)
      },
      options: {
        onSuccess({ dispatch }: { dispatch: AxiosDispatch }) {
          dispatch(
            addSuccessNotificationsItem({
              title: 'NOTIFIER.TITLE.SUCCESS.ADD_SHELL'
            })
          )
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}

export const updateShell: UpdateShell = (data, shellId) => {
  return {
    type: ShellsActionType.UPDATE_SHELL,
    payload: {
      request: {
        url: `/farms/domainsExternal/${shellId}`,
        method: 'put',
        data: snakeCaseKeys(data)
      },
      options: {
        onSuccess({ dispatch, response }: { response: ShellsItemPayload; dispatch: AxiosDispatch }) {
          dispatch(addSuccessNotificationsItem())

          if (data.fileStatus !== undefined) {
            dispatch({
              type: ShellsActionType.SET_SHELL,
              payload: { data: response, shellId }
            })
          }
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}
