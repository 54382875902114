import theme from 'configs/theme.config'

const styles = {
  close: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  }
}

export default styles
