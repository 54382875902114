import BG from 'components/shared/images/webp/bg.bright.webp'
import BGDevices from 'components/shared/images/webp/bg.devices.webp'
import theme from 'configs/theme.config'

const styles = {
  layout: {
    display: 'flex',
    width: '100%',
    flex: 1,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      background: `url(${BGDevices}) no-repeat top center / 100% auto`,
      px: 2
    },

    '& > *': {
      width: '50%',

      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },

    '& form': {
      width: 286
    }
  },
  image: {
    background: `url(${BG}) no-repeat top center / auto 100%`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      background: 'none'
    }
  },
  logo: {
    mb: 6,
    height: 249,

    [theme.breakpoints.down('sm')]: {
      mt: 11
    },

    [theme.breakpoints.down('xs')]: {
      mb: 4,
      mt: 7,
      height: 179
    }
  },
  slogan: {
    fontFamily: "'Press Start 2P', cursive",
    fontSize: 40,
    fontWeight: 400,
    lineHeight: '64px',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      lineHeight: '40px',
      letterSpacing: '0.96px',
      mb: 9.6
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
      letterSpacing: '0.88px',
      mb: 8.5
    }
  },
  title: {
    mb: 6,

    [theme.breakpoints.down('sm')]: {
      mb: 5
    }
  },
  submit: {
    mt: 4,

    [theme.breakpoints.down('sm')]: {
      mt: 3,
      mb: 5
    }
  }
}

export default styles
