import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { fetchStatistics, getCommonStatistics } from 'modules/statistics'
import StatisticsPage from './StatisticsPage'

const mapStateToProps = (state: RootState) => {
  return {
    commonStatistics: getCommonStatistics(state)
  }
}

const mapDispatchToProps = {
  fetchStatistics
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPage)
