import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import * as TOKENS from 'constants/tokens'

const DeleteIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C6 1.44769 6.44775 1 7.00006 1H9C9.55229 1 10 1.44772 10 2H12C13.1046 2 14 2.89543 14 4H2C2.00006 2.89547 2.89547 2 4 2H6ZM3 5H13V14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 14.5523 3 14V5ZM4 9C4 8.44772 4.44772 8 5 8C5.55228 8 6 8.44772 6 9V11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11V9ZM7 9C7 8.44772 7.44772 8 8 8C8.55228 8 9 8.44772 9 9V11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11V9ZM11 8C10.4477 8 10 8.44772 10 9V11C10 11.5523 10.4477 12 11 12C11.5523 12 12 11.5523 12 11V9C12 8.44772 11.5523 8 11 8Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default DeleteIcon16
