import React, { forwardRef } from 'react'
import { useIntl, IntlShape } from 'react-intl'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import styles from './FieldText.styles'

interface FieldTextOwnProps {
  errorMessage?: string
  helperText?: string
  limit?: number
  size?: 'small' | 'medium' | 'large'
}

export type FieldTextProps = FieldTextOwnProps & TextFieldProps

const FieldText = forwardRef<HTMLInputElement | HTMLDivElement, FieldTextProps>(
  ({ label, fullWidth = true, size = 'large', errorMessage, limit, value, helperText, ...props }, ref) => {
    const intl: IntlShape = useIntl()

    return (
      <TextField
        ref={ref}
        fullWidth={fullWidth}
        size={size}
        helperText={
          <>
            {limit ? (
              <Typography sx={styles.limit} variant="caption" color="textPrimary" component="span">
                {String(value).length}/{limit}
              </Typography>
            ) : null}
            {errorMessage
              ? intl.formatMessage({ id: errorMessage, defaultMessage: errorMessage })
              : helperText
              ? intl.formatMessage({ id: helperText, defaultMessage: helperText })
              : null}
            &nbsp;
          </>
        }
        label={label ? intl.formatMessage({ id: String(label), defaultMessage: String(label) }) : ''}
        value={value}
        {...props}
        error={Boolean(errorMessage)}
      />
    )
  }
)

export default FieldText
