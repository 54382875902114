import { IntlShape } from 'react-intl'
import moment, { Moment } from 'moment'
import mapKeys from 'lodash/mapKeys'
import snakeCase from 'lodash/snakeCase'
import camelCase from 'lodash/camelCase'

export const snakeCaseKeys = (object: Record<string, any>) => {
  return mapKeys(object, (value, key) => snakeCase(key))
}

export const camelCaseKeys = <ObjT extends object, CamelCasedObjT>(object: ObjT): CamelCasedObjT => {
  return mapKeys(object, (value, key) => camelCase(key)) as CamelCasedObjT
}

export const camelCaseArray = <ObjT extends object, CamelCasedArrT>(array: ObjT[]): CamelCasedArrT => {
  return array.map(item => camelCaseKeys(item)) as CamelCasedArrT
}

export const getFormattedDate = (date: Date, intl: IntlShape, isTime?: boolean) => {
  return moment(date).calendar({
    sameDay: `[${intl.formatMessage({ id: 'SHARED.DATE.TEXT.TODAY' })}] ${isTime ? 'HH:mm' : ''}`,
    nextDay: `[${intl.formatMessage({ id: 'SHARED.DATE.TEXT.TOMORROW' })}] ${isTime ? 'HH:mm' : ''}`,
    nextWeek: `DD/MM/YYYY ${isTime ? 'HH:mm' : ''}`,
    lastDay: `[${intl.formatMessage({ id: 'SHARED.DATE.TEXT.YESTERDAY' })}] ${isTime ? 'HH:mm' : ''}`,
    lastWeek: `DD/MM/YYYY ${isTime ? 'HH:mm' : ''}`,
    sameElse: `DD/MM/YYYY ${isTime ? 'HH:mm' : ''}`
  })
}

export const getServerFormattedDate: (date?: Moment, format?: string) => string = (date, format = 'YYYY-MM-DD') => {
  const momentDate = date ? moment(date) : moment()

  return momentDate.utc().format(format)
}

export const filterSelectedTableIds = (id: number, idsList: number[]) => {
  const selectedIndex = idsList.indexOf(id)
  const selectedCount = idsList.length
  let newSelected: number[] = []

  if (selectedIndex === -1) {
    newSelected = [...idsList, id]
  } else if (selectedIndex === 0) {
    newSelected = [...idsList.slice(1)]
  } else if (selectedIndex === selectedCount - 1) {
    newSelected = [...idsList.slice(0, -1)]
  } else if (selectedIndex > 0) {
    newSelected = [...idsList.slice(0, selectedIndex), ...idsList.slice(selectedIndex + 1)]
  }

  return newSelected
}

export const getRGBAColor = (hex: string, transparency: string) => {
  const opacity = parseInt(transparency) / 100
  const values = hex.match(/\w\w/g) || []
  const [r, g, b] = values.map(k => parseInt(k, 16))

  return `rgba( ${r}, ${g}, ${b}, ${opacity} )`
}
