import React, { FC } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import Button from '@mui/material/Button'
import PageLayout from 'components/PageLayout'
import Dialog from 'components/shared/Dialog'
import SubtitleBox from 'components/shared/SubtitleBox'
import SnippetsIcon16 from 'components/shared/icons/16x16/SnippetsIcon16'
import KeysIcon16 from 'components/shared/icons/16x16/KeysIcon16'
import PlusIcon16 from 'components/shared/icons/16x16/PlusIcon16'
import { KeysHash } from 'types/keys.types'
import { PATH_KEYS } from 'constants/routes.constants'
import KeysGroupsTable from './KeysGroupsTable'
import CreateSnippetsForm from './CreateSnippetsForm'
import ImportKeysForm from './ImportKeysForm'

const KeysPage: FC = () => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { hash } = useLocation()

  const closeDialog = () => {
    navigate({
      pathname: PATH_KEYS,
      search: searchParams.toString()
    })
  }

  return (
    <PageLayout title="KEYS_PAGE.TITLE">
      <SubtitleBox title="KEYS_PAGE.SUBTITLE">
        <Button
          size="small"
          variant="contained"
          onClick={() =>
            navigate({
              pathname: PATH_KEYS,
              search: searchParams.toString(),
              hash: KeysHash.SNIPPETS
            })
          }
          startIcon={<SnippetsIcon16 />}
        >
          {intl.formatMessage({ id: 'KEYS_PAGE.BUTTON.GENERATE_SNIPPETS' })}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() =>
            navigate({
              pathname: PATH_KEYS,
              search: searchParams.toString(),
              hash: KeysHash.IMPORT
            })
          }
          startIcon={<KeysIcon16 />}
        >
          {intl.formatMessage({ id: 'KEYS_PAGE.BUTTON.IMPORT_KEYS' })}
        </Button>
      </SubtitleBox>
      <KeysGroupsTable />
      <Dialog
        open={hash === KeysHash.SNIPPETS}
        onClose={closeDialog}
        dialogTitle={intl.formatMessage({ id: 'KEYS_PAGE.DIALOG.TITLE.GENERATE_SNIPPETS' })}
      >
        <CreateSnippetsForm />
      </Dialog>
      <Dialog
        open={hash === KeysHash.IMPORT}
        onClose={closeDialog}
        dialogTitle={intl.formatMessage({ id: 'KEYS_PAGE.DIALOG.TITLE.IMPORT' })}
      >
        <ImportKeysForm />
      </Dialog>
    </PageLayout>
  )
}

export default KeysPage
