import React, { FC } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import styles from './Image.styles'

interface ImageProps extends BoxProps {
  src: string
  alt: string
  width?: number | string
  height?: number | string
  isCover?: boolean
}

const Image: FC<ImageProps> = ({ src, alt, width, height, isCover, sx, ...props }) => (
  <Box sx={{ ...styles.image, ...(isCover && styles.cover), ...sx }} {...props}>
    <img src={src} width={width} height={height} alt={alt} />
  </Box>
)

export default Image
