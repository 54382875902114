import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CampaignsIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <g id="24/campaigns">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4853 3.51472L12 0L3.51472 3.51472L0 12L3.51472 20.4853L12 24L20.4853 20.4853L24 12L20.4853 3.51472ZM4.98719 4.98719L12 2.08239L19.0128 4.98719L21.9176 12L19.0128 19.0128L12 21.9176L4.98719 19.0128L2.08239 12L4.98719 4.98719ZM12 1L19.7782 4.22183L23 12L19.7782 19.7782L12 23L4.22183 19.7782L1 12L4.22183 4.22183L12 1ZM8 6H7.32297L7.07152 6.62861L6.07152 9.12861L5.92297 9.5L6.07152 9.87139L7.07152 12.3714L7.32297 13H8H11V16H7V18H11V19H13V18H16H16.677L16.9285 17.3714L17.9285 14.8714L18.077 14.5L17.9285 14.1286L16.9285 11.6286L16.677 11H16H13V8H17V6H13V5H11V6H8ZM8.67703 8H11V11H8.67703L8.07703 9.5L8.67703 8ZM13 16V13H15.323L15.923 14.5L15.323 16H13Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  )
}

export default CampaignsIcon24
