import React, { FC, useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@mui/material/MobileStepper'
import useMediaQuery from '@mui/material/useMediaQuery'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import FieldTextControlled from 'components/shared/form/FieldTextControlled'
import FieldTextarea from 'components/shared/form/FieldTextarea'
import ArrowRightIcon24 from 'components/shared/icons/24x24/ArrowRightIcon24'
import ArrowLeftIcon24 from 'components/shared/icons/24x24/ArrowLeftIcon24'
import LogInIcon24 from 'components/shared/icons/24x24/LogInIcon24'
import MenuIcon20 from 'components/shared/icons/20x20/MenuIcon20'
import Image from 'components/shared/Image'
import MainImg from 'components/shared/images/webp/main2.webp'
import UTP1Img from 'components/shared/images/webp/utp1.webp'
import UTP2Img from 'components/shared/images/webp/utp2.webp'
import UTP3Img from 'components/shared/images/webp/utp3.webp'
import ScrollDownImg from 'components/shared/images/webp/scrollDown.webp'
import TargetImg from 'components/shared/images/svg/target.svg'
import GridImg from 'components/shared/images/svg/grid.svg'
import LogoImg from 'components/shared/images/svg/logoTextUa.svg'
import LogoEnImg from 'components/shared/images/svg/logoTextEn.svg'
import ArrowRightImg from 'components/shared/images/svg/arrowRight.svg'
import { ContactUsValues, ContactUs } from 'types/user.types'
import { PATH_SIGN_IN, PATH_LANDING_EN } from 'constants/routes.constants'
import theme from 'configs/theme.config'
import schema from './LandingPage.schemas'
import styles from './LandingPage.styles'

interface LandingPageProps {
  contactUs: ContactUs
}

const ADVANTAGES_LIST_MAP = [
  {
    title: 'LANDING_PAGE.ADVANTAGES_LIST.TITLE.ORGANIC_TRAFFIC',
    description: 'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.ORGANIC_TRAFFIC',
    img: UTP1Img,
    color: 'red'
  },
  {
    title: 'LANDING_PAGE.ADVANTAGES_LIST.TITLE.INDUSTRY_FOCUS',
    description: 'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.INDUSTRY_FOCUS',
    img: UTP2Img,
    color: 'green'
  },
  {
    title: 'LANDING_PAGE.ADVANTAGES_LIST.TITLE.TECH_SOLUTIONS',
    description: 'LANDING_PAGE.ADVANTAGES_LIST.DESCRIPTION.TECH_SOLUTIONS',
    img: UTP3Img,
    color: 'blue'
  }
]

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0.3
}

const LandingPage: FC<LandingPageProps> = ({ contactUs }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const aboutRef = useRef<Element>(null)
  const contactRef = useRef<Element>(null)

  const [activeStep, setActiveStep] = useState(0)
  const [isAboutVisible, setIsAboutVisible] = useState(false)
  const [isContactVisible, setIsContactVisible] = useState(false)
  const [isSideMenu, setIsSideMenu] = useState(false)

  const maxSteps = ADVANTAGES_LIST_MAP.length

  const isMDSize = useMediaQuery(theme.breakpoints.down('md'))
  const isWidth710 = useMediaQuery('(max-width:710px)')
  const isHeight600 = useMediaQuery('(max-height:600px)')

  const openMenu = () => setIsSideMenu(true)
  const closeMenu = () => setIsSideMenu(false)

  const handleScroll = (entries: IntersectionObserverEntry[], screenType: 'about' | 'contact') => {
    const [entry] = entries

    screenType === 'about' ? setIsAboutVisible(entry.isIntersecting) : setIsContactVisible(entry.isIntersecting)
  }

  useEffect(() => {
    const aboutObserver = new IntersectionObserver(entries => handleScroll(entries, 'about'), observerOptions)
    const contactObserver = new IntersectionObserver(entries => handleScroll(entries, 'contact'), observerOptions)

    if (aboutRef.current) {
      aboutObserver.observe(aboutRef.current)
    }

    if (contactRef.current) {
      contactObserver.observe(contactRef.current)
    }

    if (isAboutVisible && aboutRef.current) {
      aboutObserver.unobserve(aboutRef.current)
    }

    if (isContactVisible && contactRef.current) {
      contactObserver.unobserve(contactRef.current)
    }

    return () => {
      if (aboutRef.current) {
        aboutObserver.unobserve(aboutRef.current)
      }

      if (contactRef.current) {
        contactObserver.unobserve(contactRef.current)
      }
    }
  }, [isAboutVisible, isContactVisible])

  const scrollToAbout = () => {
    setIsSideMenu(false)
    aboutRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
  const scrollToContact = () => {
    setIsSideMenu(false)
    contactRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => (prevActiveStep === 2 ? 0 : prevActiveStep + 1))
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => (prevActiveStep === 0 ? 2 : prevActiveStep - 1))
  }

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  const contactUsValues: ContactUsValues = {
    email: '',
    subject: '',
    message: ''
  }

  const { control, handleSubmit, reset } = useForm({
    defaultValues: contactUsValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const handleContactUs = async (values: ContactUsValues) => {
    try {
      await contactUs(values)

      reset()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.screen, alignItems: 'center', justifyContent: 'center' }}>
        <Image sx={styles.main} width={isMDSize ? 1473 : 2039} minHeight="100%" src={MainImg} alt="Shaman Solutions" />
        <Image
          sx={styles.grid}
          width={isWidth710 ? 2076 : isMDSize ? 2122 : 2872}
          src={GridImg}
          alt="Shaman Solutions"
        />
        <Box sx={styles.gradients}>
          <Box sx={styles.gradient}>
            <Box sx={styles.gradientShadow} />
          </Box>
          <Box sx={styles.gradientSkewBox}>
            <Box sx={styles.gradientSkew}>
              <Box sx={styles.gradientShadowSkewed} />
            </Box>
          </Box>
        </Box>
        <Image sx={styles.logo} src={pathname === PATH_LANDING_EN ? LogoEnImg : LogoImg} alt="Shaman Solutions" />
        {isMDSize || isHeight600 ? (
          <>
            <IconButton sx={styles.menuBtn} color="primary" onClick={openMenu}>
              <MenuIcon20 />
            </IconButton>
            <SwipeableDrawer sx={styles.sideMenu} anchor="left" open={isSideMenu} onClose={closeMenu} onOpen={openMenu}>
              <Image
                sx={styles.sideLogo}
                width={115}
                src={pathname === PATH_LANDING_EN ? LogoEnImg : LogoImg}
                alt="Shaman Solutions"
              />
              <List>
                <ListItem>
                  <ListItemButton onClick={() => navigate(PATH_SIGN_IN)}>
                    <ListItemText disableTypography primary={intl.formatMessage({ id: 'LANDING_PAGE.MENU.LOG_IN' })} />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={scrollToAbout}>
                    <ListItemText
                      disableTypography
                      primary={intl.formatMessage({ id: 'LANDING_PAGE.MENU.ABOUT_US' })}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={scrollToContact}>
                    <ListItemText disableTypography primary={intl.formatMessage({ id: 'LANDING_PAGE.MENU.CONTACT' })} />
                  </ListItemButton>
                </ListItem>
              </List>
            </SwipeableDrawer>
          </>
        ) : (
          <Box sx={styles.menu}>
            <Box>
              <Button sx={{ mr: 10 }} size="large" onClick={scrollToAbout}>
                {intl.formatMessage({ id: 'LANDING_PAGE.MENU.ABOUT_US' })}
              </Button>
              <Button size="large" onClick={scrollToContact}>
                {intl.formatMessage({ id: 'LANDING_PAGE.MENU.CONTACT' })}
              </Button>
            </Box>
            <Box>
              <Button size="large" startIcon={<LogInIcon24 />} onClick={() => navigate(PATH_SIGN_IN)}>
                {intl.formatMessage({ id: 'LANDING_PAGE.MENU.LOG_IN' })}
              </Button>
            </Box>
          </Box>
        )}
        <Typography
          position="absolute"
          sx={styles.title}
          variant="h1"
          dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'LANDING_PAGE.TITLE.MAKE_MAGIC' }) }}
        />
        <Image sx={styles.scrollDown} src={ScrollDownImg} alt="Shaman Solutions" />
      </Box>
      <Box sx={styles.slider} ref={aboutRef}>
        <SwipeableViews
          style={{ ...styles.slideContainer, ...(isAboutVisible ? styles.animatedBlock : {}) }}
          index={activeStep}
          onChangeIndex={handleStepChange}
        >
          {ADVANTAGES_LIST_MAP.map(item => (
            <Box sx={styles.slide} key={item.title}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Image
                  sx={{ justifyContent: 'center', display: 'flex' }}
                  src={item.img}
                  width={isMDSize ? '90%' : 400}
                  alt={intl.formatMessage({ id: item.title })}
                />
              </Box>
              <Box sx={styles.description}>
                <Typography
                  sx={styles.descriptionTitle}
                  variant="h1"
                  mb={4}
                  dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: item.title }) }}
                />
                <Typography sx={styles.descriptionText} variant="LRegular">
                  {intl.formatMessage({ id: item.description })}
                </Typography>
                <MobileStepper
                  sx={styles.stepper}
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  nextButton={
                    <IconButton sx={styles.roundBtn} color="outlinedSecondary" onClick={handleNext}>
                      <ArrowRightIcon24 />
                    </IconButton>
                  }
                  backButton={
                    <IconButton sx={{ ...styles.roundBtn, mr: 2 }} color="outlinedSecondary" onClick={handleBack}>
                      <ArrowLeftIcon24 />
                    </IconButton>
                  }
                />
              </Box>
            </Box>
          ))}
        </SwipeableViews>
      </Box>
      <Box sx={styles.screen} ref={contactRef}>
        <Box display="flex" flex={1} alignItems="center" justifyContent="center">
          <Box sx={styles.contactUs}>
            <Image
              sx={{ ...styles.arrow, ...(isContactVisible ? styles.animatedArrow : {}) }}
              src={ArrowRightImg}
              alt="Contact Us"
            />
            <Typography
              sx={{ ...styles.lets, ...(isContactVisible ? styles.animatedBlock : {}) }}
              variant="h1"
              dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'LANDING_PAGE.TITLE.LETS_START' }) }}
            />
            <Box sx={styles.contactUsForm}>
              <Image
                sx={{ ...styles.target, ...(isContactVisible ? styles.animatedBlock : {}) }}
                src={TargetImg}
                alt="Contact Us"
              />
              <Box sx={{ ...styles.form, ...(isContactVisible ? styles.animatedForm : {}) }}>
                <form onSubmit={handleSubmit(handleContactUs)}>
                  <FieldTextControlled
                    sx={{ mb: 2 }}
                    name="email"
                    control={control}
                    label="FORM.FIELD.TITLE.EMAIL"
                    size="medium"
                  />
                  <FieldTextControlled
                    sx={{ mb: 2 }}
                    name="subject"
                    control={control}
                    label="FORM.FIELD.TITLE.CONTUCT_US_SUBJECT"
                    size="medium"
                  />
                  <FieldTextarea
                    sx={{ mb: 2 }}
                    rows={3}
                    name="message"
                    control={control}
                    label="FORM.FIELD.TITLE.CONTUCT_US_MESSAGE"
                    helperText="FORM.FIELD.HELPER_TEXT.MAX_255"
                    size="medium"
                  />
                  <Button type="submit" variant="contained" fullWidth>
                    {intl.formatMessage({ id: 'LANDING_PAGE.BUTTON.SUBMIT.CONTACT_US' })}
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.footer}>
          <Typography variant="XSMedium" color="#27272A">
            © 2021-{new Date().getFullYear()} SOLU.SH
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default LandingPage
