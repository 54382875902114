import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import Button, { ButtonProps } from '@mui/material/Button'
import ArrowBackIcon24 from 'components/shared/icons/24x24/ArrowBackIcon24'

interface BackButtonProps extends ButtonProps {
  handleBack?: () => void
}

const BackButton: FC<BackButtonProps> = ({ handleBack, ...props }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (handleBack) {
      handleBack()
    } else {
      navigate(-1)
    }
  }

  return (
    <Button onClick={handleClick} variant="contained" size="large" {...props}>
      <ArrowBackIcon24 />
    </Button>
  )
}

export default BackButton
