import React, { FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Backdrop from 'components/shared/Backdrop'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import SnippetsIcon24 from 'components/shared/icons/24x24/SnippetsIcon24'
import {
  KeysGroupsItem,
  KeysGroupsList,
  CreateSnippetsValues,
  SnippetsMode,
  SnippetsThreads,
  CreateSnippets,
  SnippetsModeItem,
  SnippetsThreadsItem
} from 'types/keys.types'
import { SNIPETS_MODES_LIST, SNIPETS_THREAD_LIST } from 'constants/keys.constants'
import { PATH_KEYS } from 'constants/routes.constants'
import schema from './CreateSnippetsForm.schemas'

interface CreateSnippetsFormParams {
  keysGroupsList: KeysGroupsList
  createSnippets: CreateSnippets
}

const CreateSnippetsForm: FC<CreateSnippetsFormParams> = ({ keysGroupsList, createSnippets }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isWidth305 = useMediaQuery('(max-width:325px)')

  const createSnippetsValues: CreateSnippetsValues = {
    groupId: '',
    mode: SnippetsMode.ALL,
    threads: SnippetsThreads.THREADS100
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: createSnippetsValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const handleCreateSnippets = (values: CreateSnippetsValues) => {
    try {
      const successInfo = intl.formatMessage(
        { id: 'NOTIFIER.INFO.TEXT.CREATE_SNIPPETS' },
        {
          groupTitle: keysGroupsList.find(group => String(group.id) === String(values.groupId))?.title,
          mode: values.mode,
          threads: parseInt(values.threads)
        }
      )

      createSnippets(values, successInfo)

      navigate({
        pathname: PATH_KEYS,
        search: searchParams.toString()
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleCreateSnippets)}>
      {keysGroupsList.length > 0 && (
        <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.KEYS_GROUP"
          name="groupId"
          control={control}
          optionsList={keysGroupsList}
          getValue={value => (value ? keysGroupsList.find(group => String(group.id) === String(value)) : null)}
          getNewValue={(newValue: KeysGroupsItem | null) => newValue?.id || ''}
          getOptionLabel={option => (option ? option?.title : '')}
          renderOption={(props, { id, title }) => (
            <MenuItem {...props} key={id}>
              {title}
            </MenuItem>
          )}
        />
      )}
      <FieldAutocomplete
        sx={{ mb: 2 }}
        label="FORM.FIELD.TITLE.SNIPPETS_MODE"
        name="mode"
        control={control}
        optionsList={SNIPETS_MODES_LIST}
        getValue={value => (value ? SNIPETS_MODES_LIST.find(snippet => String(snippet.id) === String(value)) : null)}
        getNewValue={(newValue: SnippetsModeItem | null) => newValue?.id || ''}
        getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
        renderOption={(props, { id, title }) => (
          <MenuItem {...props} key={id}>
            {intl.formatMessage({ id: title })}
          </MenuItem>
        )}
      />
      <FieldAutocomplete
        label="FORM.FIELD.TITLE.SNIPPETS_THREADS"
        name="threads"
        control={control}
        optionsList={SNIPETS_THREAD_LIST}
        getValue={value => (value ? SNIPETS_THREAD_LIST.find(thread => String(thread.id) === String(value)) : null)}
        getNewValue={(newValue: SnippetsThreadsItem | null) => newValue?.id || ''}
        getOptionLabel={option => (option ? intl.formatMessage({ id: option?.title }) : '')}
        renderOption={(props, { id, title }) => (
          <MenuItem {...props} key={id}>
            {intl.formatMessage({ id: title })}
          </MenuItem>
        )}
      />
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          type="submit"
          variant="contained"
          size={isWidth305 ? 'small' : 'large'}
          fullWidth
          startIcon={<SnippetsIcon24 />}
        >
          {intl.formatMessage({ id: 'CREATE_SNIPPETS_FORM.BUTTON.GENERATE' })}
        </Button>
      </Box>
      <Backdrop open={isSubmitting} />
    </form>
  )
}

export default CreateSnippetsForm
