import { keyframes } from '@emotion/react'
import theme from 'configs/theme.config'
import * as TOKENS from 'constants/tokens'

const fadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`

const title = keyframes`
  from {
    opacity: 0;
    transform: translate(-32px)
  }
  to {
    opacity: 1;
    transform: translate(0)
  }
`

const titleM = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px)
  }
  to {
    opacity: 1;
    transform: translate(0)
  }
`

const arrow = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1;
    transform: translate(0, 8px)
  }
`

const gradientShadow = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translateY(-100%)
  }
  1% {
    opacity: 1;
  }
  20% {
    opacity: 1;
    transform: translateY(100%)
  }
  21% {
    opacity: 0;
  }
`

const main = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9)
  }
  to {
    opacity: 1;
    transform: none
  }
`

const grid = keyframes`
  from {
    opacity: 0;
    transform: scale(1.07)
  }
  to {
    opacity: 1;
    transform: none
  }
`

const styles = {
  container: {
    background: '#030305',
    color: TOKENS.COMPONENT_COLOR_TEXT_BODY_PRIMARY,
    overflow: 'hidden'
  },
  screen: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    position: 'relative'
  },
  main: {
    position: 'absolute',
    zIndex: 1,
    opacity: 0,
    transform: 'scale(0.9)',
    animation: `800ms ease-out 800ms forwards ${main}`
  },
  grid: {
    position: 'absolute',
    zIndex: 2,
    opacity: 0,
    transform: 'scale(1.07)',
    animation: `800ms ease-out 800ms forwards ${grid}`
  },
  gradients: {
    zIndex: 3,
    position: 'absolute',
    width: 1062,
    height: 704,

    [theme.breakpoints.down('md')]: {
      width: 783,
      height: 519
    },

    '@media screen and (max-width: 710px)': {
      height: 509
    }
  },
  gradient: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  gradientSkewBox: {
    position: 'absolute',
    perspective: '976px',
    perspectiveOrigin: '50% -104px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 2401,
    height: 1387,
    bottom: -1140,

    [theme.breakpoints.down('md')]: {
      perspective: '1155px',
      perspectiveOrigin: '50% 168px',
      width: 1664,
      height: 1492,
      bottom: -1052
    },

    '@media screen and (max-width: 710px)': {
      height: 1483,
      bottom: -1042
    }
  },
  gradientSkew: {
    width: '100%',
    height: '100%',
    transformOrigin: 'center center',
    transform: 'rotateX(-270deg) translateY(-523px)',
    overflow: 'hidden'
  },
  gradientShadow: {
    width: '100%',
    height: '100%',
    transform: 'translateY(-100%)',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.06) 49.41%, rgba(255, 255, 255, 0.00) 100%)',
    animation: `10800ms ease-out 5400ms infinite ${gradientShadow}`
  },
  gradientShadowSkewed: {
    width: '100%',
    height: '100%',
    transform: 'translateY(-100%)',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.06) 49.41%, rgba(255, 255, 255, 0.00) 100%)',
    animation: `10800ms ease-out 6400ms infinite ${gradientShadow}`
  },
  logo: {
    position: 'absolute',
    zIndex: 4,
    opacity: 0,
    animation: `800ms ease-out 1200ms forwards ${fadeIn}`
  },
  menu: {
    position: 'absolute',
    zIndex: 4,
    top: theme.spacing(10),
    height: 48,
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 1240,
    width: '100%',
    px: 3,
    opacity: 0,
    animation: `800ms ease-in 1600ms forwards ${fadeIn}`
  },
  menuBtn: {
    position: 'fixed',
    right: theme.spacing(3),
    top: theme.spacing(4),
    zIndex: 100
  },
  sideMenu: {
    width: 200,
    flexShrink: 0,

    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: 200,
      px: 3,
      pt: 4,
      pb: 3
    },

    '& li.MuiListItem-root > div': {
      height: 48,
      px: 2,
      borderLeft: 0
    }
  },
  sideLogo: {
    mb: 8,

    '@media screen and (max-height: 326px)': {
      mb: 3
    }
  },
  title: {
    zIndex: 4,
    bottom: theme.spacing(18),
    width: '50%',
    right: 0,
    pl: 8,
    color: TOKENS.COMPONENT_COLOR_TEXT_BODY_ACTIVE,
    fontSize: 68,
    lineHeight: '72px',
    opacity: 0,
    animation: `600ms ease-in 1800ms forwards ${title}`,

    '@media screen and (max-width: 1121px)': {
      fontSize: 52,
      lineHeight: '60px'
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
      p: 0,
      textAlign: 'center',
      fontSize: 56,
      lineHeight: '64px',
      animation: `600ms ease-in 1800ms forwards ${titleM}`,
      bottom: '10%',
      px: 3
    },

    '@media screen and (max-height: 900px)': {
      bottom: '10%'
    },

    '@media screen and (max-height: 600px)': {
      width: '100%',
      p: 0,
      textAlign: 'center',
      fontSize: 56,
      lineHeight: '64px',
      animation: `600ms ease-in 1800ms forwards ${titleM}`,
      bottom: theme.spacing(2)
    },
    '@media screen and (max-height: 400px)': {
      fontSize: 39,
      lineHeight: '40px'
    },
    '@media screen and (max-width: 400px)': {
      bottom: theme.spacing(2)
    },
    '@media screen and (max-width: 340px)': {
      fontSize: 45,
      lineHeight: '55px'
    }
  },
  scrollDown: {
    position: 'absolute',
    bottom: theme.spacing(3),
    zIndex: 4,
    opacity: 0,
    animation: `800ms ease-out 800ms forwards ${arrow}`,

    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    '@media screen and (max-height: 600px)': {
      display: 'none'
    }
  },
  slider: {
    alignItems: 'center',
    justifyContent: 'center',
    py: 20,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 4,

    [theme.breakpoints.down('md')]: {
      pt: 7,
      pb: 11
    }
  },
  slideContainer: {
    maxWidth: 1240,
    width: '100%',
    opacity: 0,
    transform: 'translate(0, 24px)',
    transition: 'all 800ms ease-in'
  },
  animatedBlock: {
    opacity: 1,
    transform: 'translate(0, 0)'
  },
  slide: {
    display: 'flex',

    '& > *': {
      display: 'flex',
      width: '50%',
      flexDirection: 'column'
    },

    '@media screen and (max-width: 710px)': {
      flexDirection: 'column',
      px: 3,
      alignItems: 'center',

      '& > *': {
        minWidth: 327,
        width: '80%'
      }
    },

    '@media screen and (max-width: 600px)': {
      p: 0,

      '& > *': {
        width: '90%',
        minWidth: 'auto',

        '& img': {
          width: '80%'
        }
      }
    }
  },
  description: {
    justifyContent: 'flex-end',
    pr: 2,
    pl: 1,

    '@media screen and (max-width: 710px)': {
      p: 0
    }
  },
  descriptionTitle: {
    '@media screen and (max-width: 960px)': {
      fontSize: 36,
      lineHeight: '44px',

      '@media screen and (max-width: 710px)': {
        mt: -4
      }
    }
  },
  descriptionText: {
    mb: 2,
    maxWidth: 446,

    '@media screen and (max-width: 710px)': {
      maxWidth: '100%'
    }
  },
  stepper: {
    background: 'transparent',
    justifyContent: 'flex-start',
    padding: 0,

    '& .MuiMobileStepper-dots': {
      display: 'none'
    }
  },
  roundBtn: {
    width: 64,
    height: 64,
    borderRadius: 32
  },
  contactUs: {
    maxWidth: 1240,
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5,

    '& > *': {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    '& form': {
      width: 279,

      [theme.breakpoints.down('md')]: {
        width: 272
      }
    },

    '@media screen and (max-width: 710px)': {
      flexDirection: 'column',
      px: 3,

      '& > *': {
        minWidth: 327,
        width: '80%'
      },

      '& form': {
        mt: 12,
        width: '100%'
      }
    },

    '@media screen and (max-width: 600px)': {
      p: 0,

      '& > *': {
        width: '90%',
        minWidth: 'auto'
      }
    }
  },
  arrow: {
    opacity: 0,
    position: 'absolute',
    transform: 'translate(-24px)',
    transition: 'all 800ms ease-in',

    '@media screen and (max-width: 960px)': {
      display: 'none'
    }
  },
  animatedArrow: {
    opacity: 1,
    transform: 'translate(0, 0)'
  },
  contactUsForm: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lets: {
    opacity: 0,
    transform: 'translate(0, 24px)',
    transition: 'all 800ms ease-in',

    '@media screen and (max-width: 710px)': {
      justifyContent: 'flex-start'
    }
  },
  target: {
    position: 'absolute',
    opacity: 0,
    transform: 'translate(0, 24px)',
    transition: 'all 800ms ease-in'
  },
  form: {
    opacity: 0,

    '@media screen and (max-width: 710px)': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  animatedForm: {
    opacity: 1,
    transition: 'all 800ms ease-in'
  },
  footer: {
    background: '#030305',
    position: 'relative',
    zIndex: 5,
    mt: theme.spacing(4),
    height: 128,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: 'solid 1px rgba(255, 255, 255, 0.1)'
  }
}

export default styles
