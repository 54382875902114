import React, { FC } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import SubtitleBox from 'components/shared/SubtitleBox'
import DomainsActiveTable from './DomainsActiveTable'
import theme from 'configs/theme.config'

const DomainsActive: FC = () => {
  const isBetweenSize = useMediaQuery(theme.breakpoints.between('sm', 830))
  const is600Size = useMediaQuery('(max-width:600px)')

  return (
    <>
      {!isBetweenSize && !is600Size ? <SubtitleBox title="DOMAINS_ACTIVE.SUBTITLE" /> : null}
      <DomainsActiveTable />
    </>
  )
}

export default DomainsActive
