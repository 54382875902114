import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { PATH_STATISTICS_DEFAULT } from 'constants/routes.constants'

interface PublicRouteProps {
  isLoaded: boolean
  isAuthed: boolean
  Component: FC
}

const PublicRoute: FC<PublicRouteProps> = ({ isLoaded, isAuthed, Component }) => {
  return isLoaded && isAuthed ? <Navigate to={PATH_STATISTICS_DEFAULT} replace /> : <Component />
}

export default PublicRoute
