import React, { FC, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { TooltipProps } from '@mui/material/Tooltip'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from 'components/shared/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'

interface TooltipDisabledProps extends TooltipProps {
  title: string
  isVisible?: boolean
  boxProps?: BoxProps
}

const TooltipHover: FC<TooltipDisabledProps> = ({ isVisible = true, title, children, boxProps, ...props }) => {
  const intl: IntlShape = useIntl()
  const isHover = useMediaQuery('(hover: hover)')

  const [isTooltip, setIsTooltip] = useState(false)

  return isHover ? (
    <Tooltip
      open={isTooltip}
      placement="bottom"
      title={intl.formatMessage({ id: title })}
      onMouseEnter={() => setIsTooltip(isVisible)}
      onMouseLeave={() => setIsTooltip(false)}
      {...props}
    >
      <Box className="hover" {...boxProps}>
        {children}
      </Box>
    </Tooltip>
  ) : (
    <Box {...boxProps}>{children}</Box>
  )
}

export default TooltipHover
