import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CopyIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1C1.89543 1 1 1.89543 1 3V10C1 11.1046 1.89543 12 3 12H4V13C4 14.1046 4.89543 15 6 15H13C14.1046 15 15 14.1046 15 13V6C15 4.89543 14.1046 4 13 4H12V3C12 1.89543 11.1046 1 10 1H3ZM10 6V4V3H3V10H10V6ZM12 6H13V13H6V12H10C11.1046 12 12 11.1046 12 10V6Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default CopyIcon16
