import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getKeysGroupsList, createSnippets } from 'modules/keys'
import CreateSnippetsForm from './CreateSnippetsForm'

const mapStateToProps = (state: RootState) => {
  return {
    keysGroupsList: getKeysGroupsList(state)
  }
}

const mapDispatchToProps = {
  createSnippets
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSnippetsForm)
