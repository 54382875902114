import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getKeysGroupsList, importKeys, fetchKeysGroupsList } from 'modules/keys'
import ImportKeysForm from './ImportKeysForm'

const mapStateToProps = (state: RootState) => {
  return {
    keysGroupsList: getKeysGroupsList(state)
  }
}

const mapDispatchToProps = {
  importKeys,
  fetchKeysGroupsList
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportKeysForm)
