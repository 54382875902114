import React, { FC } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import styles from './Card.styles'

const Card: FC<BoxProps> = ({ sx, children, ...props }) => (
  <Box flexDirection="column" sx={{ ...styles.box, ...sx }} {...props}>
    {children}
  </Box>
)

export default Card
