import React, { FC, MouseEvent, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import copy from 'clipboard-copy'
import Box, { BoxProps } from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

interface CopyButtonProps extends BoxProps {
  value: string
  handleOnCopy?: () => void
}

const CopyButton: FC<CopyButtonProps> = ({ children, value, handleOnCopy, ...props }) => {
  const intl: IntlShape = useIntl()

  const [isTooltip, setIsTooltip] = useState(false)

  const onCopy = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    await copy(value)
    setIsTooltip(true)

    if (handleOnCopy) {
      handleOnCopy()
    }
  }

  const handleOnTooltipClose = () => {
    setIsTooltip(false)
  }

  return (
    <Tooltip
      open={isTooltip}
      title={intl.formatMessage({ id: 'BUTTON.COPY_BUTTON.TOOLTIP' })}
      leaveDelay={500}
      onClose={handleOnTooltipClose}
    >
      <Box onClick={onCopy} component="span" style={{ cursor: 'pointer' }} {...props}>
        {children}
      </Box>
    </Tooltip>
  )
}

export default CopyButton
