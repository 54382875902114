import { connect } from 'react-redux'
import { RootState } from 'types/system.types'
import { getParsers } from 'modules/user'
import { fetchParsersInfo, getParsersInfo, validateDomains, fetchDomainsStorageList } from 'modules/domains'
import DomainsValidateForm from './DomainsValidateForm'

const mapStateToProps = (state: RootState) => ({
  parsers: getParsers(state),
  parsersInfo: getParsersInfo(state)
})

const mapDispatchToProps = {
  validateDomains,
  fetchParsersInfo,
  fetchDomainsStorageList
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainsValidateForm)
