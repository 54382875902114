import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ElixirIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1H4V4.56619L4.99999 5.16619V7.07124L1.02839 10.5464C0.250229 11.2273 0.119363 12.3891 0.726552 13.226L2.01357 15H13.9864L15.2734 13.226C15.8806 12.3891 15.7498 11.2273 14.9716 10.5464L11 7.07123V5.16619L12 4.56619V1ZM6 3.43381V3H10V3.43381L8.99999 4.03381V7.97876L11.8814 10.5H4.11859L6.99999 7.97878V4.03381L6 3.43381Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ElixirIcon16
