import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const StatusPendingIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 5C1 2.79086 2.79086 1 5 1H11C13.2091 1 15 2.79086 15 5V11C15 13.2091 13.2091 15 11 15H5C2.79086 15 1 13.2091 1 11V5ZM3 8C3 5.23858 5.23858 3 8 3C10.7614 3 13 5.23858 13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8ZM7.5 5V8V8.30902L7.77639 8.44721L9.77639 9.44721L10.2236 8.55279L8.5 7.69098V5H7.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default StatusPendingIcon16
