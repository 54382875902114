import { BrowserRouter } from 'react-router-dom'
import React from 'react'
import { hydrate } from 'react-dom'
import { createCssCache } from 'utils/system.utils'
import Root from './root'

const cache = createCssCache()

hydrate(
  <BrowserRouter>
    <Root cache={cache} />
  </BrowserRouter>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
