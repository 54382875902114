import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ErrorIcon20: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 20 20" fontSize="icon20" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58333 1.66702H12.4167C12.9833 1.66702 13.7833 2.00036 14.1833 2.40036L17.6 5.81703C18 6.21703 18.3333 7.01703 18.3333 7.58369V12.417C18.3333 12.9837 18 13.7837 17.6 14.1837L14.1833 17.6004C13.7833 18.0004 12.9833 18.3337 12.4167 18.3337H7.58333C7.01666 18.3337 6.21665 18.0004 5.81665 17.6004L2.39999 14.1837C1.99999 13.7837 1.66666 12.9837 1.66666 12.417V7.58369C1.66666 7.01703 1.99999 6.21703 2.39999 5.81703L5.81665 2.40036C6.21665 2.00036 7.01666 1.66702 7.58333 1.66702ZM13.3583 7.52537L10.8833 10.0004L13.3583 12.4754C13.6 12.717 13.6 13.117 13.3583 13.3587C13.2333 13.4837 13.075 13.542 12.9167 13.542C12.7583 13.542 12.6 13.4837 12.475 13.3587L10 10.8837L7.525 13.3587C7.4 13.4837 7.24167 13.542 7.08333 13.542C6.925 13.542 6.76667 13.4837 6.64167 13.3587C6.4 13.117 6.4 12.717 6.64167 12.4754L9.11667 10.0004L6.64167 7.52537C6.4 7.2837 6.4 6.8837 6.64167 6.64204C6.88334 6.40037 7.28333 6.40037 7.525 6.64204L10 9.11704L12.475 6.64204C12.7167 6.40037 13.1167 6.40037 13.3583 6.64204C13.6 6.8837 13.6 7.2837 13.3583 7.52537Z"
        fill="#EF4444"
      />
    </SvgIcon>
  )
}

export default ErrorIcon20
