import React, { FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import FieldFile from 'components/shared/form/FieldFile'
import FieldTextControlled from 'components/shared/form/FieldTextControlled'
import FieldAutocomplete from 'components/shared/form/FieldAutocomplete'
import Backdrop from 'components/shared/Backdrop'
import KeysIcon24 from 'components/shared/icons/24x24/KeysIcon24'
import { SortMethod } from 'types/shared.types'
import {
  KeysGroupsList,
  KeysGroupsItem,
  ImportKeysValues,
  ImportKeys,
  FetchKeysGroupsList,
  KeysTableHeadIds
} from 'types/keys.types'
import { PATH_KEYS } from 'constants/routes.constants'
import schema from './ImportKeysForm.schemas'

interface ImportKeysFormParams {
  keysGroupsList: KeysGroupsList
  importKeys: ImportKeys
  fetchKeysGroupsList: FetchKeysGroupsList
}

const ImportKeysForm: FC<ImportKeysFormParams> = ({ keysGroupsList, importKeys, fetchKeysGroupsList }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isWidth300 = useMediaQuery('(max-width:300px)')

  const sortMethod = (searchParams.get('sortMethod') as SortMethod) || SortMethod.DESC
  const sortBy = (searchParams.get('sortBy') as KeysTableHeadIds) || KeysTableHeadIds.CREATED_AT

  const importKeysValues: ImportKeysValues = {
    groupId: '',
    title: '',
    file: null
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    watch,
    trigger,
    setError
  } = useForm({
    defaultValues: importKeysValues,
    mode: 'all',
    resolver: yupResolver(schema)
  })

  const handleImportKeys = async (values: ImportKeysValues) => {
    try {
      await importKeys(values)

      handleFetchKeysGroupsList()
    } catch (error) {
      console.log(error)
    }
  }

  const handleFetchKeysGroupsList = () => {
    fetchKeysGroupsList(sortMethod, sortBy)

    navigate({
      pathname: PATH_KEYS,
      search: searchParams.toString()
    })
  }

  const handleGroupIdChange = () => {
    const groupId = watch('groupId')

    if (groupId) {
      setValue('title', '')
      trigger(['title'])
    }
  }

  const handleTitleChange = () => {
    const title = watch('title')

    if (title) {
      setValue('groupId', '')
      trigger('groupId')
    }
  }

  return (
    <form onSubmit={handleSubmit(handleImportKeys)}>
      {keysGroupsList.length > 0 && (
        <FieldAutocomplete
          sx={{ mb: 2 }}
          label="FORM.FIELD.TITLE.KEYS_GROUP"
          name="groupId"
          control={control}
          afterFieldChange={handleGroupIdChange}
          optionsList={keysGroupsList}
          getValue={value => (value ? keysGroupsList.find(group => String(group.id) === String(value)) : null)}
          getNewValue={(newValue: KeysGroupsItem | null) => newValue?.id || ''}
          getOptionLabel={option => (option ? option?.title : '')}
          renderOption={(props, { id, title }) => (
            <MenuItem {...props} key={id}>
              {title}
            </MenuItem>
          )}
        />
      )}
      <FieldTextControlled
        sx={{ mb: 2 }}
        label="FORM.FIELD.TITLE.KEYS_GROUP_TITLE"
        name="title"
        control={control}
        afterFieldChange={handleTitleChange}
      />
      <FieldFile name="file" control={control} setError={setError} label="FORM.FIELD.TITLE.KEYS_FILE" />
      <Box display="flex" justifyContent="center" mt={4}>
        <Button
          type="submit"
          variant="contained"
          size={isWidth300 ? 'small' : 'large'}
          fullWidth
          startIcon={<KeysIcon24 />}
        >
          {intl.formatMessage({ id: 'IMPORT_KEYS_FORM.BUTTON.SUBMIT.IMPORT' })}
        </Button>
      </Box>
      <Backdrop open={isSubmitting} />
    </form>
  )
}

export default ImportKeysForm
