export enum CampaignsActionType {
  FETCH_CAMPAIGNS_LIST = 'FETCH_CAMPAIGNS_LIST',
  SET_CAMPAIGNS_LIST = 'SET_CAMPAIGNS_LIST',
  CREATE_CAMPAIGN = 'CREATE_CAMPAIGN',
  SET_CAMPAIGN = 'SET_CAMPAIGN'
}

export interface CampaignsState {
  campaignsList: CampaignsList
}

export type FetchCampaignsList = () => void

export interface CampaignsItemPayload {
  id: number
  user_id: number
  credential_partner_id: number
  credential_tracker_id: number
  title: string
  description: string
  source_id: string
  link: string
  created_at: Date
}

export type CampaignsListPayload = CampaignsItemPayload[]

export interface CampaignsItem {
  id: number
  title: string
}

export type CampaignsList = CampaignsItem[]

export interface SetCampaignsListAction {
  type: CampaignsActionType.SET_CAMPAIGNS_LIST
  payload: CampaignsListPayload
}

export interface CreateCampaignValues {
  title: string
  description: string
  sourceId: string
  link: string
}

export type CreateCampaign = (data: CreateCampaignValues) => void

export interface SetCampaignAction {
  type: CampaignsActionType.SET_CAMPAIGN
  payload: CampaignsItemPayload
}
