import React, { FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from 'components/shared/Table'
import KeysIcon24 from 'components/shared/icons/24x24/KeysIcon24'
import { getFormattedDate } from 'utils/shared.utils'
import { PATH_KEYS } from 'constants/routes.constants'
import { KEYS_TABLE_HEAD } from 'constants/keys.constants'
import { KeysGroupsList, KeysTableHeadIds, FetchKeysGroupsList, KeysHash } from 'types/keys.types'

interface KeysGroupsTableParams {
  sortedKeysGroupsList: KeysGroupsList
  fetchKeysGroupsList: FetchKeysGroupsList
}

const KeysGroupsTable: FC<KeysGroupsTableParams> = ({ sortedKeysGroupsList, fetchKeysGroupsList }) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const isWidth305 = useMediaQuery('(max-width:305px)')

  return (
    <Table
      sortByDefault={KeysTableHeadIds.CREATED_AT}
      tableHead={KEYS_TABLE_HEAD}
      fetchTableData={fetchKeysGroupsList}
      isTableData={sortedKeysGroupsList.length > 0}
      emptyTitle="KEYS_PAGE.KEYS_GROUPS_TABLE.TEXT.EMPTY"
      emptyContent={
        <>
          <Button
            variant="contained"
            size={isWidth305 ? 'small' : 'large'}
            onClick={() => navigate({ pathname: PATH_KEYS, search: searchParams.toString(), hash: KeysHash.IMPORT })}
            startIcon={<KeysIcon24 />}
          >
            {intl.formatMessage({ id: 'KEYS_PAGE.BUTTON.IMPORT_KEYS' })}
          </Button>
        </>
      }
    >
      {sortedKeysGroupsList.map(row => {
        return (
          <TableRow key={row.id}>
            <TableCell>{row.id}</TableCell>
            <TableCell>{getFormattedDate(row.createdAt, intl)}</TableCell>
            <TableCell>{row.title}</TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}

export default KeysGroupsTable
