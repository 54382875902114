import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const ArrowRightIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 20.792L20.4996 12.5424L12.25 4.29282L10.8358 5.70703L16.6712 11.5424L3.50041 11.5424L3.50041 13.5424H16.6712L10.8358 19.3778L12.25 20.792Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default ArrowRightIcon24
