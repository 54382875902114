import snakeCase from 'lodash/snakeCase'
import {
  CampaignsActionType,
  FetchCampaignsList,
  CampaignsListPayload,
  CreateCampaign,
  CampaignsItemPayload
} from 'types/campaigns.types'
import { AxiosDispatch } from 'types/system.types'
import { logError } from 'utils/system.utils'
import { addErrorNotificationsItem, addSuccessNotificationsItem } from 'modules/notifications'

export const fetchCampaignsList: FetchCampaignsList = () => {
  return {
    type: CampaignsActionType.FETCH_CAMPAIGNS_LIST,
    payload: {
      request: {
        url: '/materials/campaigns'
      },
      options: {
        onSuccess({ dispatch, response }: { response: CampaignsListPayload; dispatch: AxiosDispatch }) {
          dispatch({
            type: CampaignsActionType.SET_CAMPAIGNS_LIST,
            payload: response
          })
        },
        onError({ error }: { error: string }) {
          logError(error)
        }
      }
    }
  }
}

export const createCampaign: CreateCampaign = data => {
  return {
    type: CampaignsActionType.CREATE_CAMPAIGN,
    payload: {
      request: {
        url: '/materials/campaigns',
        method: 'POST',
        data: Object.entries(data).reduce(
          (memo, [key, value]) => (value ? ((memo[snakeCase(key)] = value), memo) : memo),
          {}
        )
      },
      options: {
        onSuccess({ dispatch, response }: { response: CampaignsItemPayload; dispatch: AxiosDispatch }) {
          dispatch(addSuccessNotificationsItem({ title: 'NOTIFIER.TITLE.SUCCESS.CREATE_CAMPAIGNS' }))
          dispatch({
            type: CampaignsActionType.SET_CAMPAIGN,
            payload: response
          })
        },
        onError({ error, dispatch }: { error: string; dispatch: AxiosDispatch }) {
          logError(error)
          dispatch(addErrorNotificationsItem(error))
          throw new Error(error)
        }
      }
    }
  }
}
