const styles = {
  info: {
    '& .MuiTooltip-tooltip': {
      background: 'error.main'
    },
    '& .MuiTooltip-arrow': {
      color: 'error.main'
    }
  }
}

export default styles
