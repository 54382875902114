import { KeysState, KeysActionType, SetKeysGroupsListAction, SetSortedKeysGroupsListAction } from 'types/keys.types'
import { camelCaseArray } from 'utils/shared.utils'

type KeysAction = SetKeysGroupsListAction | SetSortedKeysGroupsListAction

const initialState: KeysState = {
  keysGroupsList: [],
  sortedKeysGroupsList: []
}

const keys = (state = initialState, { type, payload }: KeysAction) => {
  switch (type) {
    case KeysActionType.SET_KEYS_GROUPS_LIST:
      return {
        ...state,
        keysGroupsList: camelCaseArray(payload)
      }
    case KeysActionType.SET_SORTED_KEYS_GROUPS_LIST:
      return {
        ...state,
        sortedKeysGroupsList: camelCaseArray(payload)
      }
    default:
      return state
  }
}

export default keys
