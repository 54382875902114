import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import moment from 'moment'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Table from 'components/shared/Table'
import TooltipHover from 'components/shared/TooltipHover'
import CopyButton from 'components/shared/CopyButton'
import ElixirIcon24 from 'components/shared/icons/24x24/ElixirIcon24'
import CopyIcon16 from 'components/shared/icons/16x16/CopyIcon16'
import { getFormattedDate } from 'utils/shared.utils'
import { PATH_DOMAINS_STORAGE_DEFAULT } from 'constants/routes.constants'
import { DOMAINS_ACTIVE_TABLE_HEAD, PROVIDER_ZONE_STATUS } from 'constants/domains.constants'
import { DomainsActiveList, DomainsActiveTableHeadIds, FetchDomainsActiveList } from 'types/domains.types'
import { Pagination } from 'types/shared.types'
import * as TOKENS from 'constants/tokens'
import styles from './DomainsActiveTable.styles'

interface DomainsActiveTableParams {
  domainsActiveList: DomainsActiveList
  domainsActiveListPagination: Pagination
  fetchDomainsActiveList: FetchDomainsActiveList
}

const DomainsActiveTable: FC<DomainsActiveTableParams> = ({
  domainsActiveList,
  domainsActiveListPagination,
  fetchDomainsActiveList
}) => {
  const intl: IntlShape = useIntl()
  const navigate = useNavigate()

  return (
    <Table
      sx={styles.table}
      sortByDefault={DomainsActiveTableHeadIds.CREATED_AT}
      tableHead={DOMAINS_ACTIVE_TABLE_HEAD}
      fetchTableData={fetchDomainsActiveList}
      isTableData={domainsActiveList.length > 0}
      emptyTitle="DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.TEXT.EMPTY"
      emptyContent={
        <>
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate(PATH_DOMAINS_STORAGE_DEFAULT)}
            startIcon={<ElixirIcon24 />}
          >
            {intl.formatMessage({ id: 'DOMAINS_ACTIVE.BUTTON.CREATE_DOMAINS' })}
          </Button>
        </>
      }
      pagination={domainsActiveListPagination}
    >
      {domainsActiveList.map(row => {
        const StatusIcon = PROVIDER_ZONE_STATUS[row.providerZoneStatus].icon

        return (
          <TableRow key={row.id}>
            <TableCell>{getFormattedDate(row.createdAt, intl)}</TableCell>
            <TableCell>{row.id}</TableCell>
            <TableCell>{row.httpStatus}</TableCell>
            <TableCell>
              <Box display="flex" alignItems="center" color={TOKENS.COMPONENT_COLOR_ICON_SECONDARY}>
                <TooltipHover boxProps={{ display: 'flex' }} title={PROVIDER_ZONE_STATUS[row.providerZoneStatus].text}>
                  <StatusIcon />
                </TooltipHover>

                <Link
                  ml={1}
                  color={PROVIDER_ZONE_STATUS[row.providerZoneStatus].color}
                  href={`https://www.google.com/search?q=site:${row.domain}`}
                  target="_blank"
                  rel="noreferrer nofollow"
                >
                  {row.domain}
                </Link>
                <CopyButton value={row.domain}>
                  <IconButton className="copyBtn" sx={styles.copy} size="small" color="outlinedSecondary">
                    <CopyIcon16 />
                  </IconButton>
                </CopyButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box display="flex">
                <CopyButton value={row.nameserver1}>
                  <TooltipHover title={row.nameserver1}>
                    <Button sx={{ mr: 1 }} size="small" color="secondary" variant="outlined" endIcon={<CopyIcon16 />}>
                      {intl.formatMessage({ id: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.NS1' })}
                    </Button>
                  </TooltipHover>
                </CopyButton>
                <CopyButton value={row.nameserver2}>
                  <TooltipHover title={row.nameserver2}>
                    <Button size="small" color="secondary" variant="outlined" endIcon={<CopyIcon16 />}>
                      {intl.formatMessage({ id: 'DOMAINS_ACTIVE.DOMAINS_ACTIVE_TABLE.CELL.TEXT.NS2' })}
                    </Button>
                  </TooltipHover>
                </CopyButton>
              </Box>
            </TableCell>
            <TableCell>
              <>
                {moment(row.datetimeStart).format('DD/MM HH:mm')} ({row.farmer})
              </>
            </TableCell>
            <TableCell>{row.requestsBots}</TableCell>
            <TableCell>{row.requestsKeys}</TableCell>
            <TableCell>
              <CopyButton value={row.mark}>
                <TooltipHover title={row.mark}>
                  <IconButton size="small" color="outlinedSecondary">
                    <CopyIcon16 />
                  </IconButton>
                </TooltipHover>
              </CopyButton>
            </TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}

export default DomainsActiveTable
