import React, { FC, useState, useEffect, useMemo } from 'react'
import { useLocation, createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { IntlShape, useIntl } from 'react-intl'
import moment from 'moment'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import PageLayout from 'components/PageLayout'
import Card from 'components/shared/Card'
import { getServerFormattedDate } from 'utils/shared.utils'
import { FetchStatistics, CommonStatistics, StatisticsPeriod } from 'types/statistics.types'
import PartnersStatisticsChart from './PartnersStatisticsChart'
import FarmsStatistics from './FarmsStatistics'
import styles from './StatisticsPage.styles'

interface StatisticsPageParams {
  fetchStatistics: FetchStatistics
  commonStatistics: CommonStatistics | null
}

const getDates = (period: StatisticsPeriod): [string, string] => {
  const today = moment.utc()
  const dateStart = today.clone()
  const dateFinal = today.clone()

  switch (period) {
    case StatisticsPeriod.WEEK:
      dateStart.startOf('isoWeek')
      dateFinal.endOf('isoWeek')
      break
    case StatisticsPeriod.MONTH:
      dateStart.startOf('month')
      dateFinal.endOf('month')
  }

  return [getServerFormattedDate(dateStart), getServerFormattedDate(dateFinal)]
}

const StatisticsPage: FC<StatisticsPageParams> = ({ fetchStatistics, commonStatistics }) => {
  const intl: IntlShape = useIntl()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [period, setPeriod] = useState<StatisticsPeriod>(
    (searchParams.get('period') as StatisticsPeriod) || StatisticsPeriod.WEEK
  )

  const dates = useMemo(() => {
    return getDates(period)
  }, [period])

  const fetchStatisticsData = () => {
    navigate(
      {
        pathname,
        search: createSearchParams({ period }).toString()
      },
      { replace: true }
    )
  }

  useEffect(() => {
    try {
      fetchStatistics(...dates)
    } catch (error) {
      console.log(error)
    }
  }, [dates])

  useEffect(() => {
    if (dates.length) {
      fetchStatisticsData()
    }
  }, [dates])

  return (
    <PageLayout title="STATISTICS_PAGE.TITLE">
      <Typography mb={2} variant="h6">
        {intl.formatMessage({ id: 'STATISTICS_PAGE.SUBTITLE.INCOME' })}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={3}>
        <Grid md={4} zero={12}>
          <Card sx={styles.card}>
            <Typography variant="h3" mb={1}>
              $ {commonStatistics?.earningsDay}
              <span> / {commonStatistics?.earningsAvg}</span>
            </Typography>
            <Typography variant="SRegular" component="span">
              {intl.formatMessage({ id: 'STATISTICS_PAGE.TEXT.INCOME.TODAY_AVG' })}
            </Typography>
          </Card>
        </Grid>
        <Grid md={4} zero={6}>
          <Card sx={styles.card}>
            <Typography variant="h3" mb={1}>
              $ {commonStatistics?.earningsWeek}
            </Typography>
            <Typography variant="SRegular" component="span">
              {intl.formatMessage({ id: 'STATISTICS_PAGE.TEXT.INCOME.WEEK' })}
            </Typography>
          </Card>
        </Grid>
        <Grid md={4} zero={6}>
          <Card sx={styles.card}>
            <Typography variant="h3" mb={1}>
              $ {commonStatistics?.earningsMonth}
            </Typography>
            <Typography variant="SRegular" component="span">
              {intl.formatMessage({ id: 'STATISTICS_PAGE.TEXT.INCOME.MONTH' })}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      {dates.length && <PartnersStatisticsChart sx={{ mt: 3 }} period={period} dates={dates} setPeriod={setPeriod} />}
      <FarmsStatistics />
    </PageLayout>
  )
}

export default StatisticsPage
