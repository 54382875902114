import React, { FC } from 'react'
import { useController } from 'react-hook-form'
import FieldText, { FieldTextProps } from 'components/shared/form/FieldText'
import { Controller, AfterFieldChange, ChangeTextField } from 'types/form.types'
import { Dispatch } from 'types/system.types'

interface FieldTextControlledPropsWithController extends Controller, Dispatch {
  afterFieldChange?: AfterFieldChange
}

export type FieldTextControlledProps = FieldTextControlledPropsWithController & FieldTextProps

const FieldTextControlled: FC<FieldTextControlledProps> = ({ control, name, afterFieldChange, dispatch, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, control })

  const handleChangeField: ChangeTextField = event => {
    field.onChange(event)
    afterFieldChange?.()
  }

  return <FieldText {...props} {...field} onChange={handleChangeField} errorMessage={error?.message} />
}

export default FieldTextControlled
