const styles = {
  tabs: {
    '@media screen and (max-width: 460px)': {
      mt: 2,
      flex: 1,

      '& .MuiTab-root': {
        flex: 1
      }
    },

    '@media screen and (max-width: 370px)': {
      '& .MuiTabs-flexContainer': {
        flexWrap: 'wrap'
      },

      '& .MuiTab-root.size-large': {
        width: '100%',
        flex: 'none',
        ml: 0,

        '&:last-child': {
          mt: 1.5
        }
      }
    }
  }
}

export default styles
