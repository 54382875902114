export const DEFAULT_PAGINATION_PER_PAGE = 25
export const PAGINATION_OPTIONS = [10, 25, 50, 100]

export const DEFAULT_PAGINATION = {
  count: 0,
  page: 1,
  lastPage: 1,
  perPage: DEFAULT_PAGINATION_PER_PAGE,
  total: 0
}

export const MONTHS_LIST = [
  'SHARED.DATE.MONTHS_LIST.TEXT.JANUARY',
  'SHARED.DATE.MONTHS_LIST.TEXT.FEBRUARY',
  'SHARED.DATE.MONTHS_LIST.TEXT.MARCH',
  'SHARED.DATE.MONTHS_LIST.TEXT.APRIL',
  'SHARED.DATE.MONTHS_LIST.TEXT.MAY',
  'SHARED.DATE.MONTHS_LIST.TEXT.JUNE',
  'SHARED.DATE.MONTHS_LIST.TEXT.JULY',
  'SHARED.DATE.MONTHS_LIST.TEXT.AUGUST',
  'SHARED.DATE.MONTHS_LIST.TEXT.SEPTEMBER',
  'SHARED.DATE.MONTHS_LIST.TEXT.OCTOBER',
  'SHARED.DATE.MONTHS_LIST.TEXT.NOVEMBER',
  'SHARED.DATE.MONTHS_LIST.TEXT.DECEMBER'
]

export const DAY_LIST = [
  'SHARED.DATE.DAY_LIST.TEXT.MONDAY',
  'SHARED.DATE.DAY_LIST.TEXT.TUESDAY',
  'SHARED.DATE.DAY_LIST.TEXT.WEDNESDAY',
  'SHARED.DATE.DAY_LIST.TEXT.THURSDAY',
  'SHARED.DATE.DAY_LIST.TEXT.FRIDAY',
  'SHARED.DATE.DAY_LIST.TEXT.SATURDAY',
  'SHARED.DATE.DAY_LIST.TEXT.SUNDAY'
]
