import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const StatisticsIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 1.99963H14V3.99963H22V1.99963ZM20 5.99963H14V7.99963H20V5.99963ZM14 9.99963H22V11.9996H14V9.99963ZM10 21.9996H12V13.9996H10V21.9996ZM8 15.9996L8 21.9996H6L6 15.9996H8ZM4 21.9996L4 17.9996H2V21.9996H4ZM16 16.5348L18 15.4645V12.9996L14 15.4645V19.5348L18 22.2015L22 19.5348V16.9996H20V18.4645L18 19.7978L16 18.4645V16.5348ZM4 1.99963H2V6.61767L6 8.61767V11.9996H8V7.3816L4 5.3816V1.99963ZM6 1.99963H8V2.8816L12 4.8816V11.9996H10V6.11767L6 4.11767V1.99963ZM4 9.49963L2 8.49963V11.9996H4V9.49963Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default StatisticsIcon24
