import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CheckIcon16: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6999 4.28579C13.0944 4.67235 13.1008 5.30548 12.7142 5.69993L7.81421 10.6999C7.62613 10.8919 7.36872 11 7.1 11C6.83128 11 6.57387 10.8919 6.38579 10.6999L4.28579 8.55707C3.89923 8.16262 3.90562 7.52949 4.30007 7.14293C4.69452 6.75637 5.32765 6.76277 5.71421 7.15721L7.1 8.57128L11.2858 4.30007C11.6723 3.90562 12.3055 3.89923 12.6999 4.28579Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default CheckIcon16
