import createCache from '@emotion/cache'
import uk from 'locales/uk'

export const createCssCache = () => {
  return createCache({ key: 'css', prepend: true })
}

export const logError = (code: string) => {
  console.log(uk[`NOTIFIER.TITLE.ERROR.${code}`])
}
