import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import { COMPONENT_COLOR_ICON_SECONDARY } from 'constants/tokens'

const SortDefaultIcon16: FC<SvgIconProps> = ({ color, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 16 16" fontSize="icon16" {...props}>
      <path
        d="M7.63069 2.13406C7.84321 1.95531 8.1568 1.95531 8.36931 2.13406L11.8007 5.02031C12.2007 5.35678 11.9582 6 11.4314 6L4.56861 6C4.04176 6 3.79929 5.35678 4.1993 5.02031L7.63069 2.13406Z"
        fill={COMPONENT_COLOR_ICON_SECONDARY}
      />
      <path
        d="M8.36931 13.8659C8.15679 14.0447 7.8432 14.0447 7.63069 13.8659L4.1993 10.9797C3.79929 10.6432 4.04176 10 4.56861 10H11.4314C11.9582 10 12.2007 10.6432 11.8007 10.9797L8.36931 13.8659Z"
        fill={COMPONENT_COLOR_ICON_SECONDARY}
      />
    </SvgIcon>
  )
}

export default SortDefaultIcon16
