import * as TOKENS from 'constants/tokens'

const styles = {
  file: {
    position: 'absolute',
    width: '100%',
    opacity: 0,
    zIndex: 2,
    overflow: 'hidden',

    '& input': {
      width: '100%',
      height: '100%',
      opacity: 0,
      pl: 240,
      mr: -240,
      cursor: 'pointer',
      lineHeight: '46px'
    },

    '& .MuiFormHelperText-root': {
      display: 'none'
    }
  },
  button: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: TOKENS.BASE_SIZING_SIZING_6,
    pr: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}

export default styles
