import React, { FC } from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const AddIcon24: FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fontSize="icon24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7929 21.9995C11.2406 21.9995 11 21.5518 11 20.9995V12.9995H3C2.44771 12.9995 2 12.5518 2 11.9995C2 11.4472 2.44772 10.9995 3 10.9995H11V2.99951C11 2.44723 11.4477 1.99951 12 1.99951C12.5523 1.99951 13 2.44724 13 2.99953V10.9995H21C21.5523 10.9995 22 11.4472 22 11.9995C22 12.5518 21.5523 12.9995 21 12.9995H13V20.9995C13 21.5518 12.3452 21.9995 11.7929 21.9995Z"
        fill="currentColor"
      />
    </SvgIcon>
  )
}

export default AddIcon24
